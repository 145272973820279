import styled from 'styled-components';

import { CircularProgress, Button } from '@material-ui/core';

import { colors, typography, breakpoints } from '~/styles';

export const ButtonComponent = styled(Button).attrs({
  type: 'button',
})`
  width: 100%;
  height: 100%;

  &.MuiButtonBase-root {
    opacity: ${(props) => (props.disabled ? 0.5 : 1)};
    padding: 0;
    border: none;
    border-radius: 4px;
    background-color: ${colors.WHITE};
    font-family: ${typography.font.TITLE};
    font-size: ${typography.size.XS};
    text-align: center;
    text-transform: none;
    color: ${colors.BRAND_COLOR};
    border: solid 2px ${colors.BRAND_COLOR};

    &:hover {
      color: ${colors.BRAND_COLOR};
      background-color: ${colors.WHITE};
      border-color: ${colors.BRAND_COLOR_HOVER};
    }

    &.MuiButton-root:hover.Mui-disabled {
      background-color: ${colors.WHITE};
      border-color: ${colors.BRAND_COLOR_HOVER};
    }

    &.Mui-disabled {
      opacity: 0.5;
      color: ${colors.BRAND_COLOR};
    }
  }

  @media (min-width: ${breakpoints.MD}) {
    &.MuiButtonBase-root {
      font-size: ${typography.size.MD};
    }
  }
`;

export const Spinner = styled(CircularProgress).attrs({
  size: 20,
})`
  &.MuiCircularProgress-root {
    color: ${colors.BRAND_COLOR};
  }
`;
