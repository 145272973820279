/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
import axios from 'axios';

class NetworkService {
  /**
   * Network service constructor
   * @constructor
   *
   * @param {string} baseURL  baseURL will be prepended to `url` unless `url` is absolute
   */
  constructor(baseURL, timeout) {
    this.token = '';
    this._instance = this._createInstance(baseURL, timeout);
    this._addResponseInterceptor();
    this._addRequestInterceptor();
  }

  /**
   * Set Authorization header
   *
   * @param {string} token
   *
   * @return {void}
   */
  async setAuthorizationHeader(token) {
    this.token = token;
  }

  /**
   * Remove Authorization header
   *
   * @return {void}
   */
  removeAuthorizationHeader() {
    this.token = '';
  }

  /**
   * Create a new instance of axios with a custom config
   *
   * @param  {string} baseURL  baseURL will be prepended to `url` unless `url` is absolute
   *
   * @return {function}        Instance of axios
   */
  _createInstance(baseURL, timeout) {
    return axios.create({
      baseURL,
      timeout,
    });
  }

  /**
   * Get the data from request
   *
   * @param  {object} response    A response from a network request
   *
   * @return {object|undefined}   The parsed JSON from the request or undefined
   */
  async _handleResponse(response) {
    let data = {};

    try {
      data = await response.data;
    } catch (error) {
      return undefined;
    }

    return data;
  }

  /**
   * GET request wrapper
   *
   * @param  {string} url      Server URL
   * @param  {object} params   URL parametres
   *
   * @return {Promise}         The Promise
   */
  async get(url, params) {
    return this._instance.get(url, { params });
  }

  /**
   * POST request wrapper
   *
   * @param  {string} url      Server URL
   * @param  {object} data     Data to be sent as the request body
   * @param  {object} params   URL parametres
   *
   * @return {Promise}         The Promise
   */
  async post(url, data, params) {
    return this._instance.post(url, data, { params });
  }

  /**
   * Sent POST request with form data
   *
   * @param  {string} url      Server URL
   * @param  {object} data     Data to be sent as the request body
   * @param  {object} params   URL parametres
   *
   * @return {Promise}         The Promise
   */
  async postWithFormData(url, data, params) {
    return this._requestWithFormData('POST', url, data, params);
  }

  /**
   * PUT request wrapper
   *
   * @param  {string} url      Server URL
   * @param  {object} data     Data to be sent as the request body
   * @param  {object} params   URL parametres
   *
   * @return {Promise}         The Promise
   */
  async put(url, data, params) {
    return this._instance.put(url, data, { params });
  }

  /**
   * Sent PUT request with form data
   *
   * @param  {string} url      Server URL
   * @param  {object} data     Data to be sent as the request body
   * @param  {object} params   URL parametres
   *
   * @return {Promise}         The Promise
   */
  async putWithFormData(url, data, params) {
    return this._requestWithFormData('PUT', url, data, params);
  }

  /**
   * DELETE request wrapper
   *
   * @param  {string} url      Server URL
   *
   * @return {Promise}         The Promise
   */
  async delete(url) {
    return this._instance.delete(url);
  }

  /**
   * Allow sent POST / PUT request with form data
   *
   * @param  {string} method   Request method to be used when making the request
   * @param  {string} url      Server URL
   * @param  {object} data     Data to be sent as the request body
   * @param  {object} params   URL parametres
   *
   * @return {Promise}         The Promise
   */
  _requestWithFormData(method, url, data, params) {
    return this._instance({
      method,
      url,
      data,
      params,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${data._boundary}`,
      },
    });
  }

  /**
   * Set request interceptor
   */
  _addRequestInterceptor() {
    this._instance.interceptors.request.use(
      (config) => {
        if (this.token) {
          config.headers.Authorization = `Bearer ${this.token}`;
        }

        return config;
      },
      // Do something with request error
      (error) => Promise.reject(error),
    );
  }

  /**
   * Set response interceptor
   */
  _addResponseInterceptor() {
    this._instance.interceptors.response.use(
      (response) => this._handleResponse(response),
      (error) => {
        throw error.response;
      },
    );
  }
}

const createNetworkService = (baseURL) => {
  const networkSerice = new NetworkService(baseURL, 15000);

  return networkSerice;
};

export const upfulNetworkService = createNetworkService(
  import.meta.env.VITE_UPFUL_BASE_URL,
);
