import { Tooltip } from '@material-ui/core';

import { colors, typography } from '~/styles';

import { withStyles } from '@material-ui/core/styles';

const styles = {
  tooltip: {
    color: colors.WHITE,
    backgroundColor: colors.PRIMARY,
    maxWidth: '300px',
    fontSize: typography.size.XS,
    fontFamily: typography.font.TEXT,
    padding: '5px 10px',
  },
  arrow: {
    color: colors.PRIMARY,
  },
};

export default withStyles(styles)(Tooltip);
