import styled from 'styled-components';
import { NavLink as link } from 'react-router-dom';

import { space, colors, breakpoints, typography } from '~/styles';

import { FormTitleText } from '~/elements';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
  padding-bottom: 16px;

  @media (min-width: ${breakpoints.SM}) {
    padding-bottom: 0;
  }

  @media (min-width: ${breakpoints.RG}) {
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: min-content;
  padding: ${space.XS};
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.SM};
  }

  @media (min-width: ${breakpoints.MD}) {
    max-width: 100%;
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 472px;
  }
`;

export const FormTitle = styled(FormTitleText)`
  align-self: center;
  margin-bottom: ${space.LG};
  color: ${colors.MILESTONE_TAG};
`;

export const FormFieldWrapper = styled.div`
  width: 100%;
`;

export const ButtonControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: ${space.XS};

  @media (min-width: ${breakpoints.RG}) {
    margin-bottom: ${space.LG};
  }
`;

export const NavLink = styled(link)`
  align-self: center;
  text-decoration: none;
  font-family: ${typography.font.TEXT};
  color: ${colors.BRAND_COLOR};
`;
