import React from 'react';

import { CreatePasswordForm } from '~/modules/Auth';

import { IntroSection } from '~/components';

import { AuthPageWrapper, PageContainer } from '~/elements';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    page: 'CreatePassword',
  },
  dataLayerName: 'PageDataLayer',
};

function CreatePassword() {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <AuthPageWrapper>
      <PageContainer withAdditionalMargin>
        <IntroSection
          title="Complete your Account"
          description="Create your password to login and protect your account."
        />
        <CreatePasswordForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default CreatePassword;
