import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
  EyeballSectionContainer,
  EyeballSectionContent,
  Eyeball,
  EyeballText,
} from './AddWorkItemModalStyles';

function EyeballSection({ initialCollaborators, initialStakeholders }) {
  const renderEyeballText = () => {
    const collaborators =
      initialCollaborators === null ? [] : initialCollaborators;
    const stakeholders =
      initialStakeholders === null ? [] : initialStakeholders;

    if (collaborators.length === 0 && stakeholders.length === 0) {
      return `When you post this, only you will be able to view it. If you want feedback, add your collaborators and stakeholders.`;
    }

    const reviewersData = [...collaborators, ...stakeholders];

    const reviewers = reviewersData.map((reviewerData) => reviewerData.label);

    if (reviewers.length === 1) {
      return `When you post this, ${reviewers[0]} will be able to view and provide feedback`;
    }
    if (reviewers.length === 2) {
      return `When you post this, ${reviewers[0]} and ${reviewers[1]} will be able to view and provide feedback.`;
    }

    return 'When you post this, only the collaborators and stakeholders you tagged will be able to view and provide feedback.';
  };

  return (
    <EyeballSectionContainer>
      <EyeballSectionContent>
        <Eyeball />
        <EyeballText>{renderEyeballText()}</EyeballText>
      </EyeballSectionContent>
    </EyeballSectionContainer>
  );
}

EyeballSection.defaultProps = {
  initialCollaborators: [],
  initialStakeholders: [],
};

EyeballSection.propTypes = {
  initialCollaborators: PropTypes.array,
  initialStakeholders: PropTypes.array,
};

export default memo(EyeballSection);
