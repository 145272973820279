import React from 'react';
import PropTypes from 'prop-types';

import { SponsorLink, SponsorImage } from './SponsorStyles';

function Sponsor({ alt, src, link }) {
  return (
    <SponsorLink href={link} target="_blank">
      <SponsorImage alt={alt} src={src} />
    </SponsorLink>
  );
}

Sponsor.propTypes = {
  alt: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
};

export default Sponsor;
