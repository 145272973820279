import React from 'react';

import { IntroSection } from '~/components';

import { P } from './ThankYouSectionStyles';

function ThankYouSection() {
  return (
    <IntroSection
      title="Thanks! Just one more step…"
      description="Your e-mail has been added to our mailing list. Please let us know which topics most interest you, so that we contact you about what is most relevant for you."
      additionalComponent={
        <P href="/contactUs" target="_blank">
          Contact Us
        </P>
      }
    />
  );
}

export default ThankYouSection;
