import * as Yup from 'yup';
import { email as username } from '~/utils/formsData';

export const textFieldsData = {
  username: {
    type: 'email',
    // label: 'Work Email',
    label: 'Email',
  },
  password: {
    type: 'password',
    label: 'Password',
  },
};

export const errorDictionary = {
  LOGIN0: {
    username: 'We are having technical difficulties. Can you try this again?',
  },
  LOGIN2: { username: 'User and password combination is invalid.' },
  CT0: {
    username: 'We are having technical difficulties. Can you try this again?',
  },
  CT1: { username: 'User and password combination is invalid.' },
  CT2: { username: 'Your account has been frozen.' },
};

// Schemas
export const loginSchema = Yup.object({
  username,
  password: Yup.string().required('Password should not be empty'),
});

// Initial values
export const loginFieldsInitialValues = {
  username: '',
  password: '',
};
