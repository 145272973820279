import { ReactComponent as FaqPlus } from '~/assets/images/faq-plus.svg';
import { ReactComponent as FaqMinus } from '~/assets/images/faq-minus.svg';
import { Paper } from '@material-ui/core';
import React from 'react';

export default function FAQ({
  question,
  answer,
  item,
  expanded,
  setFaqExpanded,
}: {
  question: string;
  answer: React.ReactNode;
  expanded: boolean;
  item: number;
  setFaqExpanded: (item: number) => void;
}) {
  return (
    <Paper
      className="flex flex-row items-start justify-start shadow-[0px_0px_20px_rgba(26,35,53,0.15)] rounded-[6px] p-[15px] space-x-[15px] font-[Lato-Regular] mb-[10px]"
      onClick={() => setFaqExpanded(item)}
    >
      <div>{expanded ? <FaqMinus /> : <FaqPlus />}</div>
      <div>
        <div className="text-[18px]">{question}</div>
        {expanded ? (
          <div className="text-[16px] mt-[15px]">{answer}</div>
        ) : null}
      </div>
    </Paper>
  );
}
