import React from 'react';

import { TermsAndConditions as Terms } from './CreateAccountFormStyles';

function TermsAndConditions() {
  return (
    <>
      I agree to the{' '}
      <Terms href="/terms" target="_blank" rel="noopener noreferrer">
        Terms of Service
      </Terms>{' '}
      &amp;{' '}
      <Terms href="/privacy" target="_blank" rel="noopener noreferrer">
        Privacy Policy
      </Terms>
    </>
  );
}

export default TermsAndConditions;
