import { put, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { requestWrapper as request } from '~/utils';

import api from '~/services';

import { pageRoutes } from '~/modules/Navigation/constants';
import { actionTypes } from './joinSlice';

import { joinWaitlListNormalizer } from './normalizer';

function* joinWaitlList({ payload: { data, errorDictionary } }) {
  const { response } = yield request(api.joinWaitList, data, errorDictionary);

  if (response) {
    const parsedResponse = joinWaitlListNormalizer(response);

    yield put(actionTypes.joinWaitListSuccess(parsedResponse));
    yield put(push(pageRoutes.WAIT_LIST));
  } else {
    yield put(actionTypes.joinWaitListError());
  }
}

export default function* joinWaitlListSaga() {
  yield all([takeLatest(actionTypes.joinWaitListRequest, joinWaitlList)]);
}
