import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormSubmitButton, FormTextField } from '~/components';
import { Form, Formik, Field } from 'formik';
import { getLinkedinData } from '~/modules/Profile/redux/selectors';
import { actionTypes } from '~/modules/Profile/redux/profileSlice';
import { LinkedinIcon } from '~/assets';
import {
  ProfileFormWrapper,
  SubmitButtonControl,
  SubmitButtonWrapper,
} from '../commonStyles';
import {
  LinkedinFormSchema,
  textFieldData,
  errorDictionary,
} from './formsData';
import { LinkedinIconWrapper } from './LinkedInFormStyles';

function LinkedInForm() {
  const dispatch = useDispatch();

  const linkedinValue = useSelector(getLinkedinData);

  const saveUpdates = (data, { setErrors, setSubmitting }) => {
    dispatch(
      actionTypes.updateLinkedinRequest({
        data,
        errorDictionary,
        setErrors,
        setSubmitting,
      }),
    );
  };

  return (
    <ProfileFormWrapper>
      <LinkedinIconWrapper>
        <LinkedinIcon />
      </LinkedinIconWrapper>
      <Formik
        enableReinitialize
        initialValues={linkedinValue}
        validationSchema={LinkedinFormSchema}
        validateOnMount
        onSubmit={saveUpdates}
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <Field
              {...textFieldData}
              placeholder="Enter"
              component={FormTextField}
            />
            <SubmitButtonControl>
              <SubmitButtonWrapper>
                <FormSubmitButton
                  text="Save Updates"
                  isFetching={isSubmitting}
                  disabled={isSubmitting || !isValid}
                />
              </SubmitButtonWrapper>
            </SubmitButtonControl>
          </Form>
        )}
      </Formik>
    </ProfileFormWrapper>
  );
}

export default LinkedInForm;
