import { createGlobalStyle } from 'styled-components';

import { font, size } from './typography';
import colors from './colors';

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
  };

  html {
    height: 100%;
  }

  body {
    margin: 0;
    padding: 0;
    height: 100%;
  }

  #root {
    min-height: 100%;
    display: flex;
    flex-direction: column;
  }

  ul, ol, li, h1, h2, h3 {
    margin: 0;
    padding: 0;
  }

  ul, ol {
    list-style: none;
  }

  .ContactUs-Link {
    margin: 0;
    padding: 0;
    color: ${colors.BRAND_COLOR};
    text-decoration: underline;
    cursor: pointer;
  } 

  .MuiSnackbar-root {
    .MuiSnackbarContent-message {
      font-family: ${font.TEXT};
      font-size: ${size.SM};
    }
  }

  p.MuiTypography-root {
    font-family: ${font.TEXT};
  }

  span.MuiTypography-root.MuiPickersCalendarHeader-dayLabel.MuiTypography-caption {
    font-family: ${font.TEXT};
    color: ${colors.SECONDARY_TEXT};
  }
`;

export default GlobalStyle;
