import React, { useEffect } from 'react';
import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainer,
} from '~/elements';

import { ContactUsForm, Intro } from '~/modules/ContactUs';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    page: 'ContactUs',
  },
  dataLayerName: 'PageDataLayer',
};

function ContactUs() {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <AuthPageWrapper>
      <NavigationBarContainer />
      <PageContainer>
        <Intro />
        <ContactUsForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default ContactUs;
