function deleteWhitespaces(data) {
  Object.keys(data).forEach((dataKey) => {
    data[dataKey] =
      typeof data[dataKey] === 'string' ? data[dataKey].trim() : data[dataKey];
  });

  return data;
}

export default deleteWhitespaces;
