import React from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { CheckboxLabel, Checkbox } from '~/elements';
import {
  DisabilityCheckboxesWrapper,
  DisabilityCheckboxesContainer,
  DisabilityLabel,
} from './DemographicsFormStyles';

function DisabilityCheckboxes({ fieldName }) {
  const { values, setFieldValue } = useFormikContext();

  const handleDoubleClick = (variant) => {
    if (values.hasDisability === variant) {
      setFieldValue(fieldName, null);
    } else {
      setFieldValue(fieldName, variant);
    }
  };

  const toggleCheckbox = (event) => {
    if (event.target.name === 'yes') {
      handleDoubleClick(true);
    } else {
      handleDoubleClick(false);
    }
  };

  return (
    <DisabilityCheckboxesWrapper>
      <DisabilityLabel>Disability</DisabilityLabel>
      <DisabilityCheckboxesContainer>
        <CheckboxLabel
          name="yes"
          control={<Checkbox />}
          label="Yes, I have one"
          checked={values.hasDisability === true}
          onChange={toggleCheckbox}
        />
        <CheckboxLabel
          name="no"
          control={<Checkbox />}
          label="No, I do not"
          checked={values.hasDisability === false}
          onChange={toggleCheckbox}
        />
      </DisabilityCheckboxesContainer>
    </DisabilityCheckboxesWrapper>
  );
}

DisabilityCheckboxes.propTypes = {
  fieldName: PropTypes.string.isRequired,
};

export default DisabilityCheckboxes;
