/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { profileSteps, stepsFields } from '~/modules/Profile/constants';
import {
  getPersonFullName,
  getCurrentStepSelector,
  getProfileData,
  isIncompleteStepSet,
} from '~/modules/Profile/redux/selectors';
import setPassedSteps from '~/utils/setPassedSteps';
import { actionTypes } from '~/modules/Profile/redux/profileSlice';
import ProfileNavigationBar from '../ProfileNavigationBar';

import {
  BasisProfileWrapper,
  ProfileExplanationBlock,
  ProfileHeader,
  StepWrapper,
} from './ProfileBasisStyles';

function ProfileBasis() {
  const dispatch = useDispatch();
  const [isFirstRequestSend, toggleFirstRequest] = useState(false);

  const { firstName } = useSelector(getPersonFullName);
  const currentStep = useSelector(getCurrentStepSelector);
  const profileData = useSelector(getProfileData);
  const isIncompleteStepSetSuccess = useSelector(isIncompleteStepSet);

  const updatedSteps = setPassedSteps(profileData, stepsFields, profileSteps);

  const StepComponent = updatedSteps[currentStep].component;

  useEffect(() => {
    if (isIncompleteStepSetSuccess) {
      if (isFirstRequestSend) {
        dispatch(actionTypes.getPersonInfoRequest());
      } else {
        toggleFirstRequest(true);
      }
    }
  }, [dispatch, currentStep, isIncompleteStepSetSuccess]);

  return (
    <BasisProfileWrapper>
      <ProfileHeader>{firstName && `Hi, ${firstName}`}</ProfileHeader>
      <ProfileExplanationBlock>
        We&apos;re about to get really personal. Our platform is meant to help
        coach people when writing performance reviews in order to make career
        growth (decisions about promotions, salary increases, layoffs, voluntary
        turnover, and terminations) more equitable. By providing us with this
        information, you are helping us get better at providing the most
        relevant coaching to anyone who gives feedback or evaluates performance.
        This information will not be shared with your employer at the individual
        level. The information remains private and is used internally by us to
        customize our product offering, making it more accurate and relevant to
        each employee.
      </ProfileExplanationBlock>
      <ProfileNavigationBar
        currentStep={currentStep}
        profileSteps={updatedSteps}
      />
      <StepWrapper>
        <StepComponent />
      </StepWrapper>
    </BasisProfileWrapper>
  );
}

export default ProfileBasis;
