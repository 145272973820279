import styled from 'styled-components';
import { AppBar, Toolbar } from '@material-ui/core';

export const TopBarNavigatorContainer = styled.div`
  div div:not(:first-child) {
    &:after {
      padding-left: 10px;
      font-family: Lato-Regular;
      font-size: 18px;
      content: '/';
    }
  }
`;

export const NavigationBar = styled(AppBar).attrs({
  position: 'static',
})`
  &.MuiAppBar-colorPrimary {
    background-color: transparent;
    box-shadow: none;
  }

  .MuiToolbar-root {
    flex-direction: row-reverse;
    padding: 0;
    min-height: min-content;
  }
`;

export const ToolBarContainer = styled(Toolbar)``;
