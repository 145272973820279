import styled from 'styled-components';
import { space, colors, breakpoints } from '~/styles';

import { FormTitleText } from '~/elements';

export const FormWrapper = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row-reverse;
  margin: 16px;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    flex-direction: row-reverse;
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.MD}) {
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    width: 622px;
  }

  @media (min-width: ${breakpoints.LG}) {
    width: 882px;
  }
`;

export const FormTitle = styled(FormTitleText)`
  width: 100%;
  margin-bottom: 16px;
  color: ${colors.MILESTONE_TAG};

  @media (min-width: ${breakpoints.MD}) {
    margin-bottom: ${space.LG};
  }
`;

export const FormFieldWrapper = styled.div`
  width: 100%;
`;

export const ButtonControl = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media (min-width: ${breakpoints.SM}) {
    flex-direction: row;
    justify-content: space-between;
    height: 50px;
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 40px;

  @media (min-width: ${breakpoints.SM}) {
    width: 50%;
    height: 100%;
  }
`;

export const TermsAndConditions = styled.a`
  color: ${colors.BRAND_COLOR};
`;
