import { createSlice } from '@reduxjs/toolkit';

const industryWaitListState = {
  isFetching: false,
};

const industryWaitListSlice = createSlice({
  name: 'INDUSTRY_WAIT_LIST',
  initialState: industryWaitListState,
  reducers: {
    industryWaitListRequest: (state) => {
      state.isFetching = true;
    },
    industryWaitListSuccess: (state) => {
      state.isFetching = false;
    },
    industryWaitListError: (state) => {
      state.isFetching = false;
    },
  },
});

export const actionTypes = industryWaitListSlice.actions;

const industryWaitListReducer = industryWaitListSlice.reducer;

export default industryWaitListReducer;
