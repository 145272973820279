import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'react-select';

import {
  InviteOptionWrapper,
  InviteLabel,
  InviteLabelDescription,
} from './MultiSelectStyles';

function InviteOption({ data, ...props }) {
  const { label, labelDescription } = data;

  return (
    <components.Option {...props}>
      <InviteOptionWrapper>
        <InviteLabel>{label}</InviteLabel>
        <InviteLabelDescription>{labelDescription}</InviteLabelDescription>
      </InviteOptionWrapper>
    </components.Option>
  );
}

InviteOption.propTypes = {
  data: PropTypes.object.isRequired,
};

export default InviteOption;
