import styled from 'styled-components';

import { colors } from '~/styles';

export const SponsorsHolder = styled.div``;
export const SponsorsList = styled.div`
  position: relative;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background: green;
  margin: 0px;
  padding: 0px;
  background: ${colors.SPONSORS_BACKGROUND};
  position: relative;
  bottom: 0;
  text-align: center;

  a img {
    max-height: 130px;
    padding: 10px;
    margin: 0;
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
`;
