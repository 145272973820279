import { createSelector } from '@reduxjs/toolkit';

const workSelector = (state) => state.dashboard.myWork;

export const getMyWork = createSelector(workSelector, ({ myWork }) => myWork);

export const getMyWorkLoading = createSelector(
  workSelector,
  ({ isLoading }) => isLoading,
);

export const getCollaborators = createSelector(
  workSelector,
  ({ collaborators }) => collaborators,
);

export const getPeople = createSelector(workSelector, ({ people }) => people);

export const getEditingWorkItemsValues = createSelector(
  workSelector,
  ({ editingWorkItemValues }) => editingWorkItemValues,
);

export const getEditingStatus = createSelector(
  workSelector,
  ({ editingWorkItemValues }) =>
    Object.keys(editingWorkItemValues).length !== 0 &&
    Object.keys(editingWorkItemValues).some(
      (key) => editingWorkItemValues[key].length,
    ),
);

export const getEditingMode = createSelector(
  workSelector,
  ({ isEditingMode }) => isEditingMode,
);

export const getUnreadNotification = createSelector(getMyWork, (myWork) =>
  myWork.some((item) => item.work.is_read === false),
);
