import { createSelector } from '@reduxjs/toolkit';

const createCompanySelector = (state) => state.auth.createCompany;

export const submitPhoneNumberFetchingSelector = createSelector(
  createCompanySelector,
  (createCompany) => createCompany.isSubmitPhoneNumberFetching,
);

export const isPhoneNumberSubmittedSelector = createSelector(
  createCompanySelector,
  (createCompany) => createCompany.isPhoneNumberSubmitted,
);

export const isVerifyCodeEnabledSelector = createSelector(
  createCompanySelector,
  (createCompany) => createCompany.isVerifyCodeEnabled,
);

export const successSubmitPhoneDataSelector = createSelector(
  createCompanySelector,
  (createCompany) => createCompany.successSubmitPhoneData,
);

export const verifyCodeFetchingSelector = createSelector(
  createCompanySelector,
  (createCompany) => createCompany.isVerifyCodeFetching,
);

export const isResendFetchingSelector = createSelector(
  createCompanySelector,
  (createCompany) => createCompany.isResendFetching,
);

export const isResendSuccessSelector = createSelector(
  createCompanySelector,
  ({ isResendSuccess }) => isResendSuccess,
);
