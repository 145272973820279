import React from 'react';
import {
  MyWorkIcon,
  ColleaguesWorkIcon,
  CoachingExplorerIcon,
} from './components/SideBar/SideBarStyles';
import {
  HomeIcon,
  SupportIcon,
  UserCircleIcon,
} from '@heroicons/react/outline';
import CreateIcon from '@material-ui/icons/Create';

export const pageRoutes = {
  HOME: '/',
  MAIN: '/home',
  WAIT_LIST: '/waitlist',
  LOG_IN: '/login',
  CREATE_ACCOUNT: '/createAccount',
  CREATE_COMPANY: '/createCompany',
  CREATE_PASSWORD: '/createPassword',
  WAIT_LIST_THANKS: '/waitlistThanks',
  CHECK_EMAIL: '/checkEmail',
  VERIFY_COMPANY: '/verifyCompany',
  MY_WORK: '/myWork',
  COLLEGUES_WORK: '/colleaguesWork',
  COACHING_EXPLORER: '/coachingExplorer',
  FORGOT_PASSWORD: '/forgotPassword',
  CONTACT_US: '/contactUs',
  PROFILE: '/profile',
  MY_FEEDBACK: '/myFeedback',
  COLLEAGUES_FEEDBACK: '/colleaguesFeedback',
  TERMS: '/terms',
  PRIVACY: '/privacy',
};

export const ignoredPersonInfoRequestPages = [
  pageRoutes.MAIN,
  pageRoutes.HOME,
  pageRoutes.WAIT_LIST,
  pageRoutes.LOG_IN,
  pageRoutes.CREATE_ACCOUNT,
  pageRoutes.CREATE_COMPANY,
  pageRoutes.WAIT_LIST_THANKS,
  pageRoutes.CREATE_PASSWORD,
  pageRoutes.CHECK_EMAIL,
  pageRoutes.VERIFY_COMPANY,
  pageRoutes.PROFILE,
  pageRoutes.CONTACT_US,
  pageRoutes.TERMS,
  pageRoutes.PRIVACY,
];

export const screensPathWithMobileDesign = [
  '/',
  '/home',
  '/waitlist',
  '/login',
  '/createAccount',
  '/createCompany',
  '/createPassword',
  '/waitlistThanks',
  '/checkEmail',
  '/verifyEmail',
  '/contactUs',
  '/terms',
  '/privacy',
];

export const topBarNavigatorButtonsData = [
  { buttonTitle: 'Log In', pushRouteName: pageRoutes.LOG_IN },
  { buttonTitle: 'Create Account', pushRouteName: pageRoutes.CREATE_ACCOUNT },
  { buttonTitle: 'Join Waitlist', pushRouteName: pageRoutes.HOME },
];

export const topBarDataForCreateAccount = {
  text: 'Already have an account? ',
  buttonTitle: 'Log In',
  pushRouteName: pageRoutes.LOG_IN,
};

export const sideBarLinksData = [
  {
    title: 'Home',
    label: 'HM',
    link: pageRoutes.MAIN,
    icon: HomeIcon,
    openAsNewTab: false,
  },
  {
    title: 'Profile',
    label: 'PR',
    link: pageRoutes.PROFILE,
    icon: UserCircleIcon,
    openAsNewTab: false,
  },
  {
    title: 'Coaching Explorer',
    label: 'CE',
    link: pageRoutes.COACHING_EXPLORER,
    icon: CoachingExplorerIcon,
  },
  {
    title: 'Support',
    label: 'SU',
    link: pageRoutes.CONTACT_US,
    icon: SupportIcon,
    openAsNewTab: true,
  },

  // {
  //   title: 'Request Feedback',
  //   label: 'MW',
  //   link: pageRoutes.MY_WORK,
  //   icon: MyWorkIcon,
  // },
  // {
  //   title: 'Give Feedback',
  //   label: 'CW',
  //   link: pageRoutes.COLLEGUES_WORK,
  //   icon: ColleaguesWorkIcon,
  // },
];
