const space = {
  XXS: '10px',
  XS: '20px',
  SM: '30px',
  MD: '34px',
  LG: '40px',
  XL: '50px',
  XXL: '70px',
  XXXL: '90px',
  XXXXL: '120px',
};

export default space;
