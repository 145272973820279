import styled from 'styled-components';
import { colors, typography } from '~/styles';

export const FeedbackLeftPanelWrapper = styled.div`
  max-width: 100%;
  border-bottom: 1px solid ${colors.OUTLINES};
  padding-right: 25px;
  height: 100%;
  margin-bottom: 10px;
  padding-bottom: 5px;
`;

export const FeedbackPanelBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  margin: 5px 0px;
`;

export const FeedbackDescription = styled.div`
  margin-bottom: 10px;
  margin-left: 5px;
  align-self: start;
  color: ${colors.PRIMARY};
  font-size: ${typography.size.MD};
  font-family: ${typography.font.TITLE};
`;

export const FeedbackDescriptionText = styled.div`
  color: ${colors.PRIMARY};
  font-size: ${typography.size.XS};
  font-family: ${typography.font.TEXT};
  margin: 0px 5px;
  -webkit-line-clamp: ${({ lineClamp, collapsed }) =>
    collapsed ? lineClamp : 'none'};
  overflow-wrap: break-word;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const CollapsedControlsButton = styled.div`
  cursor: pointer;
  color: ${colors.BRAND_COLOR};
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.XS};
  margin-left: 5px;
  margin-top: 5px;
  &:hover {
    opacity: 0.8;
  }
`;

export const FeedbackDateText = styled.div`
  margin-bottom: 5px;
  font-size: ${typography.size.XXS};
  font-family: ${typography.font.TEXT};
  margin: 0px 5px;

  @media (min-width: 830px) {
    font-size: ${typography.size.XS};
  }
`;

export const FeedbackDateTitle = styled.div`
  margin-bottom: 5px;
  color: ${colors.MILESTONE_TAG};
  font-size: ${typography.size.XXS};
  font-family: ${typography.font.TEXT};
  margin: 0px 5px;

  @media (min-width: 830px) {
    font-size: ${typography.size.XS};
  }
`;

export const FeedbackDateBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const FeedbackDateItemsBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  max-width: 100%;
  margin: 5px 0px;
  justify-content: flex-start;
`;

export const FeedbackDateUnityBlock = styled.div`
  display: flex;
  flex-direction: row;
`;
