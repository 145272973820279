import styled from 'styled-components';

import { space, colors, typography, breakpoints } from '~/styles';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row-reverse;
  width: 100%;
  height: 100%;
  margin: 16px 0;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: min-content;
  padding: 16px;
  font-size: 12px;
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.SM};
  }

  @media (min-width: ${breakpoints.MD}) {
    max-width: 100%;
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 472px;
  }
`;

export const FormFieldWrapper = styled.div`
  width: 100%;
`;

export const SelectWrapper = styled.div`
  width: 100%;
`;

export const CheckboxSummary = styled.span`
  align-self: flex-start;
  margin: 15px 0 24px 0;
  font-family: ${typography.font.TEXT};
  color: ${colors.PRIMARY};
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const ButtonControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 36px;
  margin-top: ${space.XS};

  @media (min-width: ${breakpoints.SM}) {
    height: 50px;
    margin-top: ${space.SM};
  }
`;
