import styled from 'styled-components';

export const CheckboxWithFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: min-content;
`;

export const CheckboxControl = styled.div`
  margin: 5px 0 0 -8px;
`;

export const FieldControl = styled.div`
  width: 100%;

  .MuiInputBase-input {
    padding-top: 0;
  }
`;
