import { createSelector } from '@reduxjs/toolkit';

const joinWaitListSelector = (state) => state.waitlist.joinWaitlist;

export const joinWaitListFetchingSelector = createSelector(
  joinWaitListSelector,
  ({ isFetching }) => isFetching,
);

export const joinWaitListFetchingSelectorId = createSelector(
  joinWaitListSelector,
  ({ id }) => id,
);
