import React from 'react';
import PropTypes from 'prop-types';
import MyFeedbackItem from '~/modules/Feedback/MyFeedback/components/MyFeedbackItem';

import { MyFeedbackListContainer } from '~/modules/Feedback/MyFeedback/components/MyFeedbackItemsList/MyFeedbackItemsListStyles';
import { useDispatch } from 'react-redux';
import { actionTypes } from '../../redux/myFeedbackSlice';

function MyFeedbackItemsList({ feedbackItems, prompts, workItemId }) {
  const dispatch = useDispatch();

  const handleClick = (email) => {
    dispatch(
      actionTypes.getMyWorkItemFeedbackRequest({ id: workItemId, email }),
    );
  };
  return (
    <MyFeedbackListContainer>
      {feedbackItems.map(
        ({
          first_name: fn,
          last_name: ln,
          email,
          submitted,
          text,
          is_read: isRead,
        }) => (
          <MyFeedbackItem
            key={`my-feedback-item-${email}`}
            feedbackOwner={`${fn} ${ln}`}
            feedbackOwnerEmail={email}
            date={submitted}
            prompts={prompts}
            onClick={() => handleClick(email)}
            text={text}
            isNew={!isRead}
          />
        ),
      )}
    </MyFeedbackListContainer>
  );
}

MyFeedbackItemsList.propTypes = {
  workItemId: PropTypes.string.isRequired,
  feedbackItems: PropTypes.array.isRequired,
};

export default MyFeedbackItemsList;
