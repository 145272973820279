import React from 'react';
import PropTypes from 'prop-types';
import { pageRoutes } from '~/modules/Navigation/constants';
import WorkItem from '~/modules/Dashboard/components/WorkItem';

import { WorkItemsListContainer } from '~/modules/Dashboard/components/SharedStyledComponents';
import { FEEDBACK_STATUS } from './constants';
import ColleaguesWorkRightPanel from './ColleaguesWorkRightPanel';

function ColleaguesWorkItemsList({ workItems }) {
  const getVisibleName = (fn, ln) => `${fn} ${ln.charAt(0)}.`; // TODO: Move to utils
  return (
    <WorkItemsListContainer>
      {workItems.map(({ work }) => {
        return !work.isOwner ? (
          <WorkItem
            key={`colleagues-work-item-${work.id}`}
            id={work.id}
            title={work.title}
            link={pageRoutes.COLLEAGUES_FEEDBACK}
            date={work.created_on}
            isNew={!work.is_read}
            userShortName={getVisibleName(
              work.creator_first_name,
              work.creator_last_name,
            )}
            userFullName={`${work.creator_first_name} ${work.creator_last_name}`}
            rightPanel={
              <ColleaguesWorkRightPanel
                {...FEEDBACK_STATUS[work.provided_feedback]}
              />
            }
          />
        ) : (
          <></>
        );
      })}
    </WorkItemsListContainer>
  );
}

ColleaguesWorkItemsList.propTypes = {
  workItems: PropTypes.array.isRequired,
};

export default ColleaguesWorkItemsList;
