import React from 'react';
import PropTypes from 'prop-types';

import {
  IntroSectionWrapper,
  IntroSectionContainer,
  LogoContainer,
  Logo,
  LogoTitle,
  TitleWrapper,
  Title,
  DescriptionWrapper,
  DescriptionText,
  AdditionalComponentWrapper,
} from './IntroSectionStyles';
import { TermsAndConditions as Terms } from '~/modules/Auth/CreateAccount/components/CreateAccountForm/CreateAccountFormStyles';
function IntroSection({
  title,
  description,
  additionalComponent,
  link,
  linkText,
}) {
  return (
    <IntroSectionWrapper>
      <IntroSectionContainer>
        <LogoContainer>
          <Logo />
          <LogoTitle>upful.ai</LogoTitle>
        </LogoContainer>
        {title && (
          <TitleWrapper>
            <Title>{title}</Title>
          </TitleWrapper>
        )}
        <DescriptionWrapper>
          <DescriptionText>{description}</DescriptionText>
        </DescriptionWrapper>
        {additionalComponent && (
          <AdditionalComponentWrapper>
            {additionalComponent}
          </AdditionalComponentWrapper>
        )}
        {link && (
          <Terms className="mt-4 text-[18px]" href={link}>
            {linkText}
          </Terms>
        )}
      </IntroSectionContainer>
    </IntroSectionWrapper>
  );
}

IntroSection.defaultProps = {
  title: '',
  description: '',
  link: '',
  linkText: '',
};

IntroSection.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default IntroSection;
