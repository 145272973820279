import { createSelector } from '@reduxjs/toolkit';

const modalSelector = (state) => state.modal;

export const isModalOpenSelector = createSelector(
  modalSelector,
  ({ isOpen }) => isOpen,
);

export const getModalType = createSelector(
  modalSelector,
  ({ modalType }) => modalType,
);
