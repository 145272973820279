import styled from 'styled-components';

import { Text } from '~/elements';

import { typography, colors, breakpoints } from '~/styles';

export const FormCheckboxContainer = styled.div`
  > div {
    margin-bottom: 5px;
    :last-child {
      margin-bottom: 0;
    }
  }
`;

export const FormCheckboxWrapper = styled.div`
  margin-left: -8px;
`;

export const CheckboxErrorContainer = styled(Text)`
  margin-top: -5px;
  margin-left: 8px;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XXS};
  color: ${colors.ERROR};

  @media (min-width: ${breakpoints.MD}) {
    font-size: ${typography.size.SM};
  }
`;
