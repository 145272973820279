import { createSelector } from '@reduxjs/toolkit';

const createAccountSelector = (state) => state.auth.createAccount;

export const createAccounFetchingSelector = createSelector(
  createAccountSelector,
  (createAccount) => createAccount.isFetching,
);

export const createAccountDataSelector = createSelector(
  createAccountSelector,
  (createAccount) => createAccount.createAccountData,
);
