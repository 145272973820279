import styled from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';

import { space, colors, breakpoints, typography } from '~/styles';

import { FormTitleText } from '~/elements';

import { ChatIcon as Chat } from '~/assets';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row-reverse;
  width: 100%;
  max-width: max-content;
  height: min-content;
  position: relative;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: min-content;
  padding: ${space.XS} 16px;
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.SM};
  }

  @media (min-width: ${breakpoints.MD}) {
    width: 720px;
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 430px;
  }
`;

export const Backdrops = styled(Backdrop)`
  &.MuiBackdrop-root {
    position: absolute;
    top: 0%;
    width: 100%;
    height: 100%;
    background-color: ${colors.BACKDROP};
    z-index: 2;
  }
`;

export const ChatIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 1;
  width: 100%;
  margin-bottom: 22px;
`;

export const ChatIcon = styled(Chat)``;

export const FormTitle = styled(FormTitleText)`
  align-self: center;
  margin-bottom: 22px;
  color: ${colors.MILESTONE_TAG};
`;

export const FormDescription = styled.div`
  align-self: center;
  text-align: center;
  margin-bottom: 20px;
  font-family: ${typography.font.TEXT};
  color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.MD}) {
    margin-bottom: 49px;
  }
`;

export const ButtonControl = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-top: ${space.XS};

  @media (min-width: ${breakpoints.MD}) {
    margin-top: ${space.LG};
  }
`;
