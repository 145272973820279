import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ButtonComponent } from './OutlinedButtonStyles';

function OutlinedButton({ text, disabled, onClickAction }) {
  return (
    <ButtonComponent onClick={onClickAction} disabled={disabled}>
      {text}
    </ButtonComponent>
  );
}

OutlinedButton.defaultProps = {
  disabled: false,
};

OutlinedButton.propTypes = {
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClickAction: PropTypes.func.isRequired,
};

export default memo(OutlinedButton);
