import React from 'react';
import PropTypes from 'prop-types';

import NavigationBarTab from './NavigationBarTab';

import { NavigationBarWrapper } from './ProfileNavigationBarStyles';

function ProfileNavigationBar({ currentStep, profileSteps }) {
  return (
    <NavigationBarWrapper>
      {profileSteps.map(({ id, name, isPassed, isDisabled }) => (
        <NavigationBarTab
          key={`profile-navigation-bar-${name}`}
          id={id}
          title={name}
          isActive={id === currentStep}
          isPassed={isPassed}
          isDisabled={isDisabled}
        />
      ))}
    </NavigationBarWrapper>
  );
}

ProfileNavigationBar.propTypes = {
  currentStep: PropTypes.number.isRequired,
  profileSteps: PropTypes.array.isRequired,
};

export default ProfileNavigationBar;
