import React from 'react';
import PropTypes from 'prop-types';

import {
  WorkItemPanelBlock,
  WorkItemStatusText,
} from '~/modules/Dashboard/components/WorkItem/WorkItemStyles';

function ColleaguesWorkRightPanel({ title, icon: Icon, color }) {
  if (Icon) {
    return (
      <WorkItemPanelBlock>
        {/* <Icon color={color} /> */}
        <WorkItemStatusText color={color}>{title}</WorkItemStatusText>
      </WorkItemPanelBlock>
    );
  } else {
    return <></>;
  }
}

ColleaguesWorkRightPanel.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  color: PropTypes.string.isRequired,
};

export default ColleaguesWorkRightPanel;
