import { createSlice } from '@reduxjs/toolkit';

import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

const myWorkIntialState = {
  myWork: [],
  collaborators: [],
  editingWorkItemValues: {},
  isEditingMode: false,
};

const myWorkSlice = createSlice({
  name: 'DASHBOARD',
  initialState: myWorkIntialState,
  reducers: {
    initRequest: () => {},
    getMyWorkRequest: (state) => {
      state.isLoading = true;
    },
    getMyWorkSuccess: (state, { payload: { myWork } }) => {
      state.myWork = myWork.reverse();
      state.isLoading = false;
    },
    getMyWorkError: (state) => {
      state.isLoading = false;
    },
    openWorkItemModal: () => {},
    setEditingMode: (state, { payload: isEditingMode }) => {
      state.isEditingMode = isEditingMode;
    },
    autoSaveEditingWorkItem: () => {},
    setEditingWorkItem: (state, { payload: values }) => {
      state.editingWorkItemValues = values;
    },
    setCollaborators: (state, { payload: peopleInfo }) => {
      state.collaborators = peopleInfo;
    },
    addWorkItemRequest: () => {},
    addWorkItemSuccess: () => {},
    addWorkItemError: () => {},
  },
});

const myWorkPersistConfig = {
  key: 'myWorkItem',
  storage,
  whitelist: ['editingWorkItemValues'],
};

export const actionTypes = myWorkSlice.actions;

const myWorkReducer = myWorkSlice.reducer;

export default persistReducer(myWorkPersistConfig, myWorkReducer);
