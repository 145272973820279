import styled from 'styled-components';

import { colors, typography, breakpoints } from '~/styles';

export const Text = styled.p`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  font-size: ${typography.size.XS};
  font-style: normal;
  line-height: normal;
  color: ${colors.WHITE};
`;

export const SectionTitle = styled(Text)`
  font-family: ${typography.font.TEXT};
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  letter-spacing: normal;

  @media (min-width: ${breakpoints.SM}) {
    font-size: ${typography.size.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    font-size: ${typography.size.XL};
  }
`;

export const SectionText = styled(Text)`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.MD};
`;

export const FormErrorText = styled(Text)`
  display: inline;
  padding-top: 5px;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XXS};
  color: ${colors.ERROR};

  @media (min-width: ${breakpoints.MD}) {
    font-size: ${typography.size.SM};
  }
`;

export const FormTitleText = styled(Text)`
  font-family: ${typography.font.SITE_NAME};
  font-size: ${typography.size.SM};
  font-weight: 900;
  color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.MD}) {
    font-size: ${typography.size.MD};
  }
`;

export const CompanyTitle = styled(Text)`
  font-family: 'Poppins', sans-serif;
  font-size: ${typography.size.LG};
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.21px;
`;
