import { upfulNetworkService } from '../networkService';

export const getPersonInfo = () => upfulNetworkService.get('person/info');

export const updatePersonInfo = (data) =>
  upfulNetworkService.post('person/update', data);

export const putPersonDisability = (data) =>
  upfulNetworkService.put('person/disability', data);

export const updatePersonDisability = (data) =>
  upfulNetworkService.post(`person/disability`, data);
