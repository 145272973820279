import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ButtonComponent, Spinner } from './ButtonStyles';

function Button({ isFetching, text, disabled, onClickAction }) {
  return (
    <ButtonComponent onClick={onClickAction} disabled={disabled}>
      {isFetching ? <Spinner /> : text}
    </ButtonComponent>
  );
}

Button.defaultProps = {
  isFetching: false,
  disabled: false,
};

Button.propTypes = {
  isFetching: PropTypes.bool,
  text: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  onClickAction: PropTypes.func.isRequired,
};

export default memo(Button);
