import styled from 'styled-components';
import { TextField as MaterialUITextField } from '@material-ui/core';

import { SuccessCheckIcon } from '~/assets';
import { typography, colors, space, breakpoints } from '~/styles';

export const TextField = styled(MaterialUITextField).attrs({
  InputLabelProps: {
    shrink: true,
  },
})`
  width: 100%;

  &.MuiFormControl-root {
    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus {
      box-shadow: 0 0 0px 1000px ${colors.WHITE} inset;
    }
  }

  .MuiInputBase-root {
    font-family: ${typography.font.TEXT};
    font-size: ${typography.size.XS};
    color: ${colors.PRIMARY};
  }

  .MuiInput-underline,
  .MuiInput-underline:hover:not(.Mui-disabled) {
    &:before,
    &:after {
      border-bottom: ${(props) =>
        props.success === 'success'
          ? `2px solid ${colors.BRAND_COLOR}`
          : `2px solid ${colors.OUTLINES}`};
      transition: 0.1s ease-out;
    }
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom: 1px solid ${colors.OUTLINES};
  }

  .MuiInput-underline.Mui-error,
  .MuiInput-underline.Mui-error:hover {
    &:before,
    &:after {
      border-bottom-color: ${colors.ERROR};
    }
  }

  label {
    font-family: ${typography.font.TEXT};
    font-size: ${typography.size.XS};
    line-height: normal;
    color: ${colors.PRIMARY};
  }

  .MuiFormLabel-root {
    &.Mui-error,
    &.Mui-focused {
      color: ${colors.PRIMARY};
    }
  }

  .MuiInputLabel-shrink {
    transform: none;
    width: 100%;
    text-align: ${(props) => (props.center === 'true' ? 'center' : 'null')};
  }

  input {
    height: 36px;
    padding: 0;
    text-align: ${(props) => (props.center === 'true' ? 'center' : 'null')};

    &::placeholder {
      color: ${colors.SECONDARY_TEXT};
      opacity: 0.7;
      text-align: ${(props) => (props.center === 'true' ? 'center' : 'null')};
    }
  }

  @media (min-width: ${breakpoints.SM}) {
    .MuiInputBase-root {
      font-size: ${typography.size.SM};
    }

    label {
      font-size: ${typography.size.SM};
    }

    input {
      height: 40px;
      padding-right: ${(props) => props.success === 'success' && `25px`};
    }

    label + .MuiInputBase-root {
      input {
        padding: ${space.XXS} 0 0 0;
      }
    }
  }

  .MuiInputBase-inputMultiline {
    margin: 10px 0 5px 0;
    color: ${colors.PRIMARY};

    &::placeholder {
      color: ${colors.SECONDARY_TEXT};
    }
  }
`;

export const FormTextFieldErrorContainer = styled.div`
  height: 40px;
  width: 100%;
`;

export const FormFieldWrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const SuccessIcon = styled(SuccessCheckIcon)`
  position: absolute;
  top: calc(50% + 5px);
  right: 0;
  width: 20px;
  height: 20px;
`;
