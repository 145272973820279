import { profileFieldMap } from '~/modules/Profile/constants';

function getFieldStatus(deltasArray) {
  const statusObject = {};

  const successFields = deltasArray.map((delta) => delta.field_name || '');

  successFields.forEach((fieldName) =>
    Object.assign(statusObject, { [profileFieldMap[fieldName]]: 'success' }),
  );

  return statusObject;
}

export default getFieldStatus;
