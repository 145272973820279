import * as Yup from 'yup';

export const selectOptions = [
  { value: 5, label: 'Psychiatric' },
  { value: 4, label: 'Physical' },
  { value: 2, label: 'Auditory' },
  { value: 6, label: 'Visual' },
  { value: 3, label: 'Learning' },
  { value: 1, label: 'Other' },
];

export const initialValues = {
  disabilityTypes: [{ disabilityType: '', description: '' }],
};

const notEmpty = (disability) => disability.disabilityType !== undefined;

export const schema = Yup.object().shape({
  disabilityTypes: Yup.array().of(
    Yup.object().shape({
      disabilityType: Yup.string().required(
        'Please select an option from the drop-down.',
      ),
      description: Yup.string(),
    }),
  ),
  extraOne: Yup.bool().when(['disabilityTypes'], {
    is: (disabilityTypes) => !disabilityTypes.some(notEmpty),
    then: Yup.bool().required(),
    otherwise: Yup.bool(),
  }),
});

export const disabilityItemValue = { disabilityType: '', description: '' };
