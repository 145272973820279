import {
  configureStore,
  getDefaultMiddleware,
  combineReducers,
} from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import createSagaMiddleware from 'redux-saga';
import { connectRouter, routerMiddleware } from 'connected-react-router';

import rootReducer from '~/store/rootReducer';
import rootSaga from '~/store/rootSaga';

import { actionTypes } from '~/modules/Auth/AuthProvider/redux/authProviderSlice';

export default function configureAppStore(preloadedState, history) {
  const sagaMiddleware = createSagaMiddleware();

  const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['authProvider'],
  };

  const customizedMiddleware = getDefaultMiddleware({
    thunk: false,
    serializableCheck: false,
  });

  const middleware = [
    sagaMiddleware,
    routerMiddleware(history),
    ...customizedMiddleware,
  ];

  const reducer = combineReducers({
    router: connectRouter(history),
    ...rootReducer,
  });

  const persistedReducer = persistReducer(persistConfig, reducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware,
    devTools: import.meta.env.NODE_ENV !== 'production',
    preloadedState,
    enhancers: [],
  });

  const persistor = persistStore(store, null, () => {
    if (store?.getState?.().authProvider?.personId) {
    }
    store.dispatch(actionTypes.authenticate());
  });

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
}
