import { all, put, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { pageRoutes } from '~/modules/Navigation/constants';
import { actionTypes as toastActionTypes } from '~/modules/Toast/redux/toastSlice';

import { requestWrapper as request } from '~/utils';
import api from '~/services';

import { actionTypes } from './colleaguesFeedbackSlice';

function* getAllWorkItem({ payload: id }) {
  const { response } = yield request(api.getAllWorkItem, id);

  if (response) {
    yield put(actionTypes.getAllWorkItemSuccess(response));
  } else {
    yield put(
      toastActionTypes.showErrorToast(
        'You do not have access to this work item.',
      ),
    );
    yield put(push(pageRoutes.MY_WORK));
    yield put(actionTypes.getAllWorkItemError());
  }
}

export default function* colleaguesFeedbackSaga() {
  yield all([takeLatest(actionTypes.getAllWorkItemRequest, getAllWorkItem)]);
}
