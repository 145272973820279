export const industryTypeWaitListInitialValues = {
  fname: '',
  lname: '',
  role: '',
  industry: '',
  free: false,
  pilot: false,
  invest: false,
  share: false,
  introductions: false,
  join: false,
  other: '',
};

export const textFieldsData = {
  fname: {
    label: 'First Name',
  },
  lname: {
    label: 'Last Name',
  },
};

export const errorDictionary = {
  A1: {
    toast: `Oops - a step is missing. Let's go back to Upful.ai. You will see this page right after submitting your e-mail.`,
  },
  A2: {
    toast: `Oops - a step is missing. Let's go back to Upful.ai. You will see this page right after submitting your e-mail.`,
  },
  A3: {
    toast:
      'We are having technical difficulties. Can you try submitting this again?',
  },
  A4: {
    toast:
      'We are having technical difficulties. Can you try submitting this again?',
  },
  AI1: {
    firstName: `Please only use characters A-Z a-z ' - , .`,
  },
  AI2: {
    lastName: `Please only use characters A-Z a-z ' - , .`,
  },
  W1: {
    toast: 'Please use your work email',
  },
};
