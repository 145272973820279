import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import { FormFieldMap, FormTextField, FormSubmitButton } from '~/components';

import {
  isVerifyCodeEnabledSelector,
  verifyCodeFetchingSelector,
} from '~/modules/Auth/CreateCompany/redux/selectors';

import { actionTypes } from '~/modules/Auth/CreateCompany/redux/createCompanySlice';

import {
  textFieldsData,
  verificationCodeInitialValues,
  verificationCodeSchema,
  errorDictionary,
} from './formsData';

import {
  FormContainer,
  Form,
  ChatIconWrapper,
  ChatIcon,
  FormTitle,
  FormDescription,
  ButtonControl,
  Backdrops,
} from './CreateVerificationCodeFormStyle';

function CreateVerificationCodeForm() {
  const dispatch = useDispatch();

  const isFetching = useSelector(verifyCodeFetchingSelector);
  const isVerifyCodeEnabled = useSelector(isVerifyCodeEnabledSelector);

  const verifyCode = (data, { setErrors }) => {
    const { verifyCodeRequest } = actionTypes;

    dispatch(verifyCodeRequest({ data, errorDictionary, setErrors }));
  };

  return (
    <FormContainer>
      <Formik
        initialValues={verificationCodeInitialValues}
        validationSchema={verificationCodeSchema}
        onSubmit={verifyCode}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <ChatIconWrapper>
              <ChatIcon />
            </ChatIconWrapper>
            <FormTitle>Step 02</FormTitle>
            <FormDescription>
              A text message with a verification code was sent to the phone
              number you provided.
            </FormDescription>
            <FormFieldMap
              center
              textField={FormTextField}
              textFieldsData={textFieldsData}
              autoComplete="one-time-code"
              inputMode="numeric"
            />
            <ButtonControl>
              <FormSubmitButton
                text="Verify"
                disabled={isFetching || !isValid}
                isFetching={isFetching}
              />
            </ButtonControl>
          </Form>
        )}
      </Formik>
      <Backdrops open={!isVerifyCodeEnabled} />
    </FormContainer>
  );
}

export default CreateVerificationCodeForm;
