import React, { useState } from 'react';
import PropTypes from 'prop-types';
import DateFnsAdapter from '@date-io/date-fns';

import { Tooltip } from '~/components';
import { NotificationDot } from '~/elements';
import {
  MyFeedbackItemItemContainer,
  WorkItemContainer,
  WorkItemTitle,
  ArrowIconStyled,
  WorkItemLeftPanel,
  WorkItemRightPanel,
  WorkItemPanelBlock,
  WatchIconStyled,
  WorkItemText,
  EyebollIconStyled,
} from './MyFeedbackItemStyles';
import MyFeedbackItemExpand from './MyFeedbackItemExpand';

const dateFns = new DateFnsAdapter();

function MyFeedbackItem({
  feedbackOwner,
  date = '05/08/2020',
  prompts,
  text,
  isNew,
  onClick,
}) {
  const [isExpanded, expand] = useState(false);

  return (
    <MyFeedbackItemItemContainer>
      <WorkItemContainer
        onClick={() => {
          if (isNew) {
            onClick();
          }
          expand(!isExpanded);
        }}
      >
        <WorkItemLeftPanel>
          <WorkItemTitle>{feedbackOwner}</WorkItemTitle>
          <Tooltip
            title={`Only you and ${feedbackOwner} can read this feedback.`}
          >
            <EyebollIconStyled variant="gray" />
          </Tooltip>
        </WorkItemLeftPanel>

        <WorkItemRightPanel>
          <WorkItemPanelBlock>
            {isNew && <NotificationDot />}
            <WatchIconStyled variant="gray" />
            <WorkItemText>
              {dateFns.format(dateFns.date(date), 'MM/dd/yyyy')}
            </WorkItemText>
          </WorkItemPanelBlock>

          <ArrowIconStyled />
        </WorkItemRightPanel>
      </WorkItemContainer>
      {isExpanded && <MyFeedbackItemExpand prompts={prompts} text={text} />}
    </MyFeedbackItemItemContainer>
  );
}

MyFeedbackItem.propTypes = {
  feedbackOwner: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  prompts: PropTypes.arrayOf(PropTypes.string).isRequired,
  isNew: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default MyFeedbackItem;
