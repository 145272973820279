import styled from 'styled-components';

import { space, colors, typography, breakpoints } from '~/styles';

export const NavigationBarWrapper = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${space.LG};

  > li:not(:last-child) {
    margin-right: ${space.SM};
  }

  @media (min-width: ${breakpoints.RG}) {
    height: max-content;
  }
`;

export const NavigationBarTabContainer = styled.li`
  ${(props) =>
    props.isActive
      ? `
    color: ${colors.MILESTONE_TAG};
    :after {
      content: '';
      width: 100%;
      position: absolute;
      left: 0;
      bottom: -9px;
      border-style: solid;
      border-width: 2px 0px;
      border-radius: 3px;
      background-color: ${colors.MILESTONE_TAG};
    }
  `
      : `
    color: ${colors.SECONDARY_TEXT};
  `};

  ${(props) =>
    !props.isPassed && !props.isActive
      ? `
    :before {
      content: '•';
      padding-right: 5px;
      color: ${colors.ERROR};
    }
  `
      : ``}

  ${(props) => props.isDisabled && `pointer-events: none; opacity: 0.6;`}

  position: relative;
  height: max-content;
  margin-bottom: 15px;
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.SM};
  cursor: pointer;
`;
