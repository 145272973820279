import { createSelector } from '@reduxjs/toolkit';

const authSelector = (state) => state.authProvider;

export const getAuthenticatedStatus = createSelector(
  authSelector,
  ({ isAuthenticated }) => isAuthenticated,
);
export const getFreeEmailStatus = createSelector(
  authSelector,
  ({ free_email }) => free_email,
);
export const getAuthenticatedToken = createSelector(
  authSelector,
  ({ token }) => token,
);

export const getRefreshToken = createSelector(
  authSelector,
  ({ refresh }) => refresh,
);

export const getExpiresAt = createSelector(
  authSelector,
  ({ expiresAt }) => expiresAt,
);
export const getRefreshExpiresAt = createSelector(
  authSelector,
  ({ refreshExpiresAt }) => refreshExpiresAt,
);

export const getPersonId = createSelector(
  authSelector,
  ({ personId }) => personId,
);

export const getCompanyId = createSelector(
  authSelector,
  ({ companyId }) => companyId,
);
