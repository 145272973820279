import React from 'react';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { createPasswordFetchingSelector } from '~/modules/Auth/CreatePassword/redux/selectors';
import { actionTypes } from '~/modules/Auth/CreatePassword/redux/createPasswordSlice';

import { FormFieldMap, FormTextField, FormSubmitButton } from '~/components';

import {
  createPasswordAuthSchema,
  createPasswordAuthInitialValues,
  textFieldsData,
  errorDictionary,
} from './formsData';

import {
  FormContainer,
  Form,
  FormTitle,
  ButtonControl,
} from './CreatePasswordFormStyle';

function CreatePasswordForm() {
  const dispatch = useDispatch();

  const isFetching = useSelector(createPasswordFetchingSelector);

  const handleSubmitButton = (data, { setErrors }) => {
    const { createPasswordRequest } = actionTypes;

    dispatch(createPasswordRequest({ data, errorDictionary, setErrors }));
  };

  return (
    <FormContainer>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={createPasswordAuthInitialValues}
        validationSchema={createPasswordAuthSchema}
        onSubmit={handleSubmitButton}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FormTitle>Create Your Password</FormTitle>
            <FormFieldMap
              vertical
              textField={FormTextField}
              textFieldsData={textFieldsData}
            />
            <ButtonControl>
              <FormSubmitButton
                text="Set Password"
                disabled={isFetching || !isValid}
                isFetching={isFetching}
              />
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default CreatePasswordForm;
