import React, { useEffect } from 'react';

import { JoinForm, Sponsors } from '~/modules/Waitlist';
import { TopBarNavigator } from '~/modules/Navigation';

import { IntroSection } from '~/components';

import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainerWaitlist,
} from '~/elements';

import { topBarNavigatorButtonsData } from '~/modules/Navigation/constants';

function Join() {
  return (
    <>
      <AuthPageWrapper>
        <NavigationBarContainer>
          <TopBarNavigator
            topBarNavigatorButtonsData={topBarNavigatorButtonsData}
          />
        </NavigationBarContainer>
        <PageContainerWaitlist>
          <IntroSection description="Upful coaches employees to provide more meaningful, actionable, and unbiased performance reviews using AI and behavioral science." />
          <JoinForm />
        </PageContainerWaitlist>
      </AuthPageWrapper>
      <Sponsors />
    </>
  );
}

export default Join;
