import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';

import { CheckboxLabel, Checkbox } from '~/elements';

import {
  FormCheckboxContainer,
  CheckboxErrorContainer,
  FormCheckboxWrapper,
} from './FormCheckboxMapStyles';

function FormCheckboxMap({ checkboxObject }) {
  const { setFieldValue, errors, touched } = useFormikContext();

  const checkboxes = Object.keys(checkboxObject).map((checkboxName) => {
    return (
      <FormCheckboxWrapper key={`checkbox-form-${checkboxName}`}>
        <CheckboxLabel
          name={checkboxName}
          control={<Checkbox />}
          label={checkboxObject[checkboxName]}
          labelPlacement="end"
          onChange={(event) =>
            setFieldValue(checkboxName, event.target.checked)
          }
        />
        {errors[checkboxName] && touched[checkboxName] && (
          <CheckboxErrorContainer>
            {errors[checkboxName]}
          </CheckboxErrorContainer>
        )}
      </FormCheckboxWrapper>
    );
  });

  return <FormCheckboxContainer>{checkboxes}</FormCheckboxContainer>;
}

FormCheckboxMap.propTypes = {
  checkboxObject: PropTypes.object.isRequired,
};

export default memo(FormCheckboxMap);
