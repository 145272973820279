import * as Yup from 'yup';

export const textFieldsData = {
  verificationCode: {
    label: 'Please Enter the Verification Code',
  },
};

export const errorDictionary = {
  VCCC4: {
    verificationCode: `Verification code is incorrect. Make sure to use the most recent code.`,
  },
};

// Initial values
export const verificationCodeInitialValues = {
  verificationCode: '',
};

export const verificationCodeSchema = Yup.object({
  verificationCode: Yup.string().required(
    'Verification code field should not be empty',
  ),
});
