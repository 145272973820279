import { upfulNetworkService } from '../networkService';

export const getMyWork = () => upfulNetworkService.get('work/my');

export const getAllWork = () => upfulNetworkService.get('work/all');

export const getMyWorkItem = (id) => upfulNetworkService.get(`work/${id}/read`);
export const getAllWorkItem = (id) =>
  upfulNetworkService.get(`work/${id}/read`);

export const getMyWorkItemFeedback = ({ id, email }) =>
  upfulNetworkService.get(`work/${id}/read/${email}`);
