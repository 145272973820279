import styled from 'styled-components';

import { EyeballIcon } from '~/assets';

import { space, colors, typography } from '~/styles';

export const AddWorkItemModalWrapper = styled.div`
  max-width: 850px;
  padding: ${space.XS} 0;
`;

export const Form = styled.form``;

export const ButtonControl = styled.div`
  width: 400px;
  height: 50px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 ${space.XS};

  ${ButtonControl}:first-child {
    margin-right: ${space.XXS};
  }
`;

// InfoHeader styles
export const InfoHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 ${space.XS};
`;

export const InfoHeaderContainer = styled.div`
  display: flex;
`;

export const ProfileIconContainer = styled.div`
  padding-right: ${space.XXS};
`;

export const InfoHeaderFieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const InfoHeadFieldControl = styled.div`
  width: 100%;
  max-width: 760px;

  .MuiInput-underline:before {
    border-bottom-width: 1px;
  }

  .MuiInputBase-multiline {
    padding-top: 0;

    textarea {
      margin-top: 0;

      &::placeholder {
        opacity: 0.7;
      }
    }
  }
`;

export const TexSection = styled.div`
  display: inline-block;
  margin-bottom: ${space.XXS};
`;

const Text = styled.span`
  font-size: ${typography.size.XS};
  color: ${colors.PRIMARY};
`;

export const MainText = styled(Text)`
  font-family: ${typography.font.TITLE};
`;

export const AdditionalText = styled(Text)`
  font-family: ${typography.font.TEXT};
`;

// InviteFieldsSection styles
export const InviteFieldControl = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 12px 20px;
`;

export const InviteFieldsContainer = styled.div`
  border-top: solid 1px ${colors.OUTLINES};
  border-bottom: solid 1px ${colors.OUTLINES};

  ${InviteFieldControl}:not(:last-child) {
    border-bottom: solid 1px ${colors.OUTLINES};
  }
`;

export const IviteFieldLabel = styled.label`
  margin-right: ${space.XXS};
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.XS};
  color: ${colors.PRIMARY};
  white-space: nowrap;
`;

// Eyeball section
export const EyeballSectionContainer = styled.div`
  margin: 5px 0;
  padding: 24px ${space.XS};
`;

export const EyeballSectionContent = styled.div`
  display: flex;
  align-items: center;
`;

export const Eyeball = styled(EyeballIcon)`
  margin-right: ${space.XXS};
`;

export const EyeballText = styled.span`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  color: ${colors.PRIMARY};
`;
