import { createSlice } from '@reduxjs/toolkit';

const recaptchaInitialState = {
  recaptchaToken: '',
  refreshRepatchaValue: 0,
};

const recaptchaSlice = createSlice({
  name: 'RECAPTCHA',
  initialState: recaptchaInitialState,
  reducers: {
    setRecaptchaToken: (state, { payload: recaptchaToken }) => {
      state.recaptchaToken = recaptchaToken;
    },
    refreshRecapthca: (state) => {
      state.refreshRepatchaValue = Math.random();
    },
  },
});

export const actionTypes = recaptchaSlice.actions;

const recaptchaReducer = recaptchaSlice.reducer;

export default recaptchaReducer;
