import React from 'react';
import { useDispatch } from 'react-redux';

import { actionTypes as toastActionTypes } from '~/modules/Toast/redux/toastSlice';

import {
  CheckEmailSectionWrapper,
  CheckEmailSectionContainer,
  LogoWrapper,
  Logo,
  LogoTitle,
  Title,
  Description,
  Email,
  SubTitle,
} from './CheckEmailSectionStyles';

function CheckEmailSection() {
  const dispatch = useDispatch();

  const copyEmail = (event) => {
    const data = event.currentTarget.textContent;

    navigator.clipboard.writeText(data);
    dispatch(toastActionTypes.showSuccessToast('Email is copied.'));
  };

  return (
    <CheckEmailSectionWrapper>
      <CheckEmailSectionContainer>
        <LogoWrapper>
          <Logo />
          <LogoTitle>upful.ai</LogoTitle>
        </LogoWrapper>
        <Title>
          Check your e-mail to create your password. The link expires. If you
          don&apos;t see it within a minute, please check your
          &quot;Promotions&quot; folder or your &quot;Spam&quot; folder.
        </Title>
        <SubTitle>
          If you still don&apos;t see it, try signing up again (we&apos;ve seen
          some people have typos in their e-mail addresses) or perhaps your
          employer&apos;s e-mail service blocked it (we&apos;ve also seen this).
        </SubTitle>
        <Description>
          If you think the e-mail was blocked, please send us an e-mail (
          <Email onClick={copyEmail}>missingWelcomeEmail@upful.ai</Email>) and
          we&apos;ll try to sort it out. Thanks for your patience!
        </Description>
      </CheckEmailSectionContainer>
    </CheckEmailSectionWrapper>
  );
}

export default CheckEmailSection;
