import * as Yup from 'yup';

import { PHONE_NUMBER_REGEXP } from '~/utils/formsData/regexp';

export const textFieldsData = {
  phoneNumber: {
    type: 'tel',
    label: 'Please Enter Your Mobile Phone Number',
    placeholder: '###-###-####',
  },
};

export const errorDictionary = {
  IVCV3: {
    phoneNumber: `Please make sure to enter your area code and phone number. It should be exactly 10 digits.`,
  },
  TCC0: {
    toast: `We are unable to send a text (SMS) to the phone number you provided. Please try again or provide a different mobile (text-enabled) phone number.`,
  },
  CCC3: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CCC0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CC0: {
    toast: `We are having technical difficulties. Can you try this again?`,
  },
  CC2: {},
  IVPCV2: { toast: `Please first make an account.` },
  IVPCV3: { toast: `Please first make an account.` },
  IVPCV4: { toast: `Please first make an account.` },
  IVPCV5: { toast: `Please first make an account.` },
  IVPCV6: { toast: `Please first make an account.` },
  IVPCV7: { toast: `Please first make an account.` },
};

export const resendErrorDictionary = {
  TCC0: {
    toast: `We are unable to send a text (SMS) to the phone number you provided. Please try again or provide a different mobile (text-enabled) phone number.`,
  },
  CCC2: {
    toast: `Please wait at least 1 minute before requesting a new phone verification code.`,
  },
};

// Initial values
export const phoneNumberInitialValue = {
  phoneNumber: '',
};

export const phoneNumberValidationSchema = Yup.object({
  phoneNumber: Yup.string()
    .trim()
    .required('Phone number should not be empty')
    .matches(
      PHONE_NUMBER_REGEXP,
      'Please make sure to enter your area code and phone number. It should be exactly 10 digits.',
    ),
});
