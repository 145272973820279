import React from 'react';

import { AuthPageWrapper, PageContainer } from '~/elements';
import { VerifyCompanySection } from '~/modules/Auth';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    page: 'VerifyCompany',
  },
  dataLayerName: 'PageDataLayer',
};

function VerifyCompany() {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <AuthPageWrapper>
      <PageContainer withAdditionalMargin>
        <VerifyCompanySection />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default VerifyCompany;
