import React from 'react';
import { SnackbarProvider } from 'notistack';

import Toast from './components';

const ToastProvider = ({ children, maxSnack, hideIconVariant }) => (
  <SnackbarProvider maxSnack={maxSnack} hideIconVariant={hideIconVariant}>
    {children}
    <Toast />
  </SnackbarProvider>
);

ToastProvider.defaultProps = {
  maxSnack: 5,
  hideIconVariant: false,
};

export default ToastProvider;
