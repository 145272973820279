const colors = {
  PRIMARY: '#1a2335',
  SECONDARY_TEXT: '#8c9ec3',
  OUTLINES: '#dee4f0',
  SHADOWS: 'rgba(222, 228, 240, 0.5)',
  SIDEBAR_SHADOW: '#e9e7f3',
  PRIMARY_BG: '#f4f8fe',
  WHITE: '#FFFFFF',
  BRAND_COLOR: '#18cb84',
  BRAND_COLOR_HOVER: '#13da8b',
  ERROR: '#ee446c',
  TASK_TAG: '#18cbc9',
  ACCOMPLISHMENT_TAG: '#fa9e43',
  DELIVARABLE_TAG: '#2b73fb',
  MILESTONE_TAG: '#7b36f7',
  ASSUMPTIVE: '#ffec67',
  NAME_CALLING: '#fa64e3',
  CHECKBOX_EFFECT: 'rgba(140, 158, 195, 0.1)',
  BACKDROP: 'rgba(26, 35, 53, 0.5)',
  PROGRESS_BAR: '#31405c',
  SPONSORS_BACKGROUND: 'rgba(255, 255, 255)',
};

export default colors;
