import React, { memo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

import { getRefreshRecaptchaValue } from './redux/selectors';
import { actionTypes } from './redux/recaptchaSlice';

function Recaptcha({ widgetId }) {
  const dispatch = useDispatch();
  const refreshRepatchaValue = useSelector(getRefreshRecaptchaValue);

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    executeRecaptcha?.(widgetId)
      .then((gToken) => {
        dispatch(actionTypes.setRecaptchaToken(gToken, refreshRepatchaValue));
      })
      .catch((err) => {
        console.error(err);
      });
  }, [executeRecaptcha, widgetId, dispatch, refreshRepatchaValue]);

  return <div />;
}

export default memo(Recaptcha);
