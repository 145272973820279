import React from 'react';

import { IntroSection } from '~/components';

import { IntroWrapper } from './IntroStyles';

function Intro() {
  return (
    <IntroWrapper>
      <IntroSection description="As a startup, we love to hear directly from you with questions or feedback. Sometimes when there are technical difficulties or because we haven’t built out features yet, you might need to contact us for support. We thank you for your patience as we grow!" />
    </IntroWrapper>
  );
}

export default Intro;
