/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { breakpoints } from '~/styles';

export const FieldsContainer = styled.div`
  display: flex;
  flex-wrap: ${(props) => (props.horizontal ? 'wrap' : 'no-wrap')};
  flex-direction: ${(props) =>
    props.horizontal ? 'column' : props.vertical ? 'column' : null};
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;

  > div:nth-child(2n + 1) {
    @media (min-width: ${breakpoints.SM}) {
      margin-right: ${(props) => (props.horizontal ? '20px' : '0')};
      box-sizing: border-box;
    }

    @media (min-width: ${breakpoints.XL}) {
      box-sizing: content-box;
    }
  }

  @media (min-width: ${breakpoints.SM}) {
    flex-wrap: ${(props) => (props.horizontal ? 'wrap' : 'no-wrap')};
    flex-direction: ${(props) =>
      props.horizontal ? 'row' : props.vertical ? 'column' : null};
  }
`;

export const FieldControl = styled.div`
  display: flex;
  flex: ${(props) => (props.horizontal ? '0 1 calc(50% - 10px)' : 1)};
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;

  :last-child {
    margin-right: 0;
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 390px;
  }
`;
