import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ArrowButtonContainer, ArrowIconStyled } from './ArrowButtonStyles';

function ArrowButton({ isCollapsed, onClick }) {
  return (
    <ArrowButtonContainer onClick={onClick}>
      <ArrowIconStyled collapsed={`${isCollapsed}`} />
    </ArrowButtonContainer>
  );
}

ArrowButton.propTypes = {
  isCollapsed: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default memo(ArrowButton);
