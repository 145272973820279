import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, Form } from 'formik';

import { actionTypes } from '~/modules/Dashboard/MyWork/redux/myWorkSlice';

import { initialValues, validationSchema, errorDictionary } from './formsData';
import { AddWorkItemModalWrapper } from './AddWorkItemModalStyles';
import {
  getEditingWorkItemsValues,
  getEditingStatus,
} from '../../redux/selectors';

import InfoHeader from './InfoHeader';
import InviteFieldsSection from './InviteFieldsSection';
import EyeballSection from './EyeballSection';
import Footer from './Footer';

function AddWorkItemModal() {
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const editingValues = useSelector(getEditingWorkItemsValues);
  const isEditing = useSelector(getEditingStatus);
  const currentValues = isEditing ? editingValues : initialValues;

  const postWorkItem = (data, { setSubmitting, setStatus }) => {
    dispatch(
      actionTypes.addWorkItemRequest({
        data,
        errorDictionary,
        setSubmitting,
        setStatus,
      }),
    );
  };

  const saveEditingValues = () =>
    formRef.current &&
    dispatch(actionTypes.autoSaveEditingWorkItem(formRef.current.values));

  useEffect(() => {
    return () => {
      saveEditingValues();
    };
  });

  return (
    <AddWorkItemModalWrapper>
      <Formik
        validateOnMount
        initialValues={currentValues}
        validationSchema={validationSchema}
        onSubmit={postWorkItem}
        innerRef={formRef}
      >
        {({ handleSubmit, isValid, isSubmitting, values }) => (
          <Form onSubmit={handleSubmit}>
            <InfoHeader />
            <InviteFieldsSection />
            <EyeballSection
              initialCollaborators={values.collaborators}
              initialStakeholders={values.stakeholders}
            />
            <Footer isValid={isValid} isSubmitting={isSubmitting} />
          </Form>
        )}
      </Formik>
    </AddWorkItemModalWrapper>
  );
}

export default AddWorkItemModal;
