import { createSlice } from '@reduxjs/toolkit';

const colleaguesFeedbackState = {
  activeItem: {},
};

const colleaguesFeedabckSlice = createSlice({
  name: 'COLLEAGUES_FEEDBACK',
  initialState: colleaguesFeedbackState,
  reducers: {
    getAllWorkItemRequest: () => {},
    getAllWorkItemSuccess: (state, { payload: activeItem }) => {
      state.activeItem = activeItem;
    },
    getAllWorkItemError: () => {},
  },
});

export const actionTypes = colleaguesFeedabckSlice.actions;

const colleaguesFeedbackReducer = colleaguesFeedabckSlice.reducer;

export default colleaguesFeedbackReducer;
