import React from 'react';
import { components } from 'react-select';

import {
  InviteOptionWrapper,
  InviteLabel,
  InviteLabelDescription,
} from './MultiSelectStyles';

function InviteNoOptionMessage(props) {
  const {
    selectProps: {
      isInviteEmailError,
      noOptionsData: { title, description, inviteDescription },
    },
  } = props;

  const addEmail = () => {
    const {
      setValue,
      selectProps: { inputValue, value },
    } = props;

    const values = value === null ? [] : value;

    const newValue = { label: inputValue, value: inputValue };
    const newValues = [...values, newValue];

    setValue(newValues);
  };

  return (
    <components.NoOptionsMessage {...props}>
      <InviteOptionWrapper onClick={isInviteEmailError ? null : addEmail}>
        <InviteLabel>{title}</InviteLabel>
        <InviteLabelDescription>
          {isInviteEmailError ? description : inviteDescription}
        </InviteLabelDescription>
      </InviteOptionWrapper>
    </components.NoOptionsMessage>
  );
}

export default InviteNoOptionMessage;
