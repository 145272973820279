import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { SvgIconTypeMap } from '@material-ui/core/SvgIcon';

export default function InstructionSection({
  children,
  title,
  horizontalItems,
  subtitle = '',
}: {
  children: React.ReactNode;
  title: string;
  subtitle?: string;
  horizontalItems?: {
    Icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
    Img?: React.FunctionComponent<
      React.SVGProps<SVGSVGElement> & {
        title?: string | undefined;
      }
    >;
    ImgAlt?: string;
    body: string;
    title?: string;
    note?: string;
  }[];
}): JSX.Element {
  return (
    <div className="w-[500px] text-[#1A2335] font-['Lato-Regular'] flex flex-col items-center">
      <div className="w-full text-center  font-bold text-[25px] mb-[35px]">
        {title}
        {subtitle ? (
          <div className="w-full text-center font-bold text-[18px]">
            {subtitle}
          </div>
        ) : null}
      </div>

      {horizontalItems?.length ? (
        <div className="flex flex-row items-start justify-between mx-auto mb-[35px] w-full">
          {horizontalItems.map((item, i, a) => (
            <div
              className={
                `w-1/${a.length} flex flex-col items-center justify-between mx-2 h-full text-center` +
                (i < a.length - 1
                  ? `border-[#6A7DA4] border-r-2 ml-2 px-4`
                  : ``)
              }
            >
              <div className="w-full">
                <div className="w-full flex flex-row justify-center items-center mb-[20px]">
                  <div className="rounded-full w-[60px] h-[60px] leading-[60px] bg-white shadow-[0_0_20px_0_rgba(26,35,53,0.15)] flex flex-col items-center justify-center relative">
                    {item.Icon ? (
                      <item.Icon
                        className=""
                        style={{ fontSize: '25px', position: 'absolute' }}
                      />
                    ) : item.Img ? (
                      <item.Img className="w-[25px] h-[25px] absolute" />
                    ) : null}
                  </div>
                </div>
                <div className="text-[18px] font-bold w-full text-center">
                  {item.title}
                </div>
                <div className="text-[16px] font-400 text-center w-full">
                  {item.body}
                </div>
              </div>
              <div className="w-full text-[14px] text-[#6A7DA4] mt-[30px]">
                {item.note}
              </div>
            </div>
          ))}
        </div>
      ) : null}
      <div className="ml-10 text-[16px]">{children}</div>
    </div>
  );
}
