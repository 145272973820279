import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { ProfileIcon } from '~/components';
import { getPersonFullName } from '~/modules/Profile/redux/selectors';

import { STATUS } from './constans';
import {
  WorkingOnWrapper,
  Title,
  WorkingOnButton,
  ButtonText,
} from './WorkingOnStyles';

function WorkingOn({ handleWorkingOnClick, isEditing }) {
  const { firstName, lastName } = useSelector(getPersonFullName);
  const iconText = firstName.charAt(0).concat(lastName.charAt(0));

  return (
    <WorkingOnWrapper>
      <Title>What are you working on?</Title>
      <WorkingOnButton onClick={handleWorkingOnClick}>
        <ProfileIcon iconText={iconText} />
        <ButtonText isEditing={isEditing}>
          {isEditing ? STATUS.EDITING : STATUS.ADDING}
        </ButtonText>
      </WorkingOnButton>
    </WorkingOnWrapper>
  );
}

WorkingOn.propTypes = {
  isEditing: PropTypes.bool.isRequired,
  handleWorkingOnClick: PropTypes.func.isRequired,
};

export default WorkingOn;
