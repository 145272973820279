import BasicInfoForm from './components/BasicInfoForm';
import PositionInfoForm from './components/PositionInfoForm';
import DemographicsForm from './components/DemographicsForm';
import DisabilityForm from './components/DisabilityForm';
import LinkedInForm from './components/LinkedInForm';

export const profileSteps = [
  { id: 0, name: 'Basic Info', component: BasicInfoForm, isPassed: true },
  { id: 1, name: 'Demographics', component: DemographicsForm, isPassed: false },
  {
    id: 2,
    name: 'Disability',
    component: DisabilityForm,
    isPassed: true,
    isDisabled: true,
  },
  {
    id: 3,
    name: 'Position Info',
    component: PositionInfoForm,
    isPassed: false,
  },
  { id: 4, name: 'LinkedIn', component: LinkedInForm, isPassed: false },
];

export const stepsFields = [
  ['firstName', 'lastName', 'email', 'companyName'],
  [
    'yearOfBirth',
    'gender',
    'ethnicity',
    'religion',
    'lgbtqStatus',
    'hasDisability',
  ],
  ['disability'],
  [
    'department',
    'title',
    'manager',
    'salary',
    'dateStarted',
    'dateStartedWithManager',
    'dateOfLastPromotion',
    'dateOfLastRaise',
  ],
  ['linkedin'],
];

export const profileFieldMap = {
  first_name: 'firstName',
  last_name: 'lastName',
  gender: 'gender',
  ethnicity: 'ethnicity',
  religion: 'religion',
  lgbtq_status: 'lgbtqStatus',
  has_disability: 'hasDisability',
  year_of_birth: 'yearOfBirth',
  department: 'department',
  title: 'title',
  manager: 'manager',
  salary: 'salary',
  date_started: 'dateStarted',
  date_started_with_manager: 'dateStartedWithManager',
  date_of_last_promotion: 'dateOfLastPromotion',
  date_of_last_raise: 'dateOfLastRaise',
  linkedin: 'linkedin',
  company_name: 'companyName',
};
