import React from 'react';

import { Sponsor } from '~/components';
import { SponsorsList, SponsorsHolder } from './SponsorsStyles';
import sponsorList from './sponsorList';

function Sponsors() {
  return (
    <SponsorsHolder>
      <SponsorsList>
        {sponsorList.map((sponsor) => {
          return (
            <Sponsor
              key={sponsor.key}
              alt={sponsor.alt}
              src={sponsor.src}
              link={sponsor.link}
              width={`${(1 / sponsorList.length) * 100}%`}
            />
          );
        })}
      </SponsorsList>
    </SponsorsHolder>
  );
}

export default Sponsors;
