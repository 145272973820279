import { createSlice } from '@reduxjs/toolkit';

const authPrividerInitialState = {
  isAuthenticated: false,
  personId: '',
  companyId: '',
  token: '',
  name: '',
  expiresAt: 0,
  refresh: '',
  refreshExpiresAt: 0,
  free_email: false,
};

const authProviderSlice = createSlice({
  name: 'AUTH',
  initialState: authPrividerInitialState,
  reducers: {
    setAuthenticatedData: (state, action) => {
      const {
        free_email,
        isAuthenticated,
        token,
        person,
        company,
        name,
        refresh,
        expiresAt,
        refreshExpiresAt,
      } = action.payload;
      state.isAuthenticated = isAuthenticated;
      state.token = token;
      state.personId = person;
      state.companyId = company;
      state.name = name;
      state.refresh = refresh;
      state.expiresAt = expiresAt;
      state.refreshExpiresAt = refreshExpiresAt;
      state.free_email = free_email;
    },
    setRefreshedToken: (state, { payload }) => {
      state.token = payload;
    },
    refreshTokenRequest: () => {},
    logoutRequest: () => authPrividerInitialState,
    authenticate: () => {},
  },
});

export const actionTypes = authProviderSlice.actions;

export default authProviderSlice.reducer;
