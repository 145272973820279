import React from 'react';
import PropTypes from 'prop-types';

import {
  MyFeedbackItemExpandWrapper,
  MyFeedbackItemPrompt,
  MyFeedbackItemReplay,
} from './MyFeedbackItemStyles';

function MyFeedbackItemExpand({ text, prompts }) {
  return (
    <MyFeedbackItemExpandWrapper>
      {text.map((item, i) => (
        <div key={`my-feedback-replay-${prompts[i]}`}>
          <MyFeedbackItemPrompt>{prompts[i]}</MyFeedbackItemPrompt>
          <MyFeedbackItemReplay isEmpty={item.length === 0}>
            {item.length !== 0 ? item : 'Not answered.'}
          </MyFeedbackItemReplay>
        </div>
      ))}
    </MyFeedbackItemExpandWrapper>
  );
}

MyFeedbackItemExpand.propTypes = {
  text: PropTypes.arrayOf(PropTypes.string).isRequired,
  prompts: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default MyFeedbackItemExpand;
