import { put, all, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { stepsFields, profileSteps } from '~/modules/Profile/constants';
import {
  requestWrapper as request,
  setPassedSteps,
  deleteWhitespaces,
  getProfileErrors,
  getFieldStatus,
} from '~/utils';
import api from '~/services';

import { getPersonId } from '~/modules/Auth/AuthProvider/redux/selectors';
import { actionTypes as loginActionTypes } from '~/modules/Auth/Login/redux/loginSlice';
import { actionTypes as toastActionTypes } from '~/modules/Toast/redux/toastSlice';
import { pageRoutes } from '~/modules/Navigation/constants';
import {
  getBasicProfileInfo,
  getProfileData,
  getDemographicsData,
  getDisabilities,
  getLinkedinData,
  getPositionInfoData,
} from './selectors';
import {
  personInfoResponseNormalizer,
  updatePersonRequestNormalizer,
  updateBasicInfoDataNormalizer,
  updateDemographicsDataNormalizer,
  updateDisabilityRequestNormalizer,
  updateLinkedinNormalizer,
  updatePersonInfoNormalizer,
} from './normalizer';

import { actionTypes } from './profileSlice';

function* getPersonInfo() {
  const { response } = yield request(api.getPersonInfo);

  if (response) {
    const data = personInfoResponseNormalizer(response?.info);

    yield put(actionTypes.getPersonInfoSuccess(data));
  } else {
    yield put(actionTypes.getPersonInfoError());
  }
}

function* updateBasicInfo({
  payload: { data, errorDictionary, setErrors, setSubmitting, setStatus },
}) {
  const personId = yield select(getPersonId);
  const oldData = yield select(getBasicProfileInfo);
  const trimedData = deleteWhitespaces(data);

  const { newData, old } = updateBasicInfoDataNormalizer(trimedData, oldData);
  const updatePersonData = updatePersonRequestNormalizer(
    newData,
    old,
    personId,
  );

  const { response, error } = yield request(
    api.updatePersonInfo,
    updatePersonData,
    errorDictionary,
    setErrors,
    getProfileErrors,
  );

  if (response) {
    yield put(actionTypes.updateBasicInfoSuccess());
    setSubmitting(false);
    yield put(toastActionTypes.showSuccessToast('Saved!'));
    yield put(actionTypes.navigateToTheNextStep());
  } else {
    if (error?.data?.deltas) {
      const fieldsStatuses = getFieldStatus(error.data.deltas);
      setStatus(fieldsStatuses);
    }

    yield put(actionTypes.updateBasicInfoError());
    setSubmitting(false);
  }
}

function* updateLinkedin({
  payload: { data, errorDictionary, setErrors, setSubmitting },
}) {
  const personId = yield select(getPersonId);
  const oldData = yield select(getLinkedinData);
  const trimedData = deleteWhitespaces(data);

  const { newData, old } = updateLinkedinNormalizer(trimedData, oldData);
  const updatePersonData = updatePersonRequestNormalizer(
    newData,
    old,
    personId,
  );

  const { response } = yield request(
    api.updatePersonInfo,
    updatePersonData,
    errorDictionary,
    setErrors,
    getProfileErrors,
  );

  if (response) {
    yield put(actionTypes.updateLinkedinSuccess());
    yield put(toastActionTypes.showSuccessToast('Saved!'));
    setSubmitting(false);
    yield put(actionTypes.getPersonInfoRequest());

    yield put(push(pageRoutes.MAIN));
  } else {
    yield put(actionTypes.updateLinkedinError());
    setSubmitting(false);
  }
}

function* setIncompleteStep() {
  const profileData = yield select(getProfileData);

  const updatedStepsData = setPassedSteps(
    profileData,
    stepsFields,
    profileSteps,
  );

  const passedSteps = {};
  updatedStepsData.forEach((stepData) => {
    passedSteps[stepData.name] = stepData.isPassed;
  });

  if (profileData.demographics?.hasDisability === false) {
    updatedStepsData.find((step) => step.name === 'Disability').isPassed = true;
  }

  let firstIncompleteStep = updatedStepsData.findIndex(
    (stepData) => stepData.isPassed === false,
  );

  if (firstIncompleteStep === -1) {
    firstIncompleteStep = 3;
  }

  yield put(actionTypes.setPassedSteps(passedSteps));
  yield put(actionTypes.setCurrentStep(firstIncompleteStep));

  yield put(actionTypes.setCurrentIncompleteStepSuccess());
}

function* updateDemographics({
  payload: { data, errorDictionary, setErrors, setSubmitting, setStatus },
}) {
  const personId = yield select(getPersonId);
  const oldData = yield select(getDemographicsData);

  const { newValues, oldValues } = updateDemographicsDataNormalizer(
    data,
    oldData,
  );

  const updatePersonData = updatePersonRequestNormalizer(
    newValues,
    oldValues,
    personId,
  );

  const { response, error } = yield request(
    api.updatePersonInfo,
    updatePersonData,
    errorDictionary,
    setErrors,
    getProfileErrors,
  );

  if (response) {
    yield put(actionTypes.updateDemographicsSuccess());
    yield put(toastActionTypes.showSuccessToast('Saved!'));
    setSubmitting(false);

    if (data.hasDisability === true) {
      yield put(actionTypes.navigateToTheNextStep());
    } else {
      yield put(actionTypes.setCurrentStep(3));
    }
  } else {
    if (error?.data?.deltas) {
      const fieldsStatuses = getFieldStatus(error.data.deltas);
      setStatus(fieldsStatuses);
    }

    yield put(actionTypes.updateDemographicsError());
    setSubmitting(false);
  }
}

function* updateDisability({ payload: { data, setSubmitting } }) {
  const oldDisabilityValues = yield select(getDisabilities);
  const normalizedData = updateDisabilityRequestNormalizer(data);

  let isSuccess;

  if (oldDisabilityValues.length === 0) {
    const { response } = yield request(api.putPersonDisability, normalizedData);

    isSuccess = Boolean(response);
  } else {
    const oldValuesCount = oldDisabilityValues.length;
    const putData = normalizedData.slice(oldValuesCount);
    const postData = normalizedData.slice(0, oldValuesCount);

    const { response: postResponse } = yield request(
      api.updatePersonDisability,
      postData,
    );

    isSuccess = Boolean(postResponse);

    if (putData.length !== 0) {
      const { response: putResponse } = yield request(
        api.putPersonDisability,
        putData,
      );

      isSuccess = Boolean(postResponse) && Boolean(putResponse);
    }
  }

  if (isSuccess) {
    yield put(actionTypes.updateDisabilitySuccess());
    yield put(toastActionTypes.showSuccessToast('Saved!'));
    setSubmitting(false);
    yield put(actionTypes.navigateToTheNextStep());
  } else {
    yield put(actionTypes.updateDisabilityError());
    setSubmitting(false);
  }
}

function* updatePositionInfo({
  payload: { data, errorDictionary, setErrors, setSubmitting, setStatus },
}) {
  const personId = yield select(getPersonId);
  const oldData = yield select(getPositionInfoData);
  const { newValues, oldValues } = updatePersonInfoNormalizer(data, oldData);

  const updatePersonData = updatePersonRequestNormalizer(
    newValues,
    oldValues,
    personId,
  );

  const { response, error } = yield request(
    api.updatePersonInfo,
    updatePersonData,
    errorDictionary,
    setErrors,
    getProfileErrors,
  );

  if (response) {
    yield put(actionTypes.updatePositionInfoSuccess());
    yield put(toastActionTypes.showSuccessToast('Saved!'));
    setSubmitting(false);
    yield put(actionTypes.navigateToTheNextStep());
  } else {
    if (error?.data?.deltas) {
      const fieldsStatuses = getFieldStatus(error.data.deltas);
      setStatus(fieldsStatuses);
    }

    yield put(actionTypes.updatePositionInfoError());
    setSubmitting(false);
  }
}

export default function* profileSaga() {
  yield all([
    takeLatest(
      [actionTypes.getPersonInfoRequest, loginActionTypes.loginSuccess],
      getPersonInfo,
    ),
    takeLatest(actionTypes.updateBasicInfoRequest, updateBasicInfo),
    takeLatest(actionTypes.setCurrentIncompleteStep, setIncompleteStep),
    takeLatest(actionTypes.updateDemographicsRequest, updateDemographics),
    takeLatest(actionTypes.updateDisabilityRequest, updateDisability),
    takeLatest(actionTypes.updatePositionInfoRequest, updatePositionInfo),
    takeLatest(actionTypes.updateLinkedinRequest, updateLinkedin),
  ]);
}
