import React from 'react';

import { TexSection, MainText, AdditionalText } from './AddTeamModalStyles';

function InfoHeader() {
  return (
    <TexSection>
      <MainText>Tag Your Team</MainText>
      <AdditionalText>
        Tag or invite your collaborators and stakeholders in order to get
        feedback from them on this specific work item.
      </AdditionalText>
    </TexSection>
  );
}

export default InfoHeader;
