import { createSlice } from '@reduxjs/toolkit';

const navigationInitialState = {
  isSideBarOpened: true,
};

const navigationSlice = createSlice({
  name: 'NAVIGATION',
  initialState: navigationInitialState,
  reducers: {
    toggleSideBar: (state) => {
      state.isSideBarOpened = !state.isSideBarOpened;
    },
  },
});

export const actionTypes = navigationSlice.actions;

export default navigationSlice.reducer;
