import styled from 'styled-components';

import { SectionText } from '~/elements';
import { breakpoints, colors, space } from '~/styles';

export const TopBarNavigationButtonContainer = styled.div`
  margin: 0 5px;
`;

export const TextContainer = styled.span`
  padding: 0;
  margin: 0;
  font-family: Lato-Regular;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${(props) =>
    props.isOneItem ? `${colors.BRAND_COLOR}` : `${colors.WHITE}`};

  @media (min-width: ${breakpoints.SM}) {
    font-size: 18px;
  }

  &:hover {
    cursor: pointer;
    color: ${(props) =>
      props.isOneItem ? `${colors.BRAND_COLOR_HOVER}` : `${colors.WHITE}`};
  }
`;

export const OneItemText = styled(SectionText)`
  display: initial;
  margin-right: ${space.XXS};
`;
