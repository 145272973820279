import { createSelector } from '@reduxjs/toolkit';

const routerSelector = (state) => state.router;
const navigationSelector = (state) => state.navigation;

export const routerPathnameSelector = createSelector(
  routerSelector,
  (router) => router.location.pathname,
);

export const getSearchDataSelector = createSelector(
  routerSelector,
  ({ location }) => location.query,
);

export const getCurrentPathname = createSelector(
  routerSelector,
  ({ location }) => location.pathname,
);

export const isSideBarOpenedSelector = createSelector(
  navigationSelector,
  ({ isSideBarOpened }) => isSideBarOpened,
);
