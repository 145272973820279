export const normalizeCollaboratorsResponse = (peopleInfo) => {
  const options = [];

  if (Array.isArray(peopleInfo) && peopleInfo.length !== 0) {
    peopleInfo.forEach((collaborator) => {
      const option = {
        label: `${collaborator.first_name} ${collaborator.last_name}`,
        labelDescription: collaborator.email,
        value: collaborator.email,
      };

      options.push(option);
    });

    return options;
  }

  return [];
};

export const normalizePeopleResponse = (peopleInfo) => {
  const options = [];

  if (Array.isArray(peopleInfo) && peopleInfo.length !== 0) {
    peopleInfo.forEach((collaborator) => {
      const option = {
        firstName: collaborator.first_name,
        lastName: collaborator.last_name,
        email: collaborator.email,
      };

      options.push(option);
    });

    return options;
  }

  return [];
};

export const normalizePostWorkItemRequest = (data) => {
  const collaborators =
    data?.collaborators?.map((collaborator) => collaborator.value) || [];
  const stakeholders =
    data?.stakeholders?.map((stakeholder) => stakeholder.value) || [];

  const description = data.description.replace(/\n/g, ' ').trim();

  return {
    collaborators,
    stakeholders,
    title: data.title.trim(),
    description,
    work_type: 0,
  };
};

export const normalizeUpdateWorkItemRequest = (data) => {
  // TODO: Add common normilizer for post and update
  const collaborators =
    data?.collaborators?.map((collaborator) => collaborator.value) || [];
  const stakeholders =
    data?.stakeholders?.map((stakeholder) => stakeholder.value) || [];

  return {
    collaborators,
    stakeholders,
  };
};

export const normilizeWorkItemError = (error) => {
  const { collaborators, stakeholders } =
    error?.data?.errors.UWI2 ||
    error?.data?.errors.UWI10 ||
    error?.data?.errors.UWI16 ||
    error?.data?.errors.UWI13; // TODO: Rewrite error check

  const invalidCollaborators = collaborators || [];
  const invalidStakeholders = stakeholders || [];

  const invalidEmails = [...invalidCollaborators, ...invalidStakeholders];

  return invalidEmails;
};
