import React from 'react';
import { useDispatch } from 'react-redux';

import { actionTypes as toastActionTypes } from '~/modules/Toast/redux/toastSlice';

import {
  CheckEmailSectionWrapper,
  CheckEmailSectionContainer,
  LogoWrapper,
  Logo,
  LogoTitle,
  Title,
  Description,
  Email,
} from '../CheckEmail/CheckEmailSectionStyles';

function VerifyCompanySection() {
  const dispatch = useDispatch();

  const copyEmail = (event) => {
    const data = event.currentTarget.textContent;

    navigator.clipboard.writeText(data);
    dispatch(toastActionTypes.showSuccessToast('Email is copied.'));
  };

  return (
    <CheckEmailSectionWrapper>
      <CheckEmailSectionContainer>
        <LogoWrapper>
          <Logo />
          <LogoTitle>upful.ai</LogoTitle>
        </LogoWrapper>
        <Title>
          We will shortly verify your company and then allow access. You will
          receive an email once verification is complete.
        </Title>
        <Description>
          If you don’t receive the e-mail within 1 day, please contact us at{' '}
          <Email onClick={copyEmail}>missingVerifyCompanyEmail@upful.ai</Email>
        </Description>
      </CheckEmailSectionContainer>
    </CheckEmailSectionWrapper>
  );
}

export default VerifyCompanySection;
