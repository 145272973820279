/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';

import {
  FormFieldMap,
  FormTextField,
  FormSubmitButton,
  Button,
} from '~/components';

import {
  submitPhoneNumberFetchingSelector,
  isPhoneNumberSubmittedSelector,
  isResendFetchingSelector,
  isResendSuccessSelector,
} from '~/modules/Auth/CreateCompany/redux/selectors';

import { actionTypes as toastActionTypes } from '~/modules/Toast/redux/toastSlice';
import { actionTypes } from '~/modules/Auth/CreateCompany/redux/createCompanySlice';

import {
  textFieldsData,
  phoneNumberInitialValue,
  phoneNumberValidationSchema,
  errorDictionary,
  resendErrorDictionary,
} from './formsData';

import {
  FormContainer,
  Form,
  FormTitle,
  FormDescription,
  PhoneIconWrapper,
  PhoneIcon,
  ButtonContainer,
  ButtonControl,
} from './CreatePhoneFormStyle';

function CreatePhoneForm() {
  const dispatch = useDispatch();
  const [isResendDisabled, switchResendDisbledStatus] = useState(false);
  const [isFirstResend, changeFirstResend] = useState(true);
  const [isTimerStarted, toggleTimer] = useState(false);

  const isResendFetching = useSelector(isResendFetchingSelector);
  const isPhoneNumberSubmitted = useSelector(isPhoneNumberSubmittedSelector);
  const isFetching = useSelector(submitPhoneNumberFetchingSelector);
  const isResendSuccess = useSelector(isResendSuccessSelector);

  const setResendTimer = () => {
    toggleTimer(true);

    setTimeout(() => {
      switchResendDisbledStatus(false);
      toggleTimer(false);
    }, 60000);
  };

  useEffect(() => {
    if (isResendSuccess) {
      setResendTimer();
      switchResendDisbledStatus(true);

      if (isFirstResend) {
        changeFirstResend(false);
      }
    }
  }, [isResendSuccess, isFirstResend]);

  useEffect(() => {
    if (isPhoneNumberSubmitted) {
      setResendTimer();
    }
  }, [isPhoneNumberSubmitted]);

  const submitPhoneNumber = (data, { setErrors }) => {
    dispatch(
      actionTypes.submitPhoneNumberRequest({
        data,
        errorDictionary,
        setErrors,
      }),
    );
  };

  const handleResend = (phoneNumber) => {
    if (isFirstResend && isTimerStarted) {
      dispatch(
        toastActionTypes.showErrorToast(
          `Please wait at least 1 minute before requesting a new code.`,
        ),
      );

      changeFirstResend(false);
      switchResendDisbledStatus(true);
    } else {
      dispatch(
        actionTypes.resendCodeRequest({
          phoneNumber,
          errorDictionary: resendErrorDictionary,
        }),
      );
    }
  };

  return (
    <FormContainer>
      <Formik
        initialValues={phoneNumberInitialValue}
        validationSchema={phoneNumberValidationSchema}
        onSubmit={submitPhoneNumber}
      >
        {({ handleSubmit, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <PhoneIconWrapper>
              <PhoneIcon />
            </PhoneIconWrapper>
            <FormTitle>Step 01</FormTitle>
            <FormDescription>
              Enter your mobile phone number so we can verify that you are a
              real person.
            </FormDescription>
            <FormFieldMap
              center
              textField={FormTextField}
              textFieldsData={textFieldsData}
            />
            <ButtonControl>
              <ButtonContainer>
                <Button
                  text="Resend"
                  disabled={
                    isResendFetching ||
                    !isPhoneNumberSubmitted ||
                    (isResendDisabled && !isFirstResend)
                  }
                  isFetching={isResendFetching}
                  onClickAction={() => handleResend(values.phoneNumber)}
                />
              </ButtonContainer>
              <ButtonContainer>
                <FormSubmitButton
                  text={isPhoneNumberSubmitted ? 'Submitted' : 'Submit'}
                  isFetching={isFetching}
                  disabled={isFetching || !isValid || isPhoneNumberSubmitted}
                />
              </ButtonContainer>
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormContainer>
  );
}

export default CreatePhoneForm;
