import { createSelector } from '@reduxjs/toolkit';

const myFeedbackSelector = (state) => state.feedback.myFeedback;

export const getActiveItem = createSelector(
  myFeedbackSelector,
  ({ activeItem }) => activeItem,
);

export const getActiveItemID = createSelector(
  getActiveItem,
  ({ work }) => work.id,
);
