import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '~/components';

function ContactUsModal({ handleClose }) {
  return (
    <Modal
      title="Thanks!"
      description="Your message has been sent. You will hear back from us shortly. Feel free to close this tab."
      handleClose={handleClose}
    />
  );
}

ContactUsModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default ContactUsModal;
