import React from 'react';
import {
  PrivacyContainer,
  PrivacyText,
  PrivacyLink,
  PrivacyHeader,
  PrivacyItem,
  PrivacyList,
} from './PrivacyStyles';

export function Privacy() {
  return (
    <PrivacyContainer>
      <PrivacyText size="large" bold>
        UPFUL, INC. Privacy Policy
      </PrivacyText>
      <PrivacyText>EFFECTIVE DATE: September 10, 2020</PrivacyText>
      <PrivacyText>
        In this Privacy Policy (“Policy”), we describe how Upful, Inc. (“Upful”)
        collects, uses, and discloses information that we obtain about visitors
        to our website upful.ai (the “Site”) and the services available through
        our Site (collectively, the “Services”).
      </PrivacyText>
      <PrivacyText>
        By visiting the Site, or using any of our Services, you agree that your
        personal information will be handled as described in this Policy. Your
        use of our Site or Services, and any dispute over privacy, is subject to
        this Policy and our{' '}
        <a
          href="https://upful.ai/terms"
          rel="noopener noreferrer"
          target="_blank"
        >
          Terms of Service
        </a>
        , including its applicable limitations on damages and the resolution of
        disputes. The Upful Terms of Service are incorporated by reference into
        this Policy.
      </PrivacyText>
      <PrivacyLink href="#A">The Information We Collect About You</PrivacyLink>
      <br />
      <PrivacyLink href="#B">
        Information We Collect Directly From You
      </PrivacyLink>
      <br />
      <PrivacyLink href="#C">Information We Collect Automatically</PrivacyLink>
      <br />
      <PrivacyLink href="#D">How We Use Your Information</PrivacyLink>
      <br />
      <PrivacyLink href="#E">How We Share Your Information</PrivacyLink>
      <br />
      <PrivacyLink href="#F">
        Our Use of Cookies and Other Tracking Mechanisms
      </PrivacyLink>
      <br />
      <PrivacyLink href="#G">Third-Party Analytics</PrivacyLink>
      <br />
      <PrivacyLink href="#H">User-Generated Content</PrivacyLink>
      <br />
      <PrivacyLink href="#I">Third-Party Links</PrivacyLink>
      <br />
      <PrivacyLink href="#J">Security of Your Personal Information</PrivacyLink>
      <br />
      <PrivacyLink href="#K">Access to Your Personal Information</PrivacyLink>
      <br />
      <PrivacyLink href="#L">
        What Choices Do You Have Regarding Use of Your Personal Information?
      </PrivacyLink>
      <br />
      <PrivacyLink href="#M">Children Under 13</PrivacyLink>
      <br />
      <PrivacyLink href="#N">Contact Us</PrivacyLink>
      <br />
      <PrivacyLink href="#O">Changes to this Policy</PrivacyLink>
      <br />
      <PrivacyHeader id="A">The Information We Collect About You</PrivacyHeader>
      <PrivacyText>
        We collect information about you directly from you and automatically
        through your use of our Site or Services.
      </PrivacyText>
      <PrivacyHeader id="B">
        Information We Collect Directly From You.
      </PrivacyHeader>
      <PrivacyText>
        The information we collect from you depends on how you use our Services.
        To create an account, you must provide your work email address, first
        name, last name, and invite code. We also may collect additional
        information from you, including gender, ethnicity/race, religion, sexual
        orientation, disability, year of birth, department, title, manager’s
        work email address, salary, date you started working at your company,
        date you started working for your manager, date of last promotion, date
        of last raise, and LinkedIn profile URL.
      </PrivacyText>
      <PrivacyHeader id="C">
        Information We Collect Automatically.
      </PrivacyHeader>
      <PrivacyText>
        We automatically collect the information below about your use of our
        Site through cookies and other technologies. To the extent permitted by
        applicable law, we combine this information with other information we
        collect about you, including your personal information. Please see the
        section “Cookies and Other Tracking Mechanisms” below for more
        information. The information we collect includes:
      </PrivacyText>
      <PrivacyList>
        <PrivacyItem>Domain name</PrivacyItem>
        <PrivacyItem>Browser type and operating system</PrivacyItem>
        <PrivacyItem>
          Web pages you view on the Site; links you click on the Site
        </PrivacyItem>
        <PrivacyItem>Your IP address</PrivacyItem>
        <PrivacyItem>
          The length of time you visit our Site and use our Services
        </PrivacyItem>
        <PrivacyItem>
          The referring URL, or the webpage that led you to our Site
        </PrivacyItem>
      </PrivacyList>
      <PrivacyText>
        <strong>Information We Collect From Third Parties.</strong>
        In connection with the Services, we collect personal information about
        you from your employer, managers, colleagues, clients, and any other
        third parties you invite to respond. This information may include
        employee evaluations and other feedback on your job performance.
      </PrivacyText>
      <PrivacyHeader id="D"> How We Use Your Information</PrivacyHeader>
      <PrivacyText>
        We use your information, including your personal information, for the
        following purposes:
      </PrivacyText>
      <PrivacyList>
        <PrivacyItem>
          To provide our Site and Services to you, and to process customer
          orders.
        </PrivacyItem>
        <PrivacyItem>
          To improve the accuracy, helpfulness, and relevancy of employee
          coaching.
        </PrivacyItem>
        <PrivacyItem>
          To provide statistical analyses, generate reports, and otherwise
          provide feedback on evaluations to customers’ admin users.
        </PrivacyItem>
        <PrivacyItem>
          To better understand how users access and use our Site and Services,
          both on an aggregated and individualized basis, to improve our Site
          and Services, to respond to user desires and preferences, and for
          other research, development, and analytical purposes.
        </PrivacyItem>
        <PrivacyItem>
          To communicate with you about your use of our Site and Services, to
          respond to your inquiries, and for other customer service purposes.
        </PrivacyItem>
        <PrivacyItem>
          To assist us in advertising on third-party websites, mobile apps, and
          other online services, and to evaluate the success of our adverting
          campaigns through third-party channels (including our online targeted
          advertising and offline promotional campaigns).
        </PrivacyItem>
        <PrivacyItem>
          For marketing and promotional purposes. For example, we may use your
          information, such as your email address, to send you news and
          newsletters or to otherwise contact you for promotional purposes. We
          also use the information that we learn about you to assist us in
          advertising on third-party websites and to assist us in evaluating the
          success of our advertising campaigns in various platforms.
        </PrivacyItem>
        <PrivacyItem>
          To comply with legal and regulatory obligations, as part of our
          general business operations, and for other business administration
          purposes. For example, we may use your personal information to
          safeguard our business interests and to manage or transfer our assets
          or liabilities, such as in the case of an acquisition, disposition, or
          merger, as described below.
        </PrivacyItem>
        <PrivacyItem>
          Where we believe necessary to investigate, prevent or take action
          regarding illegal activities, suspected fraud, situations involving
          potential threats to the safety of any person, or violations of our
          Terms of Service or this Privacy Policy.
        </PrivacyItem>
      </PrivacyList>
      <PrivacyHeader id="E">How We Share Your Information</PrivacyHeader>
      <PrivacyText>
        We may share your information, including personal information, as
        follows:
      </PrivacyText>
      <PrivacyList>
        <PrivacyItem>
          <strong>Service Providers.</strong> We disclose the information we
          collect from you to service providers, contractors or agents who
          perform functions on our behalf. These service providers include
          databases and cloud storage platforms.
        </PrivacyItem>
        <PrivacyItem>
          <strong>Upful Platform Users.</strong> Your name, work email address,
          and any information that you post within our platform, including,
          without limitation, reviews, comments, and text will be available to,
          and searchable by, your manager, HR users, and those users of the Site
          and Services you have tagged. This does not include feedback or
          messages sent to us privately.
        </PrivacyItem>
      </PrivacyList>
      <PrivacyText>
        We also disclose information in the following circumstances:
      </PrivacyText>
      <PrivacyList>
        <PrivacyItem>
          Business Transfers. If (i) we or our affiliates are or may be acquired
          by, merged with, or invested in by another company, or (ii) if any of
          our assets are or may be transferred to another company, whether as
          part of a bankruptcy or insolvency proceeding or otherwise, we may
          transfer the information we have collected from you to the other
          company. As part of the business transfer process, we may share
          certain of your personal information with lenders, auditors, and
          third-party advisors, including attorneys and consultants.
        </PrivacyItem>
        <PrivacyItem>
          In Response to Legal Process. We disclose your information to comply
          with the law, a judicial proceeding, court order, or other legal
          process, such as in response to a court order or a subpoena.
        </PrivacyItem>
        <PrivacyItem>
          To Protect Us and Others. We disclose your information when we believe
          it is appropriate to do so to investigate, prevent, or take action
          regarding illegal activities, suspected fraud, situations involving
          potential threats to the safety of any person, violations of our Terms
          of Service or this Policy, or as evidence in litigation in which we
          are involved.
        </PrivacyItem>
      </PrivacyList>
      <PrivacyHeader id="F">
        Our Use of Cookies and Other Tracking Mechanisms
      </PrivacyHeader>
      <PrivacyText>
        We and our service providers use cookies and other tracking mechanisms
        to track information about your use of our Site or Services. We may
        combine this information with other personal information we collect from
        you (and our service providers may do so on our behalf).
      </PrivacyText>
      <PrivacyList>
        <PrivacyItem>
          <strong>Cookies.</strong> Cookies are alphanumeric identifiers that we
          transfer to your device’s hard drive through your web browser for
          record-keeping purposes. Some cookies allow us to make it easier for
          you to navigate our Site and Services, while others are used to enable
          a faster log-in process or to allow us to track your activities at our
          Site and Service. There are two types of cookies: session and
          persistent cookies.
        </PrivacyItem>
        <PrivacyItem>
          <strong>Session Cookies.</strong> Session cookies exist only during an
          online session. They disappear from your device when you close your
          browser or turn off your device. We use session cookies to allow our
          systems to uniquely identify you during a session or while you are
          logged into the Site and Services. This allows us to process your
          online transactions and requests and verify your identity, after you
          have logged in, as you move through our Site and Services.
        </PrivacyItem>
        <PrivacyItem>
          <strong>Persistent Cookies.</strong> Persistent cookies remain on your
          device after you have closed your browser or turned off your device.
          We use persistent cookies to track aggregate and statistical
          information about user activity.
        </PrivacyItem>
        <PrivacyItem>
          Disabling Cookies. Most web browsers automatically accept cookies, but
          if you prefer, you can edit your browser options to block them in the
          future. The Help portion of the toolbar on most browsers will tell you
          how to prevent your computer from accepting new cookies, how to have
          the browser notify you when you receive a new cookie, or how to
          disable cookies altogether. Visitors to our Site and Services who
          disable cookies will be able to browse certain areas of the Site, but
          some features may not function.
        </PrivacyItem>
      </PrivacyList>
      <PrivacyHeader id="G">Third-Party Analytics.</PrivacyHeader>
      <PrivacyText>
        We use automated devices and applications, such as Google Analytics, to
        evaluate usage of our Site and Services. We also may use other analytic
        means to evaluate our Site. We use these tools to help us improve our
        Site’s and Apps’ performance and user experiences. These entities may
        use cookies and other tracking technologies, such as web beacons or
        local storage objects (LSOs), to perform their services. To learn more
        about Google’s privacy practices, please review the Google Privacy
        Policy at{' '}
        <PrivacyLink href="https://www.google.com/policies/privacy/">
          https://www.google.com/policies/privacy/
        </PrivacyLink>
        . You can also download the Google Analytics Opt-out Browser Add-on to
        prevent their data from being used by Google Analytics at{' '}
        <PrivacyLink href="https://tools.google.com/dlpage/gaoptout">
          https://tools.google.com/dlpage/gaoptout
        </PrivacyLink>
        .
      </PrivacyText>
      <PrivacyText>
        <strong>Do-Not-Track.</strong> Currently, our systems do not recognize
        browser “do-not-track” requests. You may, however, disable certain
        tracking as discussed in this section (e.g., by disabling cookies).
      </PrivacyText>
      <PrivacyHeader id="H">User-Generated Content</PrivacyHeader>
      <PrivacyText>
        We invite you to post content on our Site and Services, including your
        comments, pictures, and any other information that you would like to be
        available on our Site or Services. If you post content to our Site or
        Services, all of the information that you post will be viewable by your
        manager, HR users, and those users of the Site and Services you have
        tagged. Upful cannot prevent such information from being used in a
        manner that may violate this Policy, the law, or your personal privacy.
      </PrivacyText>
      <PrivacyHeader id="I">Third-Party Links</PrivacyHeader>
      <PrivacyText>
        Our Site and Services may contain links to third-party websites. Any
        access to and use of such linked websites is not governed by this
        Policy, but instead is governed by the privacy policies of those
        third-party websites. We are not responsible for the information
        practices of such third-party websites.
      </PrivacyText>
      <PrivacyHeader id="J">
        Security of Your Personal Information{' '}
      </PrivacyHeader>
      <PrivacyText>
        We have implemented reasonable precautions to protect the information we
        collect from loss, misuse, and unauthorized access, disclosure,
        alteration, and destruction. Please be aware that despite our best
        efforts, no data security measures can guarantee security.
      </PrivacyText>
      <PrivacyText>
        You should take steps to protect against unauthorized access to your
        password, phone, and computer by, among other things, signing off after
        using a shared computer, choosing a robust password that nobody else
        knows or can easily guess, and keeping your log-in and password private.
        We are not responsible for any lost, stolen, or compromised passwords or
        for any activity on your account via unauthorized password activity.
      </PrivacyText>
      <PrivacyHeader id="K">Access to Your Personal Information</PrivacyHeader>
      <PrivacyText>
        You may modify personal information that you have submitted by logging
        into your account and updating your profile information, or by
        contacting us at https://upful.ai/contactUs. Please note that copies of
        information that you have updated, modified or deleted may remain
        viewable in cached and archived pages of the Site for a period of time.
      </PrivacyText>
      <PrivacyHeader id="L">
        What Choices Do You Have Regarding Use of Your Personal Information?
      </PrivacyHeader>
      <PrivacyText>
        We may send periodic promotional emails to you. You may opt out of
        promotional emails by following the opt-out instructions contained in
        the email or by contacting us at https://upful.ai/contactUs. Please note
        that it may take up to 10 business days for us to process opt-out
        requests. If you opt out of receiving promotional emails, we may still
        send you emails about your account or any services you have requested or
        received from us.
      </PrivacyText>
      <PrivacyHeader id="M">Children Under 13</PrivacyHeader>
      <PrivacyText>
        Our Services are not designed for children under 13. If we discover that
        a child under 13 has provided us with personal information, we will
        delete such information from our systems.
      </PrivacyText>
      <PrivacyHeader id="N">Contact Us</PrivacyHeader>
      <PrivacyText>
        If you have questions about the privacy aspects of our Services or would
        like to make a complaint, please contact us at privacy@upful.ai.
      </PrivacyText>
      <PrivacyHeader id="O">Changes to this Policy</PrivacyHeader>
      <PrivacyText>
        This Policy is current as of the Effective Date set forth above. We may
        change this Policy from time to time, so please be sure to check back
        periodically. We will post any changes to this Policy on our Site and
        Application. If we make any changes to this Policy that materially
        affect our practices with regard to the personal information we have
        previously collected from you, we will endeavor to provide you with
        notice in advance of such change by highlighting the change on our Site
        and Application.
      </PrivacyText>
    </PrivacyContainer>
  );
}
