import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import { colors } from '~/styles';

export const SpinnerLoading = styled(CircularProgress).attrs({
  size: 60,
})`
  position: absolute;
  top: 40%;
  left: 50%;

  &.MuiCircularProgress-root {
    color: ${colors.BRAND_COLOR};
  }
`;
