import { combineReducers } from '@reduxjs/toolkit';

import createAccountReducer from '~/modules/Auth/CreateAccount/redux/createAccountSlice';
import createCompanyReducer from '~/modules/Auth/CreateCompany/redux/createCompanySlice';
import createPasswordReducer from '~/modules/Auth/CreatePassword/redux/createPasswordSlice';
import loginReducer from '~/modules/Auth/Login/redux/loginSlice';

export default combineReducers({
  createAccount: createAccountReducer,
  createCompany: createCompanyReducer,
  createPassword: createPasswordReducer,
  login: loginReducer,
});
