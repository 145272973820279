import React from 'react';
import PropTypes from 'prop-types';
import { pageRoutes } from '~/modules/Navigation/constants';
import WorkItem from '~/modules/Dashboard/components/WorkItem';

import {
  WorkItemPanelBlock,
  WorkItemText,
  FeedbackIconStyled,
} from '~/modules/Dashboard/components/WorkItem/WorkItemStyles';

import { WorkItemsListContainer } from '~/modules/Dashboard/components/SharedStyledComponents';

function MyWorkItemsList({ workItems }) {
  return (
    <WorkItemsListContainer>
      {workItems.map(
        ({
          work: { id, title, created_on: createdOn, feedback, is_read: isRead },
        }) => {
          return (
            <WorkItem
              key={`my-work-item-${id}`}
              id={id}
              title={title}
              link={pageRoutes.MY_FEEDBACK}
              date={createdOn}
              isNew={!isRead}
              rightPanel={
                <WorkItemPanelBlock>
                  <FeedbackIconStyled variant="gray" />
                  <WorkItemText>{feedback.length}</WorkItemText>
                </WorkItemPanelBlock>
              }
            />
          );
        },
      )}
    </WorkItemsListContainer>
  );
}

MyWorkItemsList.propTypes = {
  workItems: PropTypes.array.isRequired,
};

export default MyWorkItemsList;
