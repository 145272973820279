/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';

function InviteInput(props) {
  const [initialLeftOffset, changInitialOffset] = useState(null);
  const inputRef = useRef(null);

  const {
    selectProps: { value, changeMenuBias, rigthOffset, menuWidth },
  } = props;

  useEffect(() => {
    const leftOffset = inputRef?.current.getBoundingClientRect().x;

    if (initialLeftOffset === null) {
      changInitialOffset(leftOffset);
    } else if (rigthOffset - leftOffset < menuWidth) {
      const bias = leftOffset - initialLeftOffset - menuWidth / 2;
      changeMenuBias(bias);
    } else {
      const bias = leftOffset - initialLeftOffset;
      changeMenuBias(bias);
    }
  }, [value, initialLeftOffset]);

  return (
    <div ref={inputRef}>
      <components.Input {...props} />
    </div>
  );
}

export default InviteInput;
