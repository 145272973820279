import React from 'react';

import { AuthPageWrapper, PageContainer } from '~/elements';
import { CheckEmailSection } from '~/modules/Auth';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    page: 'CheckEmail',
  },
  dataLayerName: 'PageDataLayer',
};
function CheckEmail() {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <AuthPageWrapper>
      <PageContainer withAdditionalMargin>
        <CheckEmailSection />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default CheckEmail;
