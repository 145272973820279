export const getTeamInitialValues = ({ collaborators, stakeholders }) => {
  // TODO: Rewrite this
  const coll = collaborators.map(
    ({ first_name: fn, last_name: ln, email }) => ({
      label: fn ? `${fn} ${ln}` : email,
      labelDescroption: email,
      value: email,
    }),
  );
  const stkh = stakeholders.map(({ first_name: fn, last_name: ln, email }) => ({
    label: fn ? `${fn} ${ln}` : email,
    labelDescroption: email,
    value: email,
  }));

  return {
    collaborators: coll || [],
    stakeholders: stkh || [],
  };
};
