const breakpoints = {
  XS: '0',
  SM: '600px',
  MD: '800px',
  RG: '1024px',
  LG: '1366px',
  XL: '1440px',
  XXL: '1536px',
  XXXL: '1920px',
};

export default breakpoints;
