import React from 'react';
import PropTypes from 'prop-types';

import {
  TopBarNavigationButtonContainer,
  OneItemText,
  TextContainer,
} from './TopBarNavigatorButtonStyles';

function TopBarNavigatorButton({
  buttonTitle,
  onClickAction,
  isOneItem,
  oneItemText,
}) {
  return (
    <TopBarNavigationButtonContainer>
      {isOneItem && <OneItemText>{oneItemText}</OneItemText>}
      <TextContainer isOneItem={isOneItem} onClick={onClickAction}>
        {buttonTitle}
      </TextContainer>
    </TopBarNavigationButtonContainer>
  );
}

TopBarNavigatorButton.defaultProps = {
  isOneItem: false,
  oneItemText: '',
};

TopBarNavigatorButton.propTypes = {
  buttonTitle: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
  isOneItem: PropTypes.bool,
  oneItemText: PropTypes.string,
};

export default TopBarNavigatorButton;
