import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup); // extend yup
import DateFnsAdapter from '@date-io/date-fns';

import { EMAIL_REGEXP } from './regexp';

const dateFns = new DateFnsAdapter();

// Common fields
export const email = Yup.string()
  .required('Email should not be empty')
  .matches(EMAIL_REGEXP, `Incorrect email format`);

export const password = Yup.string()
  .minUppercase(1, 'Password should contain 1 uppercase letter')
  .minLowercase(1, 'Password should contain 1 lowercase letter')
  .minNumbers(1, 'Password should contain 1 number')
  .minSymbols(1, 'Password should contain 1 symbol')
  .min(12, 'Password should be at least 12 characters long')
  .required('Password should not be empty');

export const rpassword = Yup.string()
  .minUppercase(1, 'Password should contain 1 uppercase letter')
  .minLowercase(1, 'Password should contain 1 lowercase letter')
  .minNumbers(1, 'Password should contain 1 number')
  .minSymbols(1, 'Password should contain 1 symbol')
  .required('Password should not be empty')
  .min(12, 'Password should be at least 12 characters.')
  .oneOf([Yup.ref('password')], 'Passwords do not match.');

export const date = (minDate, minYear) =>
  Yup.date()
    .nullable()
    .min(minDate, `Please select a year between ${minYear} and now.`)
    .max(
      dateFns.endOfDay(dateFns.date()),
      `Please select a year between ${minYear} and now.`,
    )
    .typeError('Invalid Date Format');

export const maxDateOfBirth = new Date(
  `${dateFns.date().getFullYear() - 18}-12-31T23:59:59`,
);

export const dateOfBirth = Yup.date()
  .nullable()
  .min(
    new Date('1920-01-01T00:00:00'),
    'Please select an option from the drop-down.',
  )
  .max(maxDateOfBirth, 'Please select an option from the drop-down.')
  .typeError('Invalid Date Format');
