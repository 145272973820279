import styled from 'styled-components';

import { colors, space, breakpoints, typography } from '~/styles';

export const Form = styled.form``;

export const FieldControl = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${breakpoints.RG}) {
    flex-direction: row;
  }
`;

export const DisabilityCheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${space.XS};
  max-width: 100%;
  flex: 0 1 calc(50% - 10px);

  .MuiFormControlLabel-root:first-child {
    margin-left: -8px;
  }

  .MuiFormControlLabel-root:last-child {
    margin-left: 15px;
  }

  @media (min-width: ${breakpoints.RG}) {
    max-width: 390px;
  }

  @media (min-width: ${breakpoints.XL}) {
    box-sizing: content-box;
  }
`;

export const DisabilityCheckboxesContainer = styled.div`
  display: flex;
  margin-top: ${space.XXS};
`;

export const DisabilityLabel = styled.label`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.SM};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.PRIMARY};
`;

export const BirthWrapepr = styled.div`
  display: flex;
  flex: 1;
  margin-top: ${space.LG};
  max-width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    margin-top: 0;
    max-width: 390px;
  }
`;
