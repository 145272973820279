import React, { memo } from 'react';
import PropTypes from 'prop-types';
import TooltipStyled from './TooltipStyles';

function MyCustomTooltip({ children, title }) {
  return (
    <TooltipStyled title={title} arrow>
      <div>{children}</div>
    </TooltipStyled>
  );
}

MyCustomTooltip.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  children: PropTypes.node.isRequired,
};

export default memo(MyCustomTooltip);
