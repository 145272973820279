import styled from 'styled-components';

import { colors, space, typography } from '~/styles';

export const WorkingOnWrapper = styled.div`
  margin-top: ${space.SM};
  padding: ${space.XS};
  max-width: 100%;
  border: solid 2px ${colors.OUTLINES};
  border-radius: 4px;
`;

export const Title = styled.h3`
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.MD};
  color: ${colors.PRIMARY};
`;

export const WorkingOnButton = styled.button`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${space.XXS};
  padding: 15px ${space.XS};
  border: solid 1px ${colors.OUTLINES};
  border-radius: 4px;
  box-shadow: 0 2px 24px 0 ${colors.SHADOWS};
  background-color: ${colors.WHITE};
  outline: none;
  cursor: pointer;
`;

export const ButtonText = styled.span`
  margin-left: ${space.XS};
  text-align: start;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.SM};
  color: ${({ isEditing }) =>
    isEditing ? colors.ERROR : colors.SECONDARY_TEXT};
`;
