function getPassedSteps(profileData, stepsFields) {
  return stepsFields.map((stepFields) => {
    return stepFields.every(
      (stepField) =>
        profileData[stepField] !== null &&
        profileData[stepField] !== undefined &&
        profileData[stepField].length !== 0,
    );
  });
}

function setPassedSteps(profileData, stepsFields, profileSteps) {
  const data = {
    ...profileData,
    ...profileData.demographics,
    ...profileData.positionInfo,
  };
  data.yearOfBirth = data.yearOfBirth?.getFullYear();
  const passedSteps = getPassedSteps(data, stepsFields);

  const updatedSteps = profileSteps.map((step, index) => ({
    ...step,
    isPassed: passedSteps[index],
  }));

  const disabilityStep = updatedSteps.find(
    (stepData) => stepData.name === 'Disability',
  );

  const { hasDisability } = profileData.demographics;

  if (hasDisability) {
    disabilityStep.isDisabled = false;
  } else if (hasDisability === false || hasDisability === null) {
    disabilityStep.isPassed = true;
  }

  return updatedSteps;
}

export default setPassedSteps;
