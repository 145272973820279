import styled from 'styled-components';

import { ArrowIcon } from '~/assets';
import { colors } from '~/styles';

export const ArrowIconStyled = styled(ArrowIcon)`
  height: 15px;
  width: 15px;
  transform: ${({ collapsed }) =>
    collapsed === 'true' ? 'rotate(90deg)' : 'rotate(270deg)'};

  path {
    stroke: ${colors.WHITE};
  }
`;

export const ArrowButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background-color: ${colors.DELIVARABLE_TAG};
  cursor: pointer;

  &:hover {
    background-color: ${colors.WHITE};

    svg {
      path {
        stroke: ${colors.DELIVARABLE_TAG};
      }
    }
  }
`;
