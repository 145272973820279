import { all, put, takeLatest, select, delay, call } from 'redux-saga/effects';

import { actionTypes as toastActionTypes } from '~/modules/Toast/redux/toastSlice';
import { actionTypes as feedbackActionTypes } from '~/modules/Feedback/MyFeedback/redux/myFeedbackSlice';
import { getAllWork } from '~/modules/Dashboard/ColleaguesWork/redux/saga';
import { openModal, closeModal } from '~/modules/Modal/redux/modalSlice';
import { MODAL_TYPES } from '~/modules/Modal/constants';

import { requestWrapper as request } from '~/utils';
import api from '~/services';
import { getAuthenticatedToken } from '~/modules/Auth/AuthProvider/redux/selectors';
import {
  normalizeCollaboratorsResponse,
  normalizePostWorkItemRequest,
  normilizeWorkItemError,
} from './normalizer';

import { actionTypes } from './myWorkSlice';
import { getEditingMode } from './selectors';

function* getMyWork() {
  const { response } = yield request(api.getMyWork);

  if (response) {
    yield put(actionTypes.getMyWorkSuccess(response));
  } else {
    yield put(actionTypes.getMyWorkError());
  }
}

function* openAddWorkItemModal() {
  yield put(openModal({ modalType: MODAL_TYPES.ADD_WORK_ITEM_MODAL }));
  yield put(actionTypes.setEditingMode(true));

  const { response } = yield request(api.getCompanyInfo);

  if (response) {
    const collaboratorsOptions = normalizeCollaboratorsResponse(
      response.peopleInfo,
    );

    yield put(actionTypes.setCollaborators(collaboratorsOptions));
  }
}

function* autoSaveEditingWorkItem({ payload }) {
  const isEditingMode = yield select(getEditingMode);

  if (isEditingMode) {
    yield put(actionTypes.setEditingWorkItem(payload));
  } else {
    yield put(actionTypes.setEditingWorkItem({}));
  }
}

function* postWorkItem({
  payload: { data, errorDictionary, setSubmitting, setStatus },
}) {
  const { response: putResponse } = yield request(api.putWork, {
    work_type: 0,
  });

  if (putResponse) {
    const { id } = putResponse;
    const requestData = normalizePostWorkItemRequest(data);

    const { response, error } = yield request(
      api.postWorkItem,
      {
        data: requestData,
        id,
      },
      errorDictionary,
    );

    if (response) {
      setSubmitting(false);

      yield put(toastActionTypes.showSuccessToast('Work Item has been saved.'));
      yield put(closeModal());
      yield put(actionTypes.autoSaveEditingWorkItem({}));
      yield put(actionTypes.addWorkItemSuccess());
    } else {
      const invalidEmails = normilizeWorkItemError(error);
      setStatus(invalidEmails);

      setSubmitting(false);
    }
  }
}

function* initNotifications() {
  const token = yield select(getAuthenticatedToken);

  yield call(getAllWork);
  yield call(getMyWork);
  // while (token) {
  //yield delay(10000);
  // }
}

export default function* myWorkSaga() {
  yield all([
    takeLatest(
      [
        actionTypes.getMyWorkRequest,
        actionTypes.addWorkItemSuccess,
        feedbackActionTypes.updateWorkItemSuccess,
      ],
      getMyWork,
    ),
    takeLatest(actionTypes.initRequest, initNotifications),
    takeLatest(actionTypes.openWorkItemModal, openAddWorkItemModal),
    takeLatest(actionTypes.addWorkItemRequest, postWorkItem),
    takeLatest(actionTypes.autoSaveEditingWorkItem, autoSaveEditingWorkItem),
  ]);
}
