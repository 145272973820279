import { createSlice } from '@reduxjs/toolkit';

const toastInitialState = {
  type: '',
  message: '',
  id: 0,
};

const toastSlice = createSlice({
  name: 'TOAST',
  initialState: toastInitialState,
  reducers: {
    addToast: (state, action) => {
      const { type, message } = action.payload;

      state.type = type;
      state.message = message;
      state.id = Math.random();
    },
    showSuccessToast: () => {},
    showErrorToast: () => {},
    showErrorToastArray: () => {},
  },
});

export const actionTypes = toastSlice.actions;

export default toastSlice.reducer;
