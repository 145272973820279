import styled from 'styled-components';

import { colors, space, breakpoints } from '~/styles';

export const AuthPageWrapper = styled.section`
  flex: 1;
  height: auto;
  padding: ${space.MD} 16px 0;
  background-color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.SM}) {
    padding: ${space.MD} ${space.XL} ${space.XXXL};
  }

  @media (min-width: ${breakpoints.MD}) {
    padding: ${space.MD} ${space.XXXL} ${space.XXXL};
  }

  @media (min-width: ${breakpoints.RG}) {
    padding: ${space.MD} ${space.XXXL};
  }

  @media (min-width: ${breakpoints.LG}) {
    padding: ${space.MD} ${space.LG} ${space.MD} ${space.XXXL};
  }

  @media (min-width: ${breakpoints.XL}) {
    padding: ${space.MD} ${space.XXXXL};
  }

  @media (min-width: ${breakpoints.XXXXL}) {
    padding: ${space.MD} 250px;
  }
`;

export const NavigationBarContainer = styled.div`
  margin-bottom: ${space.XS};

  @media (min-width: ${breakpoints.RG}) {
    margin-bottom: ${space.MD};
  }
`;

export const PageContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  margin-top: 0;
  background-color: ${colors.PRIMARY};

  @media (min-width: ${breakpoints.RG}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: initial;
    margin-top: ${(props) => (props.withAdditionalMargin ? '56px' : 0)};
  }
`;

export const PageContainerWaitlist = styled(PageContainer)`
  @media (min-width: ${breakpoints.RG}) {
    flex-flow: wrap;
  }
`;

export const DashboardWrapper = styled.section`
  display: flex;
  flex: 1;
  background-color: ${colors.PRIMARY_BG};
`;
