import styled from 'styled-components';

import { colors, typography, space } from '~/styles';

export const ProgressBarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
`;

export const ProgressBarStatus = styled.div`
  min-width: max-content;
  margin-bottom: ${space.XXS};
  font-size: ${typography.size.SM};
  font-family: ${typography.font.TEXT};
  color: ${colors.WHITE};
`;

export const BoldStatus = styled.span`
  font-family: ${typography.font.TITLE};
`;

export const ProgressContainer = styled.div`
  width: 155px;
  height: 8px;
  margin-bottom: 15px;
  border-radius: 4px;
  background-color: ${colors.PROGRESS_BAR};
`;

export const Progress = styled.div`
  width: ${(props) => props.percentage}%;
  height: 100%;
  border-radius: 4px;
  background-color: ${colors.BRAND_COLOR};
`;

export const ProgressLink = styled.a`
  margin-bottom: ${space.SM};
  min-width: 220px;
  font-size: ${typography.size.XS};
  font-family: ${typography.font.TEXT};
  color: ${colors.WHITE};
`;
