import React from 'react';
import PropTypes from 'prop-types';

import {
  ProgressBarContainer,
  ProgressBarStatus,
  BoldStatus,
  ProgressContainer,
  Progress,
  ProgressLink,
} from './ProgressBarStyles';

function ProgressBar({ percentage, link }) {
  return (
    <ProgressBarContainer>
      <ProgressBarStatus>
        Profile is <BoldStatus>{percentage}% Complete</BoldStatus>
      </ProgressBarStatus>
      <ProgressContainer>
        <Progress percentage={percentage} />
      </ProgressContainer>
      <ProgressLink href={link}>
        This helps us make the product better for you!
      </ProgressLink>
    </ProgressBarContainer>
  );
}

ProgressBar.propTypes = {
  percentage: PropTypes.number.isRequired,
  link: PropTypes.string.isRequired,
};

export default ProgressBar;
