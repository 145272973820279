import React, { useEffect, useCallback } from 'react';
import { push } from 'connected-react-router';
import { useSelector, useDispatch } from 'react-redux';
import {
  getPersonInitials,
  getPersonFullName,
  getPersonEmail,
} from '~/modules/Profile/redux/selectors';
import { SideBar } from '~/modules/Navigation';
import UpfulEditor from '@upfulai/upful-editor-react-lib';

import {
  getAuthenticatedToken,
  getExpiresAt,
  getRefreshToken,
  getRefreshExpiresAt,
  getPersonId,
  getCompanyId,
} from '~/modules/Auth/AuthProvider/redux/selectors';
import Header from '../../Feedback/components/Header';
import {
  FeedbackPageWrapper,
  FeedbackContentWrapper,
  FeedbackContent,
} from '../../Feedback/components/SharedStyledComponents';
import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    page: 'CoachingExplorer',
  },
  dataLayerName: 'PageDataLayer',
};

function CoachingExplorerFeedback() {
  TagManager.dataLayer(tagManagerArgs);
  const dispatch = useDispatch();
  const initials = useSelector(getPersonInitials);
  const { firstName, lastName } = useSelector(getPersonFullName);
  const email = useSelector(getPersonEmail);
  // we do this so the navigateToPage doesn't get recreated every iteration
  // it only changes every time dispatch is updated (which should only be once)
  const navigateToPage = useCallback(() => dispatch(push('/home')), [dispatch]);

  const token = useSelector(getAuthenticatedToken);
  const refresh = useSelector(getRefreshToken);
  const expiresAt = useSelector(getExpiresAt);
  const refreshExpiresAt = useSelector(getRefreshExpiresAt);
  const personId = useSelector(getPersonId);
  const companyId = useSelector(getCompanyId);

  return (
    <FeedbackPageWrapper>
      <SideBar isHidden />
      <>
        <Header
          title="Sample Performance Review"
          ownerInitials={initials}
          firstName=""
          lastName=""
          creatorUserName=""
          handleBackClick={navigateToPage}
        />
        <FeedbackContentWrapper>
          <FeedbackContent>
            <UpfulEditor
              reviewer_id={personId}
              company_id={companyId}
              reviewee_email={''}
              work_item_id=""
              jwt={token}
              refresh={refresh}
              expiresAt={expiresAt}
              refreshExpiresAt={refreshExpiresAt}
              fullHeight={true}
            />
          </FeedbackContent>
        </FeedbackContentWrapper>
      </>
    </FeedbackPageWrapper>
  );
}

export default CoachingExplorerFeedback;
