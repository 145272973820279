/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';

import {
  FormErrorText,
  TextField,
  FormTextFieldErrorContainer,
  FormFieldWrapper,
  SuccessIcon,
} from '~/elements';

function FormTextField({
  field,
  form: { errors, touched, status },
  center,
  ...props
}) {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const fieldStatus = getIn(status, field.name);

  const isError = !!error && !!touch;

  return (
    <>
      <FormFieldWrapper>
        <TextField
          success={fieldStatus}
          error={isError}
          center={`${center}`}
          {...field}
          {...props}
        />
        {fieldStatus === 'success' && !error && <SuccessIcon />}
      </FormFieldWrapper>
      <FormTextFieldErrorContainer>
        {error && touch && (
          <FormErrorText dangerouslySetInnerHTML={{ __html: error }} />
        )}
      </FormTextFieldErrorContainer>
    </>
  );
}

FormTextField.defaultProps = {
  center: false,
};

FormTextField.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  center: PropTypes.bool,
};

export default FormTextField;
