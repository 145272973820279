import styled from 'styled-components';
import { createMuiTheme } from '@material-ui/core';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import { ArrowIcon } from '~/assets';
import { typography, colors, breakpoints, space } from '~/styles';

export const keyboardDatePickerTheme = createMuiTheme({
  overrides: {
    MuiPickersYear: {
      root: {
        fontFamily: typography.font.TEXT,

        '&:focus, &:active': {
          color: colors.PRIMARY,
          backgroundColor: colors.OUTLINES,
        },
      },
      yearSelected: {
        color: colors.PRIMARY,
        backgroundColor: colors.OUTLINES,
      },
    },
    MuiFormHelperText: {
      root: {
        '&.Mui-error': {
          fontFamily: typography.font.TEXT,
          fontSize: typography.size.XS,
          color: colors.ERROR,
          height: '40px',
        },
      },
    },
    MuiButton: {
      textPrimary: {
        fontFamily: typography.font.TEXT,
        fontSize: typography.size.XS,
        color: colors.BRAND_COLOR,
      },
    },
    MuiPickersDay: {
      daySelected: {
        backgroundColor: colors.PRIMARY,
        color: colors.WHITE,
        '&:hover, &:focus, &:checked, &:enabled, &:active': {
          backgroundColor: colors.PRIMARY,
          color: colors.WHITE,
        },
      },
    },
  },
});

export const DatePickerProvider = styled(MuiPickersUtilsProvider)``;

export const DatePickerWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;

export const DatePicker = styled(KeyboardDatePicker).attrs({
  disableToolbar: true,
  variant: 'inline',
})`
  .MuiFormLabel-root {
    &.Mui-error,
    &.Mui-focused {
      color: ${colors.PRIMARY};
    }
  }

  &.MuiFormControl-root {
    width: 100%;
  }

  .MuiInputBase-root {
    margin-bottom: ${space.LG};
    font-family: ${typography.font.TEXT};
    font-size: ${typography.size.XS};
    color: ${colors.PRIMARY};

    &.Mui-error {
      margin-bottom: 0;
    }
  }

  .MuiInput-underline,
  .MuiInput-underline:hover:not(.Mui-disabled) {
    &:before,
    &:after {
      border-bottom: ${(props) =>
        props.success === 'success'
          ? `2px solid ${colors.BRAND_COLOR}`
          : `2px solid ${colors.OUTLINES}`};
      transition: 0.1s ease-out;
    }
  }

  .MuiInput-underline.Mui-disabled:before {
    border-bottom: 1px solid ${colors.OUTLINES};
  }

  .MuiInput-underline.Mui-error,
  .MuiInput-underline.Mui-error:hover {
    &:before,
    &:after {
      border-bottom-color: ${colors.ERROR};
    }
  }

  input {
    height: 36px;
    padding: 0;

    &::placeholder {
      position: fixed;
      color: ${colors.SECONDARY_TEXT};
    }
  }

  label + .MuiInput-formControl {
    margin: 0;
  }

  .MuiIconButton-root {
    margin-top: 12px;
    padding: 8px;
    border: none;
    background-color: transparent;
    &:hover {
      border: none;
      background-color: transparent;
      transform: none;
    }
  }

  @media (min-width: ${breakpoints.SM}) {
    .MuiInputBase-root {
      font-size: ${typography.size.SM};
    }

    label {
      font-size: ${typography.size.SM};
    }

    input {
      height: 40px;
      padding: ${space.XXS} 0 0 0;
    }
  }
`;

export const DatePickerControl = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  margin-bottom: 5px;

  :last-child {
    margin-right: 0;
  }
`;

export const DatePickerLabel = styled.label`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.SM};
  color: ${colors.PRIMARY};
  letter-spacing: normal;
  line-height: normal;
`;

export const Arrow = styled(ArrowIcon)`
  width: 20px;
  height: 20px;

  path {
    stroke: ${(props) => props.success === 'success' && colors.BRAND_COLOR};
  }
`;
