import styled, { css } from 'styled-components';

import { colors, typography, breakpoints } from '~/styles';

import { FeedbackIcon, WatchIcon, ArrowRightLargeIcon, AddIcon } from '~/assets';

const specSize = '1070px';

export const WorkItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  margin-bottom: 10px;
  padding: 24px 10px;
  background-color: ${colors.WHITE};
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  box-shadow: 0 0 15px 0 ${colors.SHADOWS};
  cursor: pointer;
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    height: 70px;
    padding: 24px 20px;
  }
`;

export const WorkItemTitle = styled.div`
  font-size: ${typography.size.MD};
  font-family: ${typography.font.TITLE};
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const textCSS = css`
  font-size: ${typography.size.XXS};
  font-family: ${typography.font.TEXT};
  margin-left: 10px;

  @media (min-width: 1200px) {
    font-size: ${typography.size.XS};
  }
`;

export const WorkItemStatusText = styled.div`
  ${textCSS}
  color: ${({ color }) => color || colors.SECONDARY_TEXT};
  min-width: 125px;

  @media (min-width: ${breakpoints.LG}) {
    min-width: 140px;
  }
`;

export const WorkItemText = styled.div`
  ${textCSS}
  color: ${({ color }) => color || colors.SECONDARY_TEXT};
`;

export const WorkItemDateText = styled.div`
  ${textCSS}
  color: ${(props) =>
    props.new === 'true' ? colors.PRIMARY : colors.SECONDARY_TEXT};
`;

export const WorkItemPanelBlock = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.SECONDARY_TEXT};
  margin: 0px 5px;

  @media (min-width: ${specSize}) {
    margin: 0px 10px;
  }

  @media (min-width: ${breakpoints.LG}) {
    margin: 0px 15px;
  }
`;

export const WorkItemLeftPanel = styled.div`
  max-width: 55%;
  min-width: 20%;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.RG}) {
    max-width: 50%;
  }

  @media (min-width: ${breakpoints.LG}) {
    max-width: 65%;
  }
`;

export const WorkItemRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: fit-content;
`;

export const WatchIconStyled = styled(WatchIcon)`
  width: 20px;
  path {
    stroke: ${(props) =>
      props.new === 'true' ? colors.PRIMARY : colors.SECONDARY_TEXT};
  }

  @media (min-width: ${breakpoints.LG}) {
    width: 25px;
  }
`;
export const FeedbackIconStyled = styled(FeedbackIcon)`
  width: 20px;
  path {
    stroke: ${({ color }) => color || colors.SECONDARY_TEXT};
  }

  @media (min-width: ${breakpoints.LG}) {
    width: 25px;
  }
`;

export const AddIconStyled = styled(AddIcon)`
  width: 20px;
  path {
    stroke: ${({ color }) => color || colors.SECONDARY_TEXT};
  }

  rect {
    stroke: ${({ color }) => color || colors.SECONDARY_TEXT};
  }

  @media (min-width: ${breakpoints.LG}) {
    width: 25px;
  }
`;

export const ArrowIconStyled = styled(ArrowRightLargeIcon)`
  height: 25px;
  width: 25px;

  @media (min-width: ${breakpoints.LG}) {
    width: 20px;
  }
`;

export const WorkItemUserName = styled.div`
  font-size: 16px;
  font-family: ${typography.font.TEXT};
  white-space: nowrap;
  padding: 5px;
  color: white;
  border-radius: 5px;
  background-color: ${colors.DELIVARABLE_TAG};
  max-width: 180px;

  @media (min-width: ${breakpoints.RG}) {
    max-width: none;
  }
`;
