import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NotificationDot } from '~/elements';
import { getCurrentPathname } from '~/modules/Navigation/redux/selectors';
import { sideBarLinksData } from '~/modules/Navigation/constants';

import { getUnreadItemsNotification } from '~/modules/Dashboard/ColleaguesWork/redux/selectors';
import { getUnreadNotification } from '~/modules/Dashboard/MyWork/redux/selectors';
import { WorkItemsContainer, NavLink, NavTitle, Item } from './SideBarStyles';

function SideBarNavigation({ opened }) {
  const pathname = useSelector(getCurrentPathname);
  const colleaguesWorkNotification = useSelector(getUnreadItemsNotification);
  const myWorkNotifications = useSelector(getUnreadNotification);

  return (
    <WorkItemsContainer isOpened={opened}>
      {sideBarLinksData.map(
        ({ title, link, icon: Icon, label, openAsNewTab }) => (
          <Item isActive={pathname === link} key={`side-bar-item-${title}`}>
            {openAsNewTab ? (
              <NavLink
                to={link}
                exact
                target={'_blank'}
                rel="noreferrer noopener"
                opened={`${opened}`}
              >
                <Icon className="w-8 h-8 text-white" />
                {opened ? <NavTitle>{title}</NavTitle> : null}
              </NavLink>
            ) : (
              <NavLink to={link} exact opened={`${opened}`}>
                <Icon className="w-8 h-8 text-white" />
                {opened ? <NavTitle>{title}</NavTitle> : null}
                {label === 'CW'
                  ? colleaguesWorkNotification && <NotificationDot />
                  : label === 'MW' &&
                    myWorkNotifications && <NotificationDot />}{' '}
                {/* //TODO: Rewrite */}
              </NavLink>
            )}
          </Item>
        ),
      )}
    </WorkItemsContainer>
  );
}

SideBarNavigation.propTypes = {
  opened: PropTypes.bool.isRequired,
};

export default memo(SideBarNavigation);
