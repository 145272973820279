import { all, fork } from 'redux-saga/effects';

import joinWaitlListSaga from '~/modules/Waitlist/redux/saga';
import authSaga from '~/modules/Auth/redux/saga';
import toastSaga from '~/modules/Toast/redux/saga';
import dashboardSaga from '~/modules/Dashboard/redux/saga';
import profileSaga from '~/modules/Profile/redux/saga';
import contactUsSaga from '~/modules/ContactUs/redux/saga';
import feedbackSaga from '~/modules/Feedback/redux/saga';

export default function* rootSaga() {
  yield all([
    fork(joinWaitlListSaga),
    fork(authSaga),
    fork(toastSaga),
    fork(dashboardSaga),
    fork(profileSaga),
    fork(contactUsSaga),
    fork(feedbackSaga),
  ]);
}
