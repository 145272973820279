import styled from 'styled-components';

import { space, typography, colors, breakpoints } from '~/styles';

export const BasisProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: ${space.XL};
`;

export const ProfileHeader = styled.h3`
  height: 36px;
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.LG};
  font-weight: normal;
  color: ${colors.PRIMARY};
`;

export const ProfileExplanationText = styled.div`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  padding-top: 0px;
  margin-top: -20px;
  margin-bottom: 20px;
  color: ${colors.SECONDARY_TEXT};
  max-width: 882px;
  width: 100%;

  @media (min-width: ${breakpoints.LG}) {
    padding-top: ${space.XS};
    margin-top: 0px;
  }
`;

export const ProfileExplanationBlock = styled.div`
  font-family: ${typography.font.TEXT};
  font-size: ${typography.size.XS};
  padding: 15px;
  margin-top: 20px;
  color: ${colors.SECONDARY_TEXT};
  max-width: 882px;
  width: 100%;
  border: solid 1px ${colors.BRAND_COLOR};
  border-radius: 4px;
  background-color: white;
`;

export const StepWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${space.XXS};
  padding-bottom: 60px;
  width: 100%;

  @media (min-width: ${breakpoints.LG}) {
    padding-bottom: 0;
  }
`;
