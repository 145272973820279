import styled from 'styled-components';

import { space, colors, breakpoints } from '~/styles';

import { FormTitleText } from '~/elements';

export const FormContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  flex-direction: row-reverse;
  margin: 16px;
  height: 100%;
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    flex-direction: row-reverse;
    justify-content: end;
    margin: 0;
  }
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  background-color: ${colors.WHITE};

  @media (min-width: ${breakpoints.MD}) {
    padding: ${space.LG};
  }

  @media (min-width: ${breakpoints.RG}) {
    width: 622px;
  }

  @media (min-width: ${breakpoints.LG}) {
    width: 882px;
  }
`;

export const FormTitle = styled(FormTitleText)`
  align-self: center;
  width: 100%;
  margin-bottom: ${space.LG};
  color: ${colors.MILESTONE_TAG};
`;

export const FormFieldWrapper = styled.div`
  width: 100%;

  > div > div:last-child {
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.SM}) {
    > div > div {
      margin-bottom: ${space.XS};
    }
  }
`;

export const SelectContainer = styled.div`
  display: flex;
  width: 50%;
`;

export const ButtonControl = styled.div`
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  width: 70%;
  height: 50px;
  margin-top: ${space.XS};

  @media (min-width: ${breakpoints.RG}) {
    align-self: flex-end;
    width: 50%;
  }
`;
