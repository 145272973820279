import styled from 'styled-components';
import { colors, typography, breakpoints } from '~/styles';
import { EyeballIcon, ArrowIcon, WatchIcon } from '~/assets';

export const MyFeedbackItemItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const WorkItemContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  align-items: center;
  padding: 24px 10px;
  background-color: ${colors.WHITE};
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  box-shadow: 0 0 15px 0 ${colors.SHADOWS};
  cursor: pointer;
  width: 100%;
  z-index: 2;

  @media (min-width: ${breakpoints.RG}) {
    height: 70px;
    padding: 24px 20px;
  }
`;

export const WorkItemTitle = styled.div`
  font-size: ${typography.size.MD};
  font-family: ${typography.font.TITLE};
  margin-left: 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 10px;
`;

export const WorkItemText = styled.div`
  font-size: ${typography.size.XS};
  font-family: ${typography.font.TEXT};
  color: ${(props) => props.variant === 'green' && colors.BRAND_COLOR};
  margin-left: 10px;
`;

export const WorkItemPanelBlock = styled.div`
  display: flex;
  align-items: center;
  color: ${colors.SECONDARY_TEXT};
  margin: 0px 20px;
`;

export const WorkItemLeftPanel = styled.div`
  max-width: 55%;
  min-width: 20%;
  display: flex;
  align-items: center;

  @media (min-width: ${breakpoints.RG}) {
    max-width: 50%;
  }

  @media (min-width: ${breakpoints.LG}) {
    max-width: 65%;
  }
`;

export const WorkItemRightPanel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: fit-content;
`;

export const EyebollIconStyled = styled(EyeballIcon)`
  height: 25px;
  path {
    stroke: ${(props) => props.variant === 'gray' && colors.SECONDARY_TEXT};
  }
`;

export const ArrowIconStyled = styled(ArrowIcon)`
  height: 20px;
  width: 20px;
`;

export const WatchIconStyled = styled(WatchIcon)`
  margin-left: 10px;
  path {
    stroke: ${(props) => props.variant === 'gray' && colors.SECONDARY_TEXT};
  }
`;

export const MyFeedbackItemExpandWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -10px;
  padding: 40px 30px 0px 30px;

  background-color: ${colors.WHITE};
  border-radius: 4px;
  border: solid 1px ${colors.OUTLINES};
  box-shadow: 0 0 15px 0 ${colors.SHADOWS};
`;

export const MyFeedbackItemPrompt = styled.div`
  font-size: ${typography.size.SM};
  font-family: ${typography.font.TEXT};
  color: ${colors.SECONDARY_TEXT};
  margin-bottom: 10px;
`;

export const MyFeedbackItemReplay = styled.div`
  font-size: ${typography.size.SM};
  font-family: ${typography.font.TEXT};
  color: ${(props) => (props.isEmpty ? colors.SECONDARY_TEXT : colors.PRIMARY)};
  margin-bottom: 30px;
  word-break: break-word;
`;
