import { all, takeLatest, put } from 'redux-saga/effects';

import { actionTypes } from './toastSlice';

function* showSuccessToast({ payload: message }) {
  yield put(
    actionTypes.addToast({
      type: 'success',
      message,
    }),
  );
}

function* showErrorToast({ payload: message }) {
  yield put(
    actionTypes.addToast({
      type: 'error',
      message,
    }),
  );
}

function* showErrorToastArray({ payload: toastErrorArray }) {
  yield all(
    toastErrorArray.map((message) =>
      put(
        actionTypes.addToast({
          type: 'error',
          message,
        }),
      ),
    ),
  );
}

function* toastSaga() {
  yield all([
    takeLatest(actionTypes.showSuccessToast, showSuccessToast),
    takeLatest(actionTypes.showErrorToast, showErrorToast),
    takeLatest(actionTypes.showErrorToastArray, showErrorToastArray),
  ]);
}

export default toastSaga;
