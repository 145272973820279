import { upfulNetworkService } from '../networkService';

export const createAccount = (data) =>
  upfulNetworkService.post('person/create', data);

export const submitPhoneNumber = (data) =>
  upfulNetworkService.post('company/create', data);

export const resendCode = (data) =>
  upfulNetworkService.post('company/resend', data);

export const verifyPhoneNumber = (data) =>
  upfulNetworkService.post('company/verify', data);

export const createPassword = (data) =>
  upfulNetworkService.post('auth/reset', data);

export const createLogin = (data) =>
  upfulNetworkService.post('auth/login', data);

export const refreshToken = () => upfulNetworkService.get('auth/renewToken');

export const contactUs = (data) => upfulNetworkService.post('/message', data);
