import { createSlice } from '@reduxjs/toolkit';

const joinWaitListState = {
  isFetching: false,
};

const joinWaitListSlice = createSlice({
  name: 'JOIN_WAIT_LIST',
  initialState: joinWaitListState,
  reducers: {
    joinWaitListRequest: (state) => {
      state.isFetching = true;
    },
    joinWaitListSuccess: (state, action) => {
      const { id } = action.payload;

      state.isFetching = false;
      state.id = id;
    },
    joinWaitListError: (state) => {
      state.isFetching = false;
    },
  },
});

export const actionTypes = joinWaitListSlice.actions;

const joinWaitListReducer = joinWaitListSlice.reducer;

export default joinWaitListReducer;
