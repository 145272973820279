import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik } from 'formik';
import qs from 'qs';
import { actionTypes } from '~/modules/Auth/CreateAccount/redux/createAccountSlice';
import { createAccounFetchingSelector } from '~/modules/Auth/CreateAccount/redux/selectors';

import {
  FormTextField,
  FormSubmitButton,
  FormFieldMap,
  FormCheckbox,
} from '~/components';

import {
  textFieldsData,
  createAccountFormSchema,
  createAccountFormInitialValues,
  errorDictionary,
} from './formsData';

import TermsAndConditions from './TermsAndConditions';

import {
  FormWrapper,
  Form,
  FormTitle,
  FormFieldWrapper,
  ButtonControl,
  ButtonContainer,
} from './CreateAccountFormStyles';
import { useLocation } from 'react-router-dom';

function CreateAccountForm() {
  const dispatch = useDispatch();

  const isFetching = useSelector(createAccounFetchingSelector);

  const handleSubmitButton = (data, { setErrors }) => {
    dispatch(
      actionTypes.createAccountRequest({
        data,
        errorDictionary,
        setErrors,
      }),
    );
  };
  const { search } = useLocation();
  const sParams = qs.parse(search.split('?')[1]);
  const [inviteCode, setInviteCode] = useState(sParams['invite'], [sParams]);
  return (
    <FormWrapper>
      <Formik
        validateOnMount
        enableReinitialize
        initialValues={{
          ...createAccountFormInitialValues,
          inviteCode,
        }}
        validationSchema={createAccountFormSchema}
        onSubmit={handleSubmitButton}
      >
        {({ handleSubmit, isValid, values }) => (
          <Form onSubmit={handleSubmit}>
            <FormTitle>Create Account</FormTitle>
            <FormFieldWrapper>
              <FormFieldMap
                horizontal
                textFieldsData={textFieldsData}
                textField={FormTextField}
              />
            </FormFieldWrapper>
            <ButtonControl>
              <FormCheckbox
                name="termsAndConditions"
                checked={values.termsAndConditions}
                label={<TermsAndConditions />}
              />
              <ButtonContainer>
                <FormSubmitButton
                  text="Let's Get Started!"
                  isFetching={isFetching}
                  disabled={isFetching || !isValid}
                />
              </ButtonContainer>
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
}

export default CreateAccountForm;
