import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form } from 'formik';

import { FormTextField, FormSubmitButton, FormFieldMap } from '~/components';

import { getBasicProfileInfo } from '~/modules/Profile/redux/selectors';
import { actionTypes } from '~/modules/Profile/redux/profileSlice';

import {
  textFieldsData,
  basicInfoFormSchema,
  errorDictionary,
} from './formsData';

import {
  ProfileFormWrapper,
  SubmitButtonControl,
  SubmitButtonWrapper,
} from '../commonStyles';
import { getFreeEmailStatus } from '~/modules/Auth/AuthProvider/redux/selectors';
function BasicInfoForm() {
  const dispatch = useDispatch();
  const freeEmailStatus = useSelector(getFreeEmailStatus);
  const profileBasicInfoValues = useSelector(getBasicProfileInfo);

  const saveUpdates = (data, { setErrors, setSubmitting, setStatus }) => {
    dispatch(
      actionTypes.updateBasicInfoRequest({
        data,
        errorDictionary,
        setErrors,
        setSubmitting,
        setStatus,
      }),
    );
  };

  return (
    <ProfileFormWrapper>
      <Formik
        enableReinitialize
        initialValues={profileBasicInfoValues}
        validationSchema={basicInfoFormSchema}
        onSubmit={saveUpdates}
      >
        {({ handleSubmit, isValid, isSubmitting }) => {
          const data = { ...textFieldsData };
          if (!freeEmailStatus) {
            delete data.companyName;
          }
          return (
            <Form onSubmit={handleSubmit}>
              <FormFieldMap
                horizontal
                textFieldsData={data}
                textField={FormTextField}
              />
              <SubmitButtonControl>
                <SubmitButtonWrapper>
                  <FormSubmitButton
                    text="Save Updates"
                    isFetching={isSubmitting}
                    disabled={isSubmitting || !isValid}
                  />
                </SubmitButtonWrapper>
              </SubmitButtonControl>
            </Form>
          );
        }}
      </Formik>
    </ProfileFormWrapper>
  );
}

export default BasicInfoForm;
