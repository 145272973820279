import styled from 'styled-components';
import { Drawer as sidebar } from '@material-ui/core';
import { NavLink as link } from 'react-router-dom';

import { CompanyTitle } from '~/elements';

import {
  LogoSmall,
  MyWorkIcon as MyWork,
  ColleaguesWorkIcon as ColleaguesWork,
  CoachingExplorerIcon as CoachingExplorer,
} from '~/assets';

import { space, breakpoints, colors, typography } from '~/styles';

export const Drawer = styled(sidebar)`
  .MuiPaper-root {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    overflow: visible;
    width: ${(props) => (props.open ? '290px' : '80px')};
    min-height: 650px;
    position: relative;
    box-shadow: 0 2px 24px 0 ${colors.SIDEBAR_SHADOW};
    background-color: ${colors.PRIMARY};
    transition: 0.3s ease-out;

    @media (min-width: ${breakpoints.RG}) {
      width: ${(props) => (props.open ? '340px' : '80px')};
    }
  }
`;

export const DrawerContentContainer = styled.div`
  padding-right: ${(props) => (props.isOpened ? space.LG : 0)};
  padding-left: ${(props) => (props.isOpened ? space.XL : 0)};
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    padding-left: ${(props) => (props.isOpened ? space.XL : 0)};
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.isOpened ? 'flex-start' : 'center')};
  width: 100%;
  height: 36px;
  margin: ${space.SM} 0 60px 0;
  cursor: pointer;
  margin-left: ${(props) => (props.isOpened ? space.XXS : 0)};

  @media (min-width: ${breakpoints.RG}) {
    margin-left: ${(props) => (props.isOpened ? '20px' : 0)};
  }
`;

export const Logo = styled.img.attrs({
  src: LogoSmall,
  srcSet: `${LogoSmall} 110w`,
  sizes: `
    (max-width: ${breakpoints.SM}) 35px,
    (max-width: ${breakpoints.LG}) 35px,
    35px
  `,
})``;

export const LogoTitle = styled(CompanyTitle)`
  margin-left: 5px;
`;

export const InviteTeamSummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
`;

export const HorizontalLine = styled.hr`
  width: 220px;
  height: 1.2px;
  margin-bottom: 30px;
  border: 1px solid ${colors.PROGRESS_BAR};
`;

export const InviteTeamSummary = styled.div`
  width: 220px;
  text-align: start;
  font-size: ${typography.size.XS};
  font-family: ${typography.font.TEXT};
  color: ${colors.WHITE};
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 155px;
  height: 50px;
`;

// SideBarContent styles
export const WorkItemsContainer = styled.ul`
  display: flex;
  flex-direction: column;
  margin-bottom: ${space.XXXL};
  align-items: ${(props) => (props.isOpened ? 'flex-start' : 'center')};
  justify-content: center;
  width: 100%;
`;

export const Item = styled.li`
  width: 100%;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }

  background-color: ${(props) =>
    props.isActive ? 'rgba(255, 255, 255, 0.1)' : 'inherit'};
`;

export const NavLink = styled(link)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: ${(props) => (props.opened === 'true' ? space.SM : 0)};
  padding-left: ${(props) => (props.opened === 'true' ? space.XL : space.XS)};
  height: 50px;
  text-decoration: none;

  @media (min-width: ${breakpoints.RG}) {
    padding-left: ${(props) =>
      props.opened === 'true' ? space.XXL : space.XS};
  }
`;

export const MyWorkIcon = styled(MyWork)`
  min-width: 35px;
  min-height: 35px;
`;

export const ColleaguesWorkIcon = styled(ColleaguesWork)`
  min-width: 35px;
  min-height: 35px;
`;

export const CoachingExplorerIcon = styled(CoachingExplorer)`
  min-width: 35px;
  min-height: 35px;
`;

export const NavTitle = styled.div`
  display: flex;
  min-width: max-content;
  margin-left: 11px;
  margin-right: 10px;
  font-size: ${typography.size.MD};
  font-family: ${typography.font.TEXT};
  color: ${colors.WHITE};
`;

export const CollapseDrawerContainer = styled.div`
  position: absolute;
  top: 76px;
  right: -13px;
  z-index: 2;
`;
