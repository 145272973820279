import styled from 'styled-components';

import { EmptyState } from '~/assets';
import { colors, typography } from '~/styles';

export const EmtyStateContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  margin-top: 12%;
`;

export const EmptyStateContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
`;

export const EmptyStateIcon = styled(EmptyState)``;

export const MessageContainer = styled.div`
  font-family: ${typography.font.TITLE};
  font-size: ${typography.size.SM};
  text-align: center;
`;

export const ClickableMessage = styled.span`
  margin: 0;
  padding: 0;
  color: ${colors.BRAND_COLOR};
  text-decoration: underline;
  cursor: pointer;

  &:active {
    opacity: 0.75;
  }
`;

export const Message = styled.span`
  margin: 0;
  padding: 0;
  color: ${colors.SECONDARY_TEXT};
`;
