export const initialValues = {
  collaborators: [],
  stakeholders: [],
};

export const errorDictionary = {
  UWI2: {
    toast:
      'You have entered one or more invalid e-mail addresses. Please use a work e-mail address only.',
  },
  UWI10: {
    toast: 'Invitation e-mail failed. Check the e-mail address and try again.',
  },
  UWI13: {
    toast: "You don't need to tag yourself to your own work item.",
  },
  UWI16: {
    toast:
      'Please remove the duplicate people tagged across your Collabortors and Stakeholders.',
  },
};

export const inviteFieldsData = [
  {
    name: 'collaborators',
    label: 'Add Collaborators:',
    placeholder: 'Add emails separated by commas ',
    noOptionsMessage: {
      title: 'User not found',
      description: 'Add a valid work email to invite them',
      inviteDescription: 'Click here to invite them',
    },
  },
  {
    name: 'stakeholders',
    label: 'Add Stakeholders:',
    placeholder: 'Add emails separated by commas ',
    noOptionsMessage: {
      title: 'User not found',
      description: 'Add a valid work email to invite them',
      inviteDescription: 'Click here to invite them',
    },
  },
];
