import React from 'react';
import { Formik, Field } from 'formik';
import { useDispatch, useSelector } from 'react-redux';

import { joinWaitListFetchingSelector } from '~/modules/Waitlist/Join/redux/selectors';

import { FormTextField, FormSubmitButton } from '~/components';

import { actionTypes } from '~/modules/Waitlist/Join/redux/joinSlice';
import {
  joinWaitListSchema,
  joinWaitListInitialValues,
  errorDictionary,
} from './formsData';

import {
  FormWrapper,
  Form,
  FormControl,
  FormTitle,
  ButtonControl,
} from './JoinFormStyles';

function JoinForm() {
  const dispatch = useDispatch();

  const isFetching = useSelector(joinWaitListFetchingSelector);

  const handleSubmitButton = (data) => {
    const { joinWaitListRequest } = actionTypes;

    dispatch(joinWaitListRequest({ data, errorDictionary }));
  };

  return (
    <FormWrapper>
      <Formik
        enableReinitialize
        initialValues={joinWaitListInitialValues}
        validationSchema={joinWaitListSchema}
        onSubmit={handleSubmitButton}
      >
        {({ handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            <FormTitle>
              If you’re interested in learning more or be the first to try our
              product, please sign-up below.
            </FormTitle>
            <FormControl>
              <Field
                name="email"
                label="Company / Work E-mail Address"
                component={FormTextField}
                placeholder="Email"
              />
            </FormControl>
            <ButtonControl>
              <FormSubmitButton
                text="Submit"
                isFetching={isFetching}
                disabled={isFetching || !isValid}
              />
            </ButtonControl>
          </Form>
        )}
      </Formik>
    </FormWrapper>
  );
}

export default JoinForm;
