import React, { useState } from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';
import { CheckboxLabel, Checkbox } from '~/elements';

import {
  CheckboxWithFieldContainer,
  CheckboxControl,
  FieldControl,
} from './FormCheckboxWithTextfieldStyle';

function FormCheckboxWithTextField({ textField, fieldName, setFieldValue }) {
  const [check, setCheck] = useState(true);

  const checkBoxHandler = () => {
    setCheck(!check);

    if (check === false) {
      setFieldValue(fieldName, '');
    }
  };

  return (
    <CheckboxWithFieldContainer>
      <CheckboxControl>
        <CheckboxLabel
          control={<Checkbox onClick={checkBoxHandler} />}
          label="Other, please specify:"
          labelPlacement="end"
        />
      </CheckboxControl>
      <FieldControl>
        <Field
          name="other"
          disabled={check}
          placeholder="Enter"
          component={textField}
        />
      </FieldControl>
    </CheckboxWithFieldContainer>
  );
}

FormCheckboxWithTextField.propTypes = {
  textField: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};

export default FormCheckboxWithTextField;
