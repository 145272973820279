import React from 'react';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';
import PropTypes from 'prop-types';

import {
  NotificationFieldWrapper,
  NotificationFieldContainer,
  NotificationCircleContainer,
  NotificationCircle,
  NotificationFieldContent,
  Text,
  LinkText,
  ArrowRightIconContainer,
  ArrowRightIcon,
} from './NotificationFieldStyles';

function NotificationField({ link, push: navigate }) {
  const navigateToPage = () => navigate(link);

  return (
    <NotificationFieldWrapper>
      <NotificationFieldContainer>
        <NotificationFieldContent>
          <NotificationCircleContainer>
            <NotificationCircle />
          </NotificationCircleContainer>
          <Text>
            Your profile is incomplete. It only takes 2 minutes to fill out your
            information.&nbsp;
            <LinkText onClick={navigateToPage}>Let&apos;s go!</LinkText>
          </Text>
        </NotificationFieldContent>
        <ArrowRightIconContainer>
          <ArrowRightIcon />
        </ArrowRightIconContainer>
      </NotificationFieldContainer>
    </NotificationFieldWrapper>
  );
}

NotificationField.defaultProps = {
  link: '#',
};

NotificationField.propTypes = {
  link: PropTypes.string,
  push: PropTypes.func.isRequired,
};

export default connect(null, { push })(NotificationField);
