import React from 'react';
import PropTypes from 'prop-types';

import {
  EmtyStateContainer,
  EmptyStateContentContainer,
  EmptyStateIcon,
  MessageContainer,
  ClickableMessage,
  Message,
} from './EmptyStateStyles';

function EmptyState({ onClickAction }) {
  return (
    <EmtyStateContainer>
      <EmptyStateContentContainer>
        <EmptyStateIcon />
        <MessageContainer>
          <Message>
            Forgot to tag some of your Collaborators or Stakeholders?{' '}
            <ClickableMessage onClick={onClickAction}>
              Tag them
            </ClickableMessage>{' '}
            now to start getting feedback!
          </Message>
        </MessageContainer>
      </EmptyStateContentContainer>
    </EmtyStateContainer>
  );
}

EmptyState.propTypes = {
  onClickAction: PropTypes.func.isRequired,
};

export default EmptyState;
