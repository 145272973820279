import { createSlice } from '@reduxjs/toolkit';

const createPasswordState = {
  isFetching: false,
};

const createPasswordSlice = createSlice({
  name: 'AUTH',
  initialState: createPasswordState,
  reducers: {
    createPasswordRequest: (state) => {
      state.isFetching = true;
    },
    createPasswordSuccess: (state) => {
      state.isFetching = false;
    },
    createPasswordError: (state) => {
      state.isFetching = false;
    },
  },
});

export const actionTypes = createPasswordSlice.actions;

const createPasswordReducer = createPasswordSlice.reducer;

export default createPasswordReducer;
