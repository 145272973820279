import styled from 'styled-components';
import Button from '@material-ui/core/Button';

import { colors, typography, breakpoints } from '~/styles';

export const ButtonComponent = styled(Button).attrs({
  type: 'button',
})`
  width: 100%;
  height: 100%;

  &.MuiButtonBase-root {
    padding: 0;
    border: solid 2px ${colors.WHITE};
    border-radius: 4px;
    background-color: ${colors.PRIMARY};
    font-family: ${typography.font.TITLE};
    font-size: ${typography.size.XS};
    text-align: center;
    text-transform: none;
    color: ${colors.WHITE};

    &:hover {
      background-color: ${colors.WHITE};
      color: ${colors.PRIMARY};
    }

    @media (min-width: ${breakpoints.MD}) {
      &.MuiButtonBase-root {
        font-size: ${typography.size.MD};
      }
    }
  }
`;
