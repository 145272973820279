import * as Yup from 'yup';

import { email } from '~/utils/formsData';

export const errorDictionary = {
  W1: {
    toast: 'Please use your work email to sign up for the waitlist. Thank you!',
  },
  W3: {
    toast: `We are having technical difficulties. Can you try submitting this again?`,
  },
};

// Schemas
export const joinWaitListSchema = Yup.object({
  email,
});

// Initial values
export const joinWaitListInitialValues = {
  email: '',
};
