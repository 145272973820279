import React, { useEffect } from 'react';
import { Formik, Field } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import {
  getAuthenticatedStatus,
  getAuthenticatedToken,
} from '~/modules/Auth/AuthProvider/redux/selectors';
import { actionTypes } from '~/modules/ContactUs/redux/contactUsSlice';
import { getBasicProfileInfo } from '~/modules/Profile/redux/selectors';
import { actionTypes as profileActionTypes } from '~/modules/Profile/redux/profileSlice';

import Recaptcha from '~/modules/Recaptcha';

import {
  FormFieldMap,
  FormTextField,
  FormSubmitButton,
  FormSelect,
} from '~/components';

import {
  errorDictionary,
  getTextFieldsData,
  getContactUsIntitalValues,
  selectData,
  requiredContactUsSchema,
  optionalContactUsSchema,
} from './formsData';

import {
  FormContainer,
  Form,
  FormTitle,
  FormFieldWrapper,
  ButtonControl,
} from './ContactUsFormStyles';

function ContactUsForm() {
  const isAuthenticated = useSelector(getAuthenticatedStatus);
  const token = useSelector(getAuthenticatedToken);
  const profileInfo = useSelector(getBasicProfileInfo);

  const dispatch = useDispatch();

  const handleSubmitButton = (
    data,
    { setErrors, setSubmitting, resetForm },
  ) => {
    dispatch(
      actionTypes.contactUsRequest({
        data,
        setErrors,
        errorDictionary,
        resetForm,
        setSubmitting,
      }),
    );
  };

  useEffect(() => {
    if (token) {
      dispatch(profileActionTypes.getPersonInfoRequest());
    }
  }, [dispatch, token]);

  const recaptchaToken = import.meta.env.VITE_RECAPTCHA_KEY || '';

  return (
    <GoogleReCaptchaProvider reCaptchaKey={recaptchaToken}>
      <FormContainer>
        <Formik
          validateOnMount
          validationSchema={
            isAuthenticated ? optionalContactUsSchema : requiredContactUsSchema
          }
          enableReinitialize
          initialValues={getContactUsIntitalValues(profileInfo)}
          onSubmit={handleSubmitButton}
        >
          {({ handleSubmit, isValid, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormTitle>Contact Us</FormTitle>
              <FormFieldWrapper>
                <FormFieldMap
                  horizontal
                  addSelect
                  textField={FormTextField}
                  textFieldsData={getTextFieldsData(isAuthenticated)}
                  selectComponent={FormSelect}
                  selectData={selectData}
                />
              </FormFieldWrapper>
              <Field
                name="message"
                label="Message"
                placeholder="Please give us enough details so we can appropriately respond."
                component={FormTextField}
                multiline
                rowsMax="4"
              />
              <ButtonControl>
                <Recaptcha widgetId="contact_us" />
                <FormSubmitButton
                  text="Submit"
                  disabled={isSubmitting || !isValid}
                  isFetching={isSubmitting}
                />
              </ButtonControl>
            </Form>
          )}
        </Formik>
      </FormContainer>
    </GoogleReCaptchaProvider>
  );
}

export default ContactUsForm;
