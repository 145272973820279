import React from 'react';
import { CoachingExplorerDashboard } from '~/modules/Dashboard';
import { DashboardWrapper } from '~/elements';

function CoachingExplorer() {
  return (
    <DashboardWrapper>
      <CoachingExplorerDashboard />
    </DashboardWrapper>
  );
}

export default CoachingExplorer;
