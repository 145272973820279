import React from 'react';

import { CreateAccountForm, CreateAccountIntro } from '~/modules/Auth';
import { TopBarNavigator } from '~/modules/Navigation';

import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainer,
} from '~/elements';

import { topBarDataForCreateAccount } from '~/modules/Navigation/constants';
import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  dataLayer: {
    page: 'CreateAccount',
  },
  dataLayerName: 'PageDataLayer',
};

function CreateAccount() {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <AuthPageWrapper>
      <NavigationBarContainer>
        <TopBarNavigator isOneItem oneItemData={topBarDataForCreateAccount} />
      </NavigationBarContainer>
      <PageContainer>
        <CreateAccountIntro />
        <CreateAccountForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default CreateAccount;
