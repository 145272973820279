import React from 'react';
import PropTypes from 'prop-types';

import {
  ModalWrapper,
  LogoContainer,
  CloseIconContainer,
  CloseIcon,
  Logo,
  LogoTitle,
  ContentContainer,
  ConentTitle,
  ContentDescription,
} from './ModalStyles';

function Modal({ title, description, handleClose }) {
  return (
    <ModalWrapper>
      <CloseIconContainer onClick={handleClose}>
        <CloseIcon />
      </CloseIconContainer>
      <LogoContainer>
        <Logo />
        <LogoTitle>upful.ai</LogoTitle>
      </LogoContainer>
      <ContentContainer>
        <ConentTitle>{title}</ConentTitle>
        <ContentDescription>{description}</ContentDescription>
      </ContentContainer>
    </ModalWrapper>
  );
}

Modal.defaultProps = {
  description: '',
};

Modal.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

export default Modal;
