import { createSlice } from '@reduxjs/toolkit';

const modalInitialState = {
  isOpen: false,
  modalType: '',
};

const modalSlice = createSlice({
  name: 'MODAL',
  initialState: modalInitialState,
  reducers: {
    openModal: (state, action) => {
      const { modalType } = action.payload;

      state.isOpen = true;
      state.modalType = modalType;
    },
    closeModal: (state) => {
      state.isOpen = false;
      state.modalType = '';
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
