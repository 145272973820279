import styled from 'styled-components';

import { breakpoints } from '~/styles';

export const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const DashboardContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 15px;

  @media (min-width: ${breakpoints.RG}) {
    padding: 0 120px 0 30px;
  }
`;
