import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactPlayer from 'react-player';
import smiley from '~/assets/images/smiley.png';
import {
  getIsAccountFull,
  getPersonEmail,
  getPersonFullName,
} from '~/modules/Profile/redux/selectors';
import { pageRoutes } from '~/modules/Navigation/constants';
import ChromeExtVideo from '~/assets/videos/ChromeExtensionVideoWithSound.mp4';
import {
  DashboardBasis,
  NotificationField,
} from '~/modules/Dashboard/components';
import LoadingState from '~/modules/Dashboard/components/LoadingState';
import { ContentContainer } from '~/modules/Dashboard/components/SharedStyledComponents';
import {
  getAuthenticatedToken,
  getCompanyId,
  getExpiresAt,
  getPersonId,
  getRefreshExpiresAt,
  getRefreshToken,
} from '~/modules/Auth/AuthProvider/redux/selectors';
import { email } from '~/utils/formsData';
import InstructionSection from '~/components/InstructionSection';
import { ReactComponent as UserCheckOutlined } from '~/assets/images/user-check.svg';
import { ReactComponent as ComputerOutlined } from '~/assets/images/computer.svg';
import { ReactComponent as CloudDownloadOutlined } from '~/assets/images/cloud-download.svg';
import { ReactComponent as PlayOutlined } from '~/assets/images/play.svg';
import { Button, Fade, Icon, Paper } from '@material-ui/core';
import { ReactComponent as CheckOutlined } from '~/assets/images/check.svg';
import { ReactComponent as MinusOutlined } from '~/assets/images/minus.svg';
import FAQ from '~/components/InstructionSection/FAQ';

function MainPageDashboard() {
  const dispatch = useDispatch();
  const isAccountFull = useSelector(getIsAccountFull);
  const personId = useSelector(getPersonId);
  const token = useSelector(getAuthenticatedToken);
  const refresh = useSelector(getRefreshToken);
  const expiresAt = useSelector(getExpiresAt);
  const refreshExpiresAt = useSelector(getRefreshExpiresAt);
  const name = useSelector(getPersonFullName);
  const email = useSelector(getPersonEmail);
  const [page, setPage] = useState('getstarted');
  const [faq, setFaq] = useState(0);
  const [hasChromeExt, setHasChromeExt] = useState(false);
  const companyId = useSelector(getCompanyId);
  const onPlayerReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo(); // can playVideo as well on load
  };

  useEffect(() => {
    const id = 'gbpdiocilmglaogkogclnngcgbchjbha';
    (async () => {
      await chrome.runtime?.sendMessage(
        id,
        {
          cmd: 'setToken',
          isAuthenticated: true,
          token,
          refresh,
          personId,
          ...name,
          email,
          companyId,
          expiresAt,
          refreshExpiresAt,
        },
        (response) => {
          if (response?.status === 'ok') {
            setHasChromeExt(true);
          }
        },
      );
    })();
  }, [
    companyId,
    personId,
    name,
    email,
    token,
    refresh,
    expiresAt,
    refreshExpiresAt,
  ]);

  return (
    <DashboardBasis>
      <div className="text-[30px] font-[700] text-center w-full font-[Lato-Regular] mt-[30px] mb-[15px]">
        Welcome to Upful!
      </div>
      <div className="text-[18px] text-center w-full font-[Lato-Regular] mb-[25px]">
        To get started, you&apos;ll need the{' '}
        <a
          className="link-primary link"
          rel="noreferrer"
          target="_blank"
          href="https://chrome.google.com/webstore/detail/upfulai-chrome-extension/gbpdiocilmglaogkogclnngcgbchjbha"
        >
          Upful Chrome Extension
        </a>
      </div>
      <div className="w-full flex flex-col items-center justify-center mb-[25px]">
        <Button
          style={{ backgroundColor: '#35BA88', color: '#fff' }}
          variant="contained"
          size="large"
          className="w-[350px]"
          onClick={() => {
            window.open(
              'https://chrome.google.com/webstore/detail/upfulai-chrome-extension/gbpdiocilmglaogkogclnngcgbchjbha',
              { target: '_blank', noopener: true },
            );
          }}
        >
          Download the Chrome Extension
        </Button>
      </div>
      <div className="w-full flex flex-col h-full justify-between items-center mx-auto pt-[15px]">
        <div className={`flex flex-col justify-center items-center`}>
          <div className="flex flex-row justify-center space-x-0 w-[650px] text-[#6A7DA4]">
            <div className="mb-[20px] pb-[15px] border-b w-[60px]">&nbsp;</div>
            <div
              onClick={() => setPage('getstarted')}
              className={`mb-[20px] border-b cursor-pointer whitespace-pre text-center mx-6 px-4`}
            >
              <div
                className={`pb-[15px] px-2 ${
                  page === 'getstarted' ? 'border-b-[5px] border-[#0899FB]' : ''
                }`}
              >
                Get Started
              </div>
            </div>
            <div
              onClick={() => setPage('instructions')}
              className={`mb-[20px] border-b cursor-pointer whitespace-pre text-center mx-6 px-4`}
            >
              <div
                className={`pb-[15px] px-2 ${
                  page === 'instructions'
                    ? 'border-b-[5px] border-[#0899FB]'
                    : ''
                }`}
              >
                Instructions
              </div>
            </div>
            <div
              onClick={() => setPage('wheretouse')}
              className={`mb-[20px] border-b cursor-pointer whitespace-pre text-center mx-6 px-4`}
            >
              <div
                className={`pb-[15px] px-2 ${
                  page === 'wheretouse' ? 'border-b-[5px] border-[#0899FB]' : ''
                }`}
              >
                Where to Use
              </div>
            </div>
            <div
              onClick={() => setPage('topfaqs')}
              className={`mb-[20px] border-b cursor-pointer whitespace-pre text-center mx-6 px-4`}
            >
              <div
                className={`pb-[15px] px-2 ${
                  page === 'topfaqs' ? 'border-b-[5px] border-[#0899FB]' : ''
                }`}
              >
                Top FAQs
              </div>
            </div>
            <div className="mb-[20px] pb-[15px] border-b w-[60px]">&nbsp;</div>
          </div>

          <div className="">
            <Fade in={page === 'getstarted'} timeout={1000}>
              <div
                className={`flex flex-col items-center w-[500px] ${
                  page === 'getstarted' ? 'block' : 'hidden'
                }`}
              >
                <div className="flex flex-row items-center justify-center mb-[15px]">
                  <div>
                    <PlayOutlined className="w-[35px] h-[35px] mr-[10px] " />
                  </div>
                  <div className="text-[18px] font-400 text-[#1A2335]">
                    Watch the quick start video
                  </div>
                </div>
                <ReactPlayer
                  url={[ChromeExtVideo]}
                  playing={true}
                  controls={true}
                  muted={true}
                  width="100%"
                  height="50%"
                  className="mx-auto"
                />
              </div>
            </Fade>
            <Fade in={page === 'instructions'} timeout={1000}>
              <div
                className={` ${page === 'instructions' ? 'block' : 'hidden'}`}
              >
                <InstructionSection
                  title="Instructions"
                  subtitle=""
                  horizontalItems={[
                    {
                      Img: CloudDownloadOutlined,
                      ImgAlt: 'Download extension from google chrome store',
                      body: 'Download extension',
                    },
                    {
                      Img: UserCheckOutlined,
                      ImgAlt: 'User logged in',
                      body: 'Stay logged in to Upful account',
                    },
                    {
                      Img: ComputerOutlined,
                      ImgAlt: 'Computer screen',
                      body: 'Refresh page to start coaching',
                    },
                  ]}
                >
                  <ul className="list-disc">
                    <li>
                      Download the{' '}
                      <a
                        className="link-primary link"
                        rel="noreferrer"
                        target="_blank"
                        href="https://chrome.google.com/webstore/detail/upfulai-chrome-extension/gbpdiocilmglaogkogclnngcgbchjbha"
                      >
                        Upful Chrome Extension here.
                      </a>
                    </li>
                    <li>
                      Stay logged into your Upful account to continue to get
                      coaching
                    </li>
                    <li>
                      If you want the coaching to work on a certain page, you
                      just need to refresh that page to activate the extension
                      on that page.
                    </li>
                    <li>
                      If the Chrome Extension doesn&apos;t recognize that you
                      are logged in, try refreshing the{' '}
                      <a
                        className="link-primary link"
                        rel="noreferrer"
                        target="_blank"
                        href="https://app.upful.ai"
                      >
                        app.upful.ai
                      </a>{' '}
                      page
                    </li>
                    <li>
                      When coaching is available, you will see text underlined
                      with a yellow/orange line. Click on that text and you will
                      see Upful&apos;s coaching.
                    </li>
                    <li>
                      You can give us feedback on the coaching, within each
                      pop-up.
                    </li>
                    <li>
                      If you&apos;d like us to add coaching, we are actively
                      monitoring to make improvements and broaden the coaching,
                      but we would love to hear from you directly!
                    </li>
                  </ul>
                </InstructionSection>
              </div>
            </Fade>
            <Fade in={page === 'wheretouse'} timeout={1000}>
              <div className={` ${page === 'wheretouse' ? 'block' : 'hidden'}`}>
                <InstructionSection
                  title="Where To Use"
                  subtitle="For Chrome on Desktops/Laptops"
                  horizontalItems={[
                    {
                      Img: CheckOutlined,
                      ImgAlt: 'Works on',
                      title: 'Works On:',
                      body: 'Lattice, CultureAmp, Lever, Greenhouse, Workable, Pando, BambooHR, Slack, Facebook Messenger, Discord, LinkedIn... many text boxes!',
                    },
                    {
                      Img: MinusOutlined,
                      ImgAlt: 'Does not work on',
                      title: 'Does Not Work On:',
                      body: 'Google pages, like Gmail and Google Docs',
                      note: '(We have disabled it on these sites until we have enough time and money to build out that capability.)',
                    },
                  ]}
                >
                  <ul className="list-disc">
                    <li>
                      Upful works on your laptop on Chrome (or Chromium, e.g.
                      Brave, Microsoft Edge) browsers only
                    </li>
                    <li>
                      Upful DOES NOT currently work on Google pages, like Gmail
                      and Google Docs. We have disabled it on these sites until
                      we have enough time and money to build out that
                      capability.
                    </li>
                    <li>
                      It works on top of a lot of textboxes, but not every
                      textbox.
                    </li>
                    <li>
                      So far, we know it works on these sites (if you&apos;re
                      viewing it on a Chrome browser): Lattice, CultureAmp,
                      Lever, Greenhouse, Workable, Pando, BambooHR, Slack,
                      Facebook Messenger, Discord, LinkedIn...
                    </li>
                    <li>
                      Do you want to limit which pages Upful is enabled on? We
                      can do that. Let us know which URLs you want it to work on
                      or you want it blocked on.
                    </li>
                  </ul>
                </InstructionSection>
              </div>
            </Fade>
            <Fade in={page === 'topfaqs'} timeout={1000}>
              <div className={` ${page === 'topfaqs' ? 'block' : 'hidden'}`}>
                <InstructionSection title="Top FAQs">
                  <FAQ
                    question="I’m not able to download the Extension. What can I do?"
                    item={0}
                    setFaqExpanded={(i) => setFaq(i)}
                    answer={
                      <ul className="list-[circle]">
                        <li className="list-item">
                          We can help get the Upful extension approved by your
                          company.{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="link-primary link"
                            href="https://forms.gle/PTSyXmNd3qg74Kv47"
                          >
                            Request this by filling out this form.
                          </a>{' '}
                          You can choose to be anonymous, as well.
                        </li>
                        <li>
                          In the meantime, you can use our{' '}
                          <a
                            className="link link-primary"
                            href="/coachingExplorer"
                          >
                            Coaching Explorer
                          </a>
                          page to receive the same coaching within our platform,
                          without the extension. You can write in our platform
                          or copy/paste text into Upful, receive the coaching,
                          make your edits, and copy/paste it back into another
                          system you use for feedback/reviews.
                        </li>
                      </ul>
                    }
                    expanded={faq === 0}
                  />
                  <FAQ
                    question="Can I live-chat with someone at Upful? I have questions, comments, requests, ideas, etc."
                    item={1}
                    setFaqExpanded={(i) => setFaq(i)}
                    answer={
                      <ul className="list-[circle]">
                        <li>
                          Yes! If you&apos;d like to set up some time for a
                          virtual meeting, contact us via the{' '}
                          <a target="_blank" className="link-primary link">
                            Support page
                          </a>
                          . You can also chat with us on our{' '}
                          <a
                            target="_blank"
                            rel="noreferrer"
                            className="link-primary link"
                            href="https://join.slack.com/t/upfulcommunity/shared_invite/zt-1fgy4ec0h-zVP7xKlwImeyehgyRmQnNg"
                          >
                            Slack community.
                          </a>
                        </li>
                      </ul>
                    }
                    expanded={faq === 1}
                  />
                  <FAQ
                    question="How do you use my profile information?"
                    item={2}
                    setFaqExpanded={(i) => setFaq(i)}
                    answer={
                      <ul className="list-[circle] ml-[40px]">
                        <li>
                          If you fill out your profile, it helps us make the
                          product better for you and for people who will give
                          you feedback or assess your performance. We will never
                          share your demographic data with your employer. When
                          we report on insights, we only share in aggregate. If
                          there are not enough people in a group, we will not
                          share that slice of data.
                        </li>
                      </ul>
                    }
                    expanded={faq === 2}
                  />
                </InstructionSection>
              </div>
            </Fade>
          </div>
        </div>
      </div>
      {!isAccountFull && (
        <div className="mt-[30px]">
          <NotificationField link={pageRoutes.PROFILE} />
        </div>
      )}
    </DashboardBasis>
  );
}

export default MainPageDashboard;
