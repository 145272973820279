/* eslint-disable no-nested-ternary */
import styled from 'styled-components';

import { typography, colors } from '~/styles';

export const MultiSelectContainer = styled.div`
  width: 100%;
`;

export const multiSelectStyles = {
  control: (styles) => ({
    ...styles,
    minHeight: 24,
    border: 'none',
    boxShadow: 'none',
    fontFamily: typography.font.TEXT,
    fontSize: typography.size.SM,

    ':hover': {
      border: 'none',
      boxShadow: 'none',
    },
  }),
  menu: (provided, { selectProps: { menuBias, menuWidth } }) => ({
    ...provided,
    left: menuBias,
    width: `${menuWidth}px`,
    padding: '10px 0',
    marginTop: 18,
    fontFamily: typography.font.TEXT,
  }),
  menuList: (styles, { selectProps: { maxMenuHeight } }) => ({
    ...styles,
    maxHeight: maxMenuHeight,
    padding: 0,
  }),
  valueContainer: (styles) => ({
    ...styles,
    minHeight: 34,
    padding: 0,
  }),
  placeholder: (styles) => ({
    ...styles,
    color: colors.SECONDARY_TEXT,
    opacity: 0.7,
  }),
  input: (styles) => ({
    ...styles,
    margin: 0,
    padding: 0,
    color: colors.PRIMARY,
  }),
  option: (styles, { isDisabled, isFocused, isSelected }) => ({
    ...styles,
    padding: '5px 20px',
    backgroundColor: isDisabled
      ? null
      : isSelected
      ? colors.PRIMARY_BG
      : isFocused
      ? colors.PRIMARY_BG
      : null,

    ':active': {
      ...styles[':active'],
      backgroundColor: colors.PRIMARY_BG,
    },
  }),
  multiValue: (styles, { selectProps, data }) => {
    const { status = [] } = selectProps;

    const isInvalid = status.includes(data.value);

    return {
      ...styles,
      margin: '5px 0',
      border: `solid 1px ${isInvalid ? colors.ERROR : colors.SECONDARY_TEXT}`,
      borderRadius: 13,
      backgroundColor: 'inherit',

      '&:not(:last-child)': {
        marginRight: 10,
      },
    };
  },
  multiValueRemove: (styles) => ({
    ...styles,

    ':hover': {
      backgroundColor: 'inherit',
      color: colors.ERROR,
      cursor: 'pointer',
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    padding: '5px 0',
  }),
};

export const InviteOptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;

  &:hover {
    background-color: ${colors.PRIMARY_BG};
  }
`;

export const InviteLabel = styled.span`
  font-size: ${typography.size.XS};
  color: ${colors.PRIMARY};
`;

export const InviteLabelDescription = styled.span`
  padding-top: 2px;
  font-size: ${typography.size.XXS};
  color: ${colors.SECONDARY_TEXT};
`;
