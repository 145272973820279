export const submitPhoneNumberNormalizer = (data) => ({
  first_name: data.firstName.trim(),
  last_name: data.lastName.trim(),
  username: data.workEmail,
  terms_accepted: data.termsAndConditions,
  phone_2fa: data.phoneNumber.trim(),
  invite_code: data.inviteCode.trim(),
});

export const submitPhoneNumberSuccessNormalizer = (responseData, data) => ({
  personId: responseData.person_id,
  companyId: responseData.company_id,
  phoneNumber: data.phoneNumber,
});

export const resendCondeRequestNormalizer = (data, phoneNumber) => ({
  company_id: data.companyId,
  phone_2fa: phoneNumber.trim(),
});

export const verifyCodeRequestNormalizer = (data) => ({
  code: data.verificationCode.trim(),
  company_id: data.companyId,
});
