import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, connect } from 'react-redux';
import { push } from 'connected-react-router';

import { getAccountOccupancy } from '~/modules/Profile/redux/selectors';
import { isSideBarOpenedSelector } from '~/modules/Navigation/redux/selectors';
import { actionTypes } from '~/modules/Navigation/redux/navigationSlice';
import { pageRoutes } from '~/modules/Navigation/constants';

import { ProgressBar, OutlinedButton, ArrowButton } from '~/components';

import SideBarContent from './SideBarContent';

import {
  Drawer,
  LogoContainer,
  Logo,
  LogoTitle,
  HorizontalLine,
  InviteTeamSummaryContainer,
  InviteTeamSummary,
  DrawerContentContainer,
  ButtonContainer,
  CollapseDrawerContainer,
} from './SideBarStyles';

function SideBar({ isHidden, push: navigate }) {
  const dispatch = useDispatch();
  const accountOccupancy = useSelector(getAccountOccupancy);
  const isOpened = useSelector(isSideBarOpenedSelector) && !isHidden;
  const isHideForMVP = true; // temporary until Invite Team Modal will be implemented

  const handleRedirect = () => {
    navigate(pageRoutes.HOME);
  };

  const handleDrawerOpen = () => {
    return dispatch(actionTypes.toggleSideBar());
  };

  const openModal = () => {};

  return (
    <Drawer variant="permanent" open={isOpened}>
      <CollapseDrawerContainer>
        {!isHidden && (
          <ArrowButton isCollapsed={isOpened} onClick={handleDrawerOpen} />
        )}
      </CollapseDrawerContainer>
      <DrawerContentContainer isOpened={isOpened}>
        <LogoContainer isOpened={isOpened} onClick={handleRedirect}>
          <Logo isOpened={isOpened} />
          {isOpened && <LogoTitle>Upful.ai</LogoTitle>}
        </LogoContainer>
      </DrawerContentContainer>
      <SideBarContent opened={isOpened} />
      <DrawerContentContainer isOpened={isOpened}>
        {isOpened && (
          <>
            {accountOccupancy !== null && accountOccupancy !== 100 && (
              <ProgressBar
                percentage={accountOccupancy}
                link={pageRoutes.PROFILE}
              />
            )}
            {!isHideForMVP && (
              <>
                <HorizontalLine />
                <InviteTeamSummaryContainer>
                  <InviteTeamSummary>
                    Invite your team and start tracking your work and get
                    feedback on it inreal-time!
                  </InviteTeamSummary>
                </InviteTeamSummaryContainer>
                <ButtonContainer>
                  <OutlinedButton
                    text="Invite Team"
                    onClickAction={openModal}
                  />
                </ButtonContainer>
              </>
            )}
            {!isHideForMVP && (
              <>
                <InviteTeamSummaryContainer>
                  <InviteTeamSummary>
                    Invite your team and start tracking your work and get
                    feedback on it inreal-time!
                  </InviteTeamSummary>
                </InviteTeamSummaryContainer>
                <ButtonContainer>
                  <OutlinedButton
                    text="Invite Team"
                    onClickAction={openModal}
                  />
                </ButtonContainer>
              </>
            )}
          </>
        )}
      </DrawerContentContainer>
    </Drawer>
  );
}

SideBar.defaultProps = {
  isHidden: false,
};

SideBar.propTypes = {
  isHidden: PropTypes.bool,
  push: PropTypes.func.isRequired,
};

export default connect(null, { push })(SideBar);
