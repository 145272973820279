import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { SubmitButton, Spinner } from './FormSubmitButtonStyles';

function FormSubmitButton({ isFetching, text, disabled, clicked }) {
  return (
    <SubmitButton onClick={clicked} variant="outlined" disabled={disabled}>
      {isFetching ? <Spinner /> : text}
    </SubmitButton>
  );
}

FormSubmitButton.defaultProps = {
  text: 'Submit',
};

FormSubmitButton.propTypes = {
  isFetching: PropTypes.bool.isRequired,
  text: PropTypes.string,
  disabled: PropTypes.bool.isRequired,
};

export default memo(FormSubmitButton);
