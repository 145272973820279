import styled from 'styled-components';

import { DashboardContentContainer } from '~/modules/Dashboard/components/DashboardBasis/DashboardBasisStyles';
import { FieldsContainer } from '~/components/FormFieldMap/FormFieldMapStyle';

import { space, colors, breakpoints } from '~/styles';

export const ProfileFormWrapper = styled.div`
  padding: ${space.LG};
  max-width: 882px;
  width: 100%;
  border: solid 1px ${colors.OUTLINES};
  border-radius: 4px;
  background-color: ${colors.WHITE};

  ${FieldsContainer} {
    flex-direction: column;

    > div:nth-child(2n + 1) {
      margin-right: 0;

      @media (min-width: ${breakpoints.RG}) {
        margin-right: 20px;
      }
    }

    @media (min-width: ${breakpoints.RG}) {
      flex-direction: row;
    }
  }
`;

export const SubmitButtonControl = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    justify-content: flex-end;
  }
`;

export const SubmitButtonWrapper = styled.div`
  height: 50px;
  width: 195px;
`;

export const ProfileContentWrapper = styled.div`
  display: flex;
  width: 100%;

  ${DashboardContentContainer} {
    padding: 0 30px;
  }
`;
