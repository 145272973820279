import React from 'react';
import { Field } from 'formik';
import PropTypes from 'prop-types';

import { FieldControl, FieldsContainer } from './FormFieldMapStyle';
import { FormTextField } from '~/components';
import { Tooltip } from '@material-ui/core';

function FormFieldMap({
  textField,
  textFieldsData,
  addSelect,
  selectComponent,
  selectData,
  vertical,
  horizontal,
  center,
  ...props
}) {
  const fields = Object.keys(textFieldsData).map((fieldName) => {
    return (
      <FieldControl horizontal={horizontal} key={`fields-form-${fieldName}`}>
        {textFieldsData[fieldName]?.hover ? (
          <Tooltip
            arrow
            title={textFieldsData[fieldName]?.hover}
            placement={textFieldsData[fieldName]?.hoverPlacement}
            style={{ marginBottom: -20 }}
          >
            <Field
              center={center}
              name={fieldName}
              label={textFieldsData[fieldName].label}
              placeholder={textFieldsData[fieldName]?.placeholder || 'Enter'}
              component={FormTextField}
              type={textFieldsData[fieldName]?.type || 'text'}
              disabled={textFieldsData[fieldName]?.disabled || false}
              {...props}
            />
          </Tooltip>
        ) : (
          <Field
            center={center}
            name={fieldName}
            label={textFieldsData[fieldName].label}
            placeholder={textFieldsData[fieldName]?.placeholder || 'Enter'}
            component={FormTextField}
            type={textFieldsData[fieldName]?.type || 'text'}
            disabled={textFieldsData[fieldName]?.disabled || false}
            {...props}
          />
        )}
      </FieldControl>
    );
  });

  const selects = Object.keys(selectData).map((selectName) => {
    return (
      <FieldControl key={`selects-form-${selectName}`} horizontal={horizontal}>
        <Field
          name={selectName}
          label={selectData[selectName].label}
          component={selectComponent}
          isSearchable={selectData[selectName]?.isSearchable}
          selectOptions={selectData[selectName].options}
        />
      </FieldControl>
    );
  });

  return (
    <FieldsContainer horizontal={horizontal} vertical={vertical}>
      {fields}
      {addSelect && selects}
    </FieldsContainer>
  );
}

FormFieldMap.defaultProps = {
  horizontal: false,
  vertical: false,
  center: false,
  selectData: {},
  textFieldsData: {},
  addSelect: false,
  selectComponent: null,
  textField: null,
};

FormFieldMap.propTypes = {
  textFieldsData: PropTypes.object,
  textField: PropTypes.func,
  selectComponent: PropTypes.func,
  horizontal: PropTypes.bool,
  vertical: PropTypes.bool,
  center: PropTypes.bool,
  selectData: PropTypes.object,
  addSelect: PropTypes.bool,
};

export default FormFieldMap;
