import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardBasis } from '~/modules/Dashboard/components';
import ProfileBasis from '~/modules/Profile';
import { DashboardWrapper } from '~/elements';

import { ProfileContentWrapper } from '~/modules/Profile/components/commonStyles';
import { isProfileDataInitialized } from '~/modules/Profile/redux/selectors';
import { actionTypes } from '~/modules/Profile/redux/profileSlice';

function Profile() {
  const dispatch = useDispatch();
  const isDataInitialized = useSelector(isProfileDataInitialized);

  useEffect(() => {
    dispatch(actionTypes.getPersonInfoRequest());

    return () => {
      dispatch(actionTypes.resetCurrentStep());
    };
  }, [dispatch]);

  useEffect(() => {
    if (isDataInitialized) {
      dispatch(actionTypes.setCurrentIncompleteStep());
    }
  }, [dispatch, isDataInitialized]);

  return (
    <DashboardWrapper>
      <ProfileContentWrapper>
        <DashboardBasis>{isDataInitialized && <ProfileBasis />}</DashboardBasis>
      </ProfileContentWrapper>
    </DashboardWrapper>
  );
}

export default Profile;
