import React from 'react';
import { useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';

import { getAuthenticatedStatus } from '~/modules/Auth/AuthProvider/redux/selectors';
import routes from '~/routes/routes';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

function AppRouter() {
  const isAuthenticated = useSelector(getAuthenticatedStatus);

  return (
    <Switch>
      {routes.map(({ isPrivate, isPublic, path, exact, component }) =>
        isPrivate ? (
          <PrivateRoute
            key={`${path}-${Boolean(exact)}`}
            path={path}
            component={component}
            exact={exact}
            isAuthenticated={isAuthenticated}
          />
        ) : (
          <PublicRoute
            key={`${path}-${Boolean(exact)}`}
            path={path}
            component={component}
            exact={exact}
            isAuthenticated={isAuthenticated}
            isPublic={isPublic}
          />
        ),
      )}
      {/* <Route component={NotFound} /> // Add 404 */}
    </Switch>
  );
}

export default AppRouter;
