import styled from 'styled-components';

import { FeedbackIcon } from '~/assets';
import { colors, typography } from '~/styles';

export const MyFeedbackHeaderConteiner = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 10px;
  padding-right: 20px;
`;

export const MyFeedbackHeaderTitle = styled.div`
  color: ${colors.PRIMARY};
  font-size: ${typography.size.MD};
  font-family: ${typography.font.TITLE};
  margin-left: 5px;
`;

export const MyFeedbacksCount = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FeedbackIconStyled = styled(FeedbackIcon)`
  path {
    stroke: ${(props) => props.variant === 'gray' && colors.SECONDARY_TEXT};
  }
`;

export const WorkItemText = styled.div`
  font-size: ${typography.size.XS};
  font-family: ${typography.font.TEXT};
  color: ${colors.SECONDARY_TEXT};
  margin-left: 10px;
`;
