import { createSelector } from '@reduxjs/toolkit';

const recaptchaSelector = (state) => state.recaptcha;

export const getRefreshRecaptchaValue = createSelector(
  recaptchaSelector,
  ({ refreshRepatchaValue }) => refreshRepatchaValue,
);

export const getRecaptchaToken = createSelector(
  recaptchaSelector,
  ({ recaptchaToken }) => recaptchaToken,
);
