import React from 'react';

import { IndustrtyTypeForm, ThankYouSection } from '~/modules/Waitlist';

import { AuthPageWrapper, PageContainer } from '~/elements';

function IndustryType() {
  return (
    <AuthPageWrapper>
      <PageContainer withAdditionalMargin>
        <ThankYouSection />
        <IndustrtyTypeForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default IndustryType;
