import React from 'react';

import { IntroSection } from '~/components';

import { CreateAccountIntroContainer } from './CreateAccountIntroStyles';

function CreateAccountIntro() {
  return (
    <CreateAccountIntroContainer>
      <IntroSection
        title="Glad you decided to join!"
        description="We need some basic information to set up your account."
        link="https://www.upful.ai"
        linkText="Learn More"
      />
    </CreateAccountIntroContainer>
  );
}

export default CreateAccountIntro;
