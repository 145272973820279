import { put, all, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import { pageRoutes } from '~/modules/Navigation/constants';
import { requestWrapper as request } from '~/utils';
import api from '~/services';
import { upfulNetworkService as apiService } from '~/services/networkService';

import { actionTypes as authActionTypes } from '~/modules/Auth/AuthProvider/redux/authProviderSlice';
import { actionTypes } from './loginSlice';

function* login({
  payload: { data, errorDictionary, setErrors, redirectPath },
}) {
  const { response } = yield request(
    api.createLogin,
    data,
    errorDictionary,
    setErrors,
  );

  if (response) {
    const {
      token,
      person,
      company,
      name,
      refresh,
      expiresAt,
      refreshExpiresAt,
      free_email,
    } = response;
    apiService.setAuthorizationHeader(token);
    yield put(
      authActionTypes.setAuthenticatedData({
        isAuthenticated: true,
        token,
        person,
        company,
        name,
        expiresAt,
        refreshExpiresAt,
        refresh,
        free_email,
      }),
    );
    yield put(actionTypes.loginSuccess());
    yield put(authActionTypes.refreshTokenRequest());
    yield put(push(redirectPath || pageRoutes.MAIN));
  } else {
    yield put(actionTypes.loginError());
  }
}

export default function* createLoginSaga() {
  yield all([takeLatest(actionTypes.loginRequest, login)]);
}
