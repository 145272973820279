import React from 'react';
import { useSelector } from 'react-redux';

import { getPersonFullName } from '~/modules/Profile/redux/selectors';

import { ProfileMenu } from '~/modules/Navigation';

import { HeaderContainer, SearchContainer } from './HeaderStyles';

function Header() {
  const { firstName, lastName } = useSelector(getPersonFullName);
  const iconText = firstName.charAt(0).concat(lastName.charAt(0));

  return (
    <HeaderContainer>
      <SearchContainer />
      <ProfileMenu
        iconText={iconText}
        firstName={firstName}
        lastName={lastName}
      />
    </HeaderContainer>
  );
}

export default Header;
