import { MainPageContent } from '~/modules/Dashboard/MainPageContent';
import React from 'react';
import { DashboardWrapper } from '~/elements';

function MainPage() {
  return (
    <DashboardWrapper>
      <MainPageContent />
    </DashboardWrapper>
  );
}

export default MainPage;
