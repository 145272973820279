import { all, fork } from 'redux-saga/effects';

import createPasswordSaga from '~/modules/Auth/CreatePassword/redux/saga';
import createCompanySaga from '~/modules/Auth/CreateCompany/redux/saga';
import createAccountSaga from '~/modules/Auth/CreateAccount/redux/saga';
import createLoginSaga from '~/modules/Auth/Login/redux/saga';
import AuthProviderSaga from '~/modules/Auth/AuthProvider/redux/saga';

export default function* authSaga() {
  yield all([
    fork(createPasswordSaga),
    fork(createCompanySaga),
    fork(createAccountSaga),
    fork(createLoginSaga),
    fork(AuthProviderSaga),
  ]);
}
