import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { getCurrentPathname } from '~/modules/Navigation/redux/selectors';
import { screensPathWithMobileDesign } from '~/modules/Navigation/constants';

function useMobileRoadblock() {
  const pathname = useSelector(getCurrentPathname);

  const oneOfAllowed = (element) => element === pathname;

  const [isMobile, setIsMobile] = useState(
    window.innerWidth < 800 && !screensPathWithMobileDesign.some(oneOfAllowed),
  );

  function handleSizeChange() {
    if (
      window.innerWidth < 800 &&
      !screensPathWithMobileDesign.some(oneOfAllowed)
    ) {
      setIsMobile(window.innerWidth < 800);
    } else {
      setIsMobile(false);
    }
  }

  React.useEffect(() => {
    handleSizeChange();
    window.addEventListener('resize', handleSizeChange);

    return () => {
      window.removeEventListener('resize', handleSizeChange);
    };
  });

  return isMobile;
}

export default useMobileRoadblock;
