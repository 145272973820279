/* eslint-disable array-callback-return */
const getErrorsByErrorType = (error, errorDictionary) => {
  const formErrorsObject = {};
  const toastErrorMessagesArray = [];
  const errorsObject = error?.data?.errors || null;

  const trackUncatchedError = () => {
    toastErrorMessagesArray.push(
      `We are having technical difficulties. Please try again. If it's still not working, please e-mail us at help@upful.ai`,
    );
  };

  if (errorsObject === null) {
    trackUncatchedError();
  } else {
    Object.keys(errorsObject).map((errorType) => {
      if (errorDictionary[errorType]?.toast) {
        toastErrorMessagesArray.push(errorDictionary[errorType].toast);
      } else if (errorDictionary[errorType]) {
        Object.assign(formErrorsObject, errorDictionary[errorType]);
      } else {
        trackUncatchedError();
      }
    });
  }

  const toastErrorArray = [...new Set(toastErrorMessagesArray)];

  return { formErrorsObject, toastErrorArray };
};

export default getErrorsByErrorType;
