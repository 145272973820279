import React from 'react';
import PropTypes from 'prop-types';

import {
  EmtyStateContainer,
  EmptyStateContentContainer,
  EmptyStateIcon,
  MessageContainer,
  ClickableMessage,
  Message,
} from './EmptyStateStyles';

function EmptyState({ clickableMessage, message, onClickAction }) {
  return (
    <EmtyStateContainer>
      <EmptyStateContentContainer>
        <EmptyStateIcon />
        <MessageContainer>
          <ClickableMessage onClick={onClickAction}>
            {clickableMessage}
          </ClickableMessage>
          <Message>{message}</Message>
        </MessageContainer>
      </EmptyStateContentContainer>
    </EmtyStateContainer>
  );
}

EmptyState.defaultProps = {
  clickableMessage: '',
};

EmptyState.propTypes = {
  clickableMessage: PropTypes.string,
  message: PropTypes.string.isRequired,
  onClickAction: PropTypes.func.isRequired,
};

export default EmptyState;
