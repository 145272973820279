import React from 'react';
import PropTypes from 'prop-types';

import {
  MyFeedbackHeaderConteiner,
  MyFeedbackHeaderTitle,
  FeedbackIconStyled,
  WorkItemText,
  MyFeedbacksCount,
} from './MyFeedbackHeaderStyles';

function MyFeedbackHeader({ feedbacksCount }) {
  return (
    <MyFeedbackHeaderConteiner>
      <MyFeedbackHeaderTitle>Feedback</MyFeedbackHeaderTitle>
      <MyFeedbacksCount>
        <FeedbackIconStyled variant="gray" />
        <WorkItemText>{`${feedbacksCount} Feedback`}</WorkItemText>
      </MyFeedbacksCount>
    </MyFeedbackHeaderConteiner>
  );
}

MyFeedbackHeader.propTypes = {
  feedbacksCount: PropTypes.number.isRequired,
};

export default MyFeedbackHeader;
