export const conctactUsRequestNormalizer = (data, recaptchaToken) => {
  data.message = data.message.replace(/\n/g, ' ').trim();

  return {
    fname: data.fname.trim(),
    lname: data.lname.trim(),
    email: data.email,
    subject: data.subject,
    message: data.message,
    recaptcha_token: recaptchaToken,
  };
};
