import { createSelector } from '@reduxjs/toolkit';

const workSelector = (state) => state.dashboard.colleaguesWork;

export const getColleaguesWork = createSelector(
  workSelector,
  ({ colleaguesWork }) => colleaguesWork,
);

export const getColleaguesWorkLoading = createSelector(
  workSelector,
  ({ isLoading }) => isLoading,
);

export const getUnreadItemsNotification = createSelector(
  workSelector,
  ({ colleaguesWork }) =>
    colleaguesWork.some((item) => item.work.is_read === false),
);
