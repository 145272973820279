import React, { useEffect } from 'react';
import { TopBarNavigator } from '~/modules/Navigation';
import { LoginForm } from '~/modules/Auth';

import {
  AuthPageWrapper,
  NavigationBarContainer,
  PageContainer,
} from '~/elements';

import { IntroSection } from '~/components';
import { topBarNavigatorButtonsData } from '~/modules/Navigation/constants';

import TagManager from 'react-gtm-module';
const tagManagerArgs = {
  dataLayer: {
    page: 'Login',
  },
  dataLayerName: 'PageDataLayer',
};

function Login() {
  TagManager.dataLayer(tagManagerArgs);
  return (
    <AuthPageWrapper>
      <NavigationBarContainer>
        <TopBarNavigator
          topBarNavigatorButtonsData={topBarNavigatorButtonsData}
        />
      </NavigationBarContainer>
      <PageContainer>
        <IntroSection description="Upful coaches employees to provide more meaningful, actionable, and unbiased performance reviews using AI and behavioral science." />
        <LoginForm />
      </PageContainer>
    </AuthPageWrapper>
  );
}

export default Login;
