import { createSlice } from '@reduxjs/toolkit';

const createCompanyState = {
  isSubmitPhoneNumberFetching: false,
  isPhoneNumberSubmitted: false,
  isVerifyCodeEnabled: false,
  isResendFetching: false,
  isResendSuccess: false,
  successSubmitPhoneData: {
    personId: '',
    companyId: '',
    phoneNumber: '',
  },
  isVerifyCodeFetching: false,
};

const createCompanySlice = createSlice({
  name: 'AUTH',
  initialState: createCompanyState,
  reducers: {
    submitPhoneNumberRequest: (state) => {
      state.isSubmitPhoneNumberFetching = true;
    },
    submitPhoneNumberSuccess: (state, { payload: data }) => {
      state.isSubmitPhoneNumberFetching = false;
      state.isPhoneNumberSubmitted = true;
      state.isVerifyCodeEnabled = true;
      state.successSubmitPhoneData = data;
    },
    submitPhoneNumberError: (state) => {
      state.isSubmitPhoneNumberFetching = false;
    },
    resendCodeRequest: (state) => {
      state.isResendFetching = true;
      state.isResendSuccess = false;
    },
    resendCodeSuccess: (state) => {
      state.isResendFetching = false;
      state.isResendSuccess = true;
    },
    resendCodeError: (state) => {
      state.isResendFetching = false;
      state.isResendSuccess = false;
    },
    verifyCodeRequest: (state) => {
      state.isVerifyCodeFetching = true;
    },
    verifyCodeSuccess: (state) => {
      state.isVerifyCodeFetching = false;
    },
    verifyCodeError: (state) => {
      state.isVerifyCodeFetching = false;
    },
    resetCeateCompanyState: () => createCompanyState,
  },
});

export const actionTypes = createCompanySlice.actions;

export default createCompanySlice.reducer;
