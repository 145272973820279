import { createSlice } from '@reduxjs/toolkit';

const contactUsInitialState = {
  isFetching: false,
};

const contactUsSlice = createSlice({
  name: 'CONTACT_US',
  initialState: contactUsInitialState,
  reducers: {
    contactUsRequest: (state) => {
      state.isFetching = true;
    },
    contactUsSuccess: (state) => {
      state.isFetching = false;
    },
    contactUsError: (state) => {
      state.isFetching = false;
    },
  },
});

export const actionTypes = contactUsSlice.actions;

const contactUsReducer = contactUsSlice.reducer;

export default contactUsReducer;
