import { createSlice } from '@reduxjs/toolkit';

const colleaguesWorkInitialState = {
  colleaguesWork: [],
};

const colleaguesWorkSlice = createSlice({
  name: 'DASHBOARD',
  initialState: colleaguesWorkInitialState,
  reducers: {
    getAllWorkRequest: (state) => {
      state.isLoading = true;
    },
    getAllWorkSuccess: (state, { payload: { allWork } }) => {
      state.colleaguesWork = allWork.reverse();
      state.isLoading = false;
    },
    getAllWorkError: (state) => {
      state.isLoading = false;
    },
  },
});

export const actionTypes = colleaguesWorkSlice.actions;

const colleaguesWorkReducer = colleaguesWorkSlice.reducer;

export default colleaguesWorkReducer;
