import styled from 'styled-components';

import { breakpoints } from '~/styles';

export const CompanyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding-bottom: 16px;
  width: 100%;

  @media (min-width: ${breakpoints.RG}) {
    align-items: flex-end;
    padding-bottom: 0;
  }

  @media (min-width: ${breakpoints.LG}) {
    flex-direction: row;
    margin: 0;
  }

  @media (min-width: ${breakpoints.RG}) {
    justify-content: flex-end;
  }
`;
