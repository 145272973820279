import { createSlice } from '@reduxjs/toolkit';

const myFeedbackState = {
  activeItem: {},
};

const myFeedabckSlice = createSlice({
  name: 'MY_FEEDBACK',
  initialState: myFeedbackState,
  reducers: {
    getMyWorkItemFeedbackRequest: () => {},
    getMyWorkItemRequest: () => {},
    getMyWorkItemSuccess: (state, { payload: activeItem }) => {
      state.activeItem = activeItem;
    },
    getMyWorkItemError: () => {},
    openAddTeamModal: () => {},
    updateWorkItemRequest: () => {},
    updateWorkItemSuccess: () => {},
    updateWorkItemError: () => {},
  },
});

export const actionTypes = myFeedabckSlice.actions;

const myWorkReducer = myFeedabckSlice.reducer;

export default myWorkReducer;
