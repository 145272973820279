import styled from 'styled-components';

import { colors } from '~/styles';

export const HeaderContainer = styled.div`
  display: flex;
  height: 90px;
  background-color: ${colors.WHITE};
  border-bottom: solid 1px ${colors.OUTLINES};
  box-shadow: 0 0 15px 0 ${colors.SHADOWS};
`;

export const SearchContainer = styled.div`
  display: flex;
  flex: 1;
`;
