import { put, all, select, takeLatest, call, delay } from 'redux-saga/effects';
import { push } from 'connected-react-router';

import api from '~/services';
import { requestWrapper as request } from '~/utils';
import {
  pageRoutes,
  ignoredPersonInfoRequestPages,
} from '~/modules/Navigation/constants';

import { upfulNetworkService as apiService } from '~/services/networkService';
import { actionTypes as profileActionTypes } from '~/modules/Profile/redux/profileSlice';
import { actionTypes as myWorkActionTypes } from '~/modules/Dashboard/MyWork/redux/myWorkSlice';
import { closeModal } from '~/modules/Modal/redux/modalSlice';
import { getCurrentPathname } from '~/modules/Navigation/redux/selectors';
import { getAuthenticatedToken } from './selectors';
import { actionTypes } from './authProviderSlice';
import { TOKEN_EXPIRES_IN } from '../constants';

function* logout() {
  const pathname = yield select(getCurrentPathname);
  yield put(profileActionTypes.resetProfileData());
  yield put(myWorkActionTypes.setEditingWorkItem({}));
  yield put(closeModal());

  if (
    pathname !== pageRoutes.CONTACT_US &&
    pathname !== pageRoutes.CREATE_ACCOUNT
  ) {
    yield put(push(pageRoutes.LOG_IN));
  }
}

function* renewToken() {
  const { response } = yield request(api.refreshToken);

  if (response) {
    const { token } = response;
    apiService.setAuthorizationHeader(token);

    yield put(actionTypes.setRefreshedToken(token));
  }
}

function* renewTokenWorker() {
  while (true) {
    const currentToken = yield select(getAuthenticatedToken);

    if (currentToken) {
      yield delay(TOKEN_EXPIRES_IN);
      yield call(renewToken);
    }
  }
}

function* authenticateUser() {
  const { pathname } = window.location;
  const token = yield select(getAuthenticatedToken);
  apiService.setAuthorizationHeader(token);

  const isIgnoredPage = (ignoredPage) => ignoredPage === pathname;

  if (!ignoredPersonInfoRequestPages.some(isIgnoredPage) || token) {
    yield put(profileActionTypes.getPersonInfoRequest());
    yield put(actionTypes.refreshTokenRequest());
  }
}

export default function* myWorkSaga() {
  yield all([
    takeLatest(actionTypes.logoutRequest, logout),
    takeLatest(actionTypes.authenticate, authenticateUser),
    takeLatest(actionTypes.refreshTokenRequest, renewTokenWorker),
  ]);
}
