export const EMAIL_REGEXP =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const PHONE_NUMBER_REGEXP =
  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;

export const NAME_VALIDATION = new RegExp(/^[a-zA-Z .'-]+$/);

export const INVITE_CODE_VALIDATION = /^[a-z0-9]{10}$/i;

export const LINKEDIN_REGEXP =
  /^(https:\/\/)?(www\.)?(ca\.)?linkedin\.com\/in\/[a-z0-9-]+\/?$/i;

export const INTEGER = /^[0-9]+$/;
