import * as Yup from 'yup';

export const initialValues = {
  title: '',
  description: '',
  collaborators: [],
  stakeholders: [],
};

export const errorDictionary = {
  UWI2: {
    toast:
      'You have entered one or more invalid e-mail addresses. Please use a work e-mail address only.',
  },
  UWI10: {
    toast: 'Invitation e-mail failed. Check the e-mail address and try again.',
  },
  UWI13: {
    toast: "You don't need to tag yourself to your own work item.",
  },
  UWI16: {
    toast:
      'Please remove the duplicate people tagged across your Collabortors and Stakeholders.',
  },
};

const commonHeaderValidation = (fieldName, minValue, maxValue) =>
  Yup.string()
    .trim()
    .min(
      minValue,
      `Your ${fieldName} line must be between ${minValue} and ${maxValue} characters.`,
    )
    .max(
      maxValue,
      `Your ${fieldName} line must be between ${minValue} and ${maxValue} characters.`,
    )
    .required(`${fieldName} should not be empty`);

const maxValidation = (fieldName, maxValue) =>
  Yup.string()
    .trim()
    .max(
      maxValue,
      `Your ${fieldName} line must be less than ${maxValue} characters.`,
    );

export const validationSchema = Yup.object({
  title: commonHeaderValidation('Title', 10, 50),
  description: maxValidation('Description', 2000),
});

export const infoHeaderFieldsData = [
  {
    name: 'title',
    placeholder: 'Add a short title for this work item',
    multiline: false,
    rowsMax: 1,
  },
  {
    name: 'description',
    placeholder: `Describe what you're working or what you accomplished in detail. You can add links or references to your work, documentation, or other reports here, too - like on JIRA, Trello, Salesforce, etc`,
    multiline: true,
    rowsMax: 10,
  },
];

export const inviteFieldsData = [
  {
    name: 'collaborators',
    label: 'Add Collaborators:',
    placeholder: 'Add emails separated by commas ',
    noOptionsMessage: {
      title: 'User not found',
      description: 'Add a valid work email to invite them',
      inviteDescription: 'Click here to invite them',
    },
  },
  {
    name: 'stakeholders',
    label: 'Add Stakeholders:',
    placeholder: 'Add emails separated by commas ',
    noOptionsMessage: {
      title: 'User not found',
      description: 'Add a valid work email to invite them',
      inviteDescription: 'Click here to invite them',
    },
  },
];
