import React from 'react';
import PropTypes from 'prop-types';
import { getIn } from 'formik';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns';

import {
  DatePicker,
  DatePickerProvider,
  DatePickerControl,
  DatePickerLabel,
  DatePickerWrapper,
  keyboardDatePickerTheme,
  Arrow,
} from './SelectDatePickerStyles';

function SelectDatePicker({
  field,
  form: { status, errors, touched, setFieldValue, setFieldTouched },
  label,
  views,
  maxDate,
  format,
  minDate,
  placeholder,
  ...props
}) {
  const error = getIn(errors, field.name);
  const touch = getIn(touched, field.name);
  const fieldStatus = getIn(status, field.name);

  const handleChangeDate = (date) => setFieldValue(field.name, date);
  const touchHandler = () => setFieldTouched(field.name, true);

  return (
    <ThemeProvider theme={keyboardDatePickerTheme}>
      <DatePickerProvider utils={DateFnsUtils}>
        <DatePickerWrapper>
          <DatePickerLabel>{label}</DatePickerLabel>
          <DatePickerControl>
            <DatePicker
              {...props}
              {...field}
              autoOk
              placeholder={placeholder}
              views={views}
              format={format}
              keyboardIcon={<Arrow success={fieldStatus} />}
              minDate={minDate}
              maxDate={maxDate}
              error={Boolean(error) && Boolean(touch)}
              success={fieldStatus}
              helperText={error && touch ? error : null}
              onChange={handleChangeDate}
              onOpen={touchHandler}
            />
          </DatePickerControl>
        </DatePickerWrapper>
      </DatePickerProvider>
    </ThemeProvider>
  );
}

SelectDatePicker.defaultProps = {
  views: ['date'],
  maxDate: new Date(),
  minDate: new Date('1920-01-01T00:00:00'),
  format: 'MM/dd/yyyy',
  placeholder: 'Date',
};

SelectDatePicker.propTypes = {
  label: PropTypes.string.isRequired,
  views: PropTypes.array,
  maxDate: PropTypes.instanceOf(Date),
  format: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  placeholder: PropTypes.string,
};

export default SelectDatePicker;
