import { all, put, select, takeLatest } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import { actionTypes as authActionTypes } from '~/modules/Auth/AuthProvider/redux/authProviderSlice';
import { upfulNetworkService as apiService } from '~/services/networkService';
import { getSearchDataSelector } from '~/modules/Navigation/redux/selectors';

import { pageRoutes } from '~/modules/Navigation/constants';
import { requestWrapper as request } from '~/utils';
import api from '~/services';

import { setPasswordRequestNormalizer } from './normalizer';
import { actionTypes } from './createPasswordSlice';
import { actionTypes as at } from '~/modules/Auth/Login/redux/loginSlice';
import { actionTypes as cat } from '../../CreateAccount/redux/createAccountSlice';

function* createPassword({ payload: { data, errorDictionary, setErrors } }) {
  const searchData = yield select(getSearchDataSelector);

  const createPasswordData = setPasswordRequestNormalizer({
    ...data,
    ...searchData,
  });

  const { response } = yield request(
    api.createPassword,
    createPasswordData,
    errorDictionary,
    setErrors,
  );

  if (response) {
    const {
      token,
      person,
      company,
      name,
      expiresAt,
      refresh,
      refreshExpiresAt,
      free_email,
    } = response;
    apiService.setAuthorizationHeader(token);
    yield put(
      authActionTypes.setAuthenticatedData({
        isAuthenticated: true,
        token,
        person,
        company,
        name,
        expiresAt,
        refresh,
        refreshExpiresAt,
        free_email,
      }),
    );
    yield put(cat.createAccountSuccess());
    yield put(at.loginSuccess());
    yield put(authActionTypes.refreshTokenRequest());
    yield put(push(pageRoutes.MAIN));
  } else {
    yield put(actionTypes.createPasswordError());
  }
}

export default function* createPasswordSaga() {
  yield all([takeLatest(actionTypes.createPasswordRequest, createPassword)]);
}
